.ShareButton {
    &-container {
        padding: 20px;
        min-width: 420px;
    }

    &-header {
        padding: 16px 20px;
        border-bottom: 1px solid var(--gray-400);
        display: flex;
        justify-content: space-between;

        span {
            font-size: 18px;
        }
    }

    .dropdown-menu {
        padding: 0;
        box-shadow: 0 1px 5px rgb(28 28 28 / 30%);
    }
}
