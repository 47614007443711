.ChartHighlight-empty {
    background-color: var(--gray-100);
}

.ChartHighlightsTable {
    &-compact {
        .TableColumn {
            white-space: normal;
            word-break: break-word;
        }

        td {
            max-width: 0;

            & > a {
                max-width: 100%;
            }
        }
    }
}
