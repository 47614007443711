$menu-height: 422px;

.PlaylistTypeFilter {
    position: relative;

    &-filter {
        width: 100%;
    }

    .PlusMoreValue {
        &-more {
            font-weight: normal;
        }
    }
}
