.SongFilter {
    &-option {
        display: flex;
        width: 100%;

        &-appearances {
            color: var(--gray-500);
            font-weight: bold;
            padding-left: 1rem;
        }

        .TruncatedText {
            flex: 1;
        }
    }
}
