.StoreStatusModal {
    &-header {
        display: flex;
        align-items: center;
        gap: var(--padding-medium-small, 8px);
        padding-bottom: 1rem;

        &-tooltip {
            .tooltip-inner {
                min-width: 320px;
            }
        }

        .GlyphIcon {
            display: block;
        }
    }

    .ReactModal-body {
        overflow-y: hidden;
        padding: 8px;
    }
}
