.InfoTable {
    text-align: left;

    td:first-child {
        width: 20%;
    }

    tr:first-child > td {
        border-top: none;
    }

    .table > tbody > tr:last-child > td {
        border-bottom: none;
    }
}
