.ChartsCardsView {
    &-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
    }

    &-region {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: var(--gray-pale-sky);
        margin-bottom: 10px;
    }
}
