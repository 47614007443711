.RecentlySearchedItems {
    width: 100%;

    &-heading {
        font-weight: 400;
        font-size: 18px;
        color: var(--text-primary);
        margin-bottom: 20px;
        line-height: 26px;
    }

    &-clearResultsContainer {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    &-clearResults {
        font-size: 12px;
    }

    .GridTable {
        .GridTable-header-row {
            .col-inCatalog {
                .SkeletonLoader {
                    display: none;
                }
            }

            .SkeletonLoader-line {
                height: 12px;
            }

            .GridTable-column-loading {
                padding: 13px 10px;
            }
        }
    }

    .ErrorV2 {
        .tableView {
            height: calc(100vh - 167px);
        }
    }
}
