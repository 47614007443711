.CatalogGridTable {
    .GridTable-header-right {
        .TableUpdateIndicator {
            order: -1;
        }
        .TableExportButton,
        [data-testid='TableExportButton'] {
            order: 1;
        }
        .NewFeatureHighlight {
            order: 2;
            margin-right: -15px;
        }
        .TableCustomizeButton {
            order: 3;
        }
        .TableSourceButton {
            order: 4;
        }
    }
}
