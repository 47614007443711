.ProductCoverArtCardHorizontal {
    color: var(--text-primary);

    &:hover {
        text-decoration: none;
    }

    &-container {
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        height: 80px;
        border-color: var(--dividers-light-stroke);

        &:hover {
            box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
        }
    }

    &-product {
        font-size: 16px;
        min-width: 0;
    }

    &-date {
        color: var(--text-secondary);
        align-self: flex-start;
    }

    &-format {
        color: var(--text-primary);
        margin: 0 10px;
    }

    &-inner {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
        flex: 1 1 auto;
        min-width: 0;
    }

    &-week {
        text-transform: capitalize;
        color: var(--text-secondary);
    }

    &-percentage {
        border: none;
        display: inline-block;
        padding: 0 0 0 0.5rem;
        text-align: left;
        min-width: auto;
    }

    .CoverArt {
        border-radius: 3px 0 0 3px;
    }
}
