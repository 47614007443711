.MetricRow {
    align-items: center;
    display: grid;
    grid-template-columns: 55% 20% 20%;
    column-gap: 5px;

    &-name {
        align-items: center;
        display: flex;
        min-width: 0;
    }

    &-change {
        text-align: right;
    }

    &-value {
        text-align: right;
    }
}
