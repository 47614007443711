.ChartsCardItem {
    padding: 10px;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    height: 60px;
    text-decoration: none;
    color: initial;

    &:hover {
        text-decoration: none;
        color: initial;
        box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
    }

    &Country {
        color: var(--text-primary);
        font-size: 16px;
        line-height: 22px;
    }

    &Info {
        &Container {
            min-width: 0;
        }
    }

    &-date {
        color: var(--text-primary);
        margin-left: 5px;

        &Container {
            font-size: 12px;
            line-height: 18px;
        }

        &Title {
            color: var(--text-secondary);
        }
    }

    &-Removed {
        background-color: var(--gray-100);

        .ChartsCardItem {
            &CountryFlag {
                opacity: 0.6;
            }

            &Country {
                color: var(--text-secondary);
            }

            &-date {
                color: var(--gray-500);

                &Title {
                    color: var(--gray-500);
                }
            }
        }
    }

    .FormattedChange {
        margin-left: auto;
        flex-shrink: 0;
    }
}
