.DetailedViewPopup {
    .ReactModal-content {
        height: calc(100vh - 19px);
        max-height: calc(100vh - 19px);
        width: calc(100vw - 40px);
    }

    .ReactModal-body {
        flex-grow: 1;
    }

    .ErrorV2.section {
        height: 530px;
    }

    .ModalFooter {
        .btn {
            padding: 11px 20px;
            width: 80px;
            height: 40px;
            justify-content: center;
        }
    }

    &-filters {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        white-space: nowrap;
    }

    &-by-region {
        &-container {
            display: flex;
            gap: 40px;
        }

        &-column {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &-card {
            &-heading {
                font-weight: 500;
                margin-bottom: var(--padding-medium-large);
            }

            &-country {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
            }
        }
    }

    &-by-countries {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
}
