.PieChart {
    display: flex;
    flex-direction: row;

    &-chart {
        margin: auto;
        width: 40%;
        max-height: 175px;
        max-width: 175px;
    }

    @media print {
        .PieChart-chart {
            width: 175px;
            height: 175px;
        }
    }

    &-table {
        align-self: center;
        flex: 1;
        flex-direction: column;
        flex-shrink: 0;
        margin: auto;
        max-width: 60%;
    }

    &-row {
        display: flex;
        margin-bottom: 0.5rem;
        &-first {
            flex-shrink: 0;
        }

        &-second {
            flex: 1;
            margin: 0 0.5rem;
        }

        &-third {
            flex-shrink: 0;
            white-space: nowrap;
            text-align: right;
        }
    }

    .no-data .highcharts-point {
        display: none;
    }

    .highcharts-point-hover {
        fill-opacity: 100%;
    }

    .highcharts-tooltip {
        border: none;
        filter: none;
        margin: 0;
        padding: 0;

        &-container {
            background-color: white;
            border: 1px solid var(--gray-400);
            border-radius: 0.25rem;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
            color: black;
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
            /* stylelint-disable declaration-no-important */
            overflow: visible !important;
            /* stylelint-enable */
        }

        &-data {
            display: flex;
        }

        &-value {
            font-weight: 500;
            margin-left: auto;
        }

        &-box {
            fill: none;
            stroke: none;
        }
    }
}
