.ParticipantOverviewPage {
    padding: 0 6px 6px 6px;

    .CompareButton {
        margin: 0 1rem;
        margin-left: auto;
        padding: 0;

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    .DataSection,
    .PageToolbar {
        height: 100%;
        margin-bottom: 0;
    }

    &-row {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax($min-data-section-width, 1fr);
        grid-column-gap: 1rem;

        &-first {
            align-items: flex-start;
            grid-auto-columns: minmax($min-data-section-width, 30%) 1fr;
        }

        &-stretch {
            grid-auto-flow: row;
            grid-row-gap: 1rem;
            grid-auto-columns: minmax($min-data-section-width, 1fr);
        }

        @media print {
            -webkit-column-break-inside: avoid !important;
            page-break-inside: avoid !important;
        }
    }

    &-column {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax($min-data-section-width, 1fr);
        grid-column-gap: 1rem;
        height: 100%;
        min-height: 386px;
    }

    &-row + &-row {
        margin-top: 1rem;
    }
}
