.PageTitle {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    > h2 {
        font-weight: normal;
    }

    &-type {
        color: var(--text-secondary);
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-icon {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
    }

    &-title {
        display: flex;
        font-weight: 400;
    }

    &-title-deleted {
        text-decoration: line-through;
    }

    &-status-deleted {
        margin-left: 10px;
    }
}
