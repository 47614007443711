.SongSelection {
    @include controls-z-index('compare-panel');

    display: flex;

    & div:last-child {
        margin-right: 0;
    }

    &-search {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 100%;

        &-centered {
            justify-content: center;
        }

        &-component {
            padding: 0;
            padding-left: 2rem;
            position: absolute;
            top: -2rem;
            width: 50%;
            z-index: 10;

            &-small {
                width: 33%;
            }
        }
    }
}
