.TiktokGainersTable {
    &:not(.zebra-variant) {
        .GridTable-table {
            grid-template-rows: 60px;
            grid-auto-rows: 80px;

            .GridTable-cell {
                padding: 10px;
            }
        }
    }

    &.loading {
        @include table-loading-indicators(TiktokGainersTable);

        .col-index,
        .GridTable-cell:first-child {
            .SkeletonLoader-item {
                display: flex;
                justify-content: center;
            }

            .SkeletonLoader-line {
                width: 10px !important;
                height: 12px;
                margin-left: initial !important;
            }
        }
    }
}
