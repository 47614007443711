.VideoOverviewPage {
    padding: 0 6px 6px 6px;
    overflow: hidden;

    &-toolbar {
        margin-bottom: 0;
    }

    .card {
        height: 100%;
        margin-bottom: 0;
    }

    .VideoFilters {
        margin-bottom: 0;
    }

    .VideoComparePanel {
        margin-bottom: 0;
    }
}
