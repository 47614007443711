.CountryFilter {
    width: $filter-width;

    .SuiteSelect {
        &-input-value {
            &-label {
                .TruncatedText {
                    display: flex;
                }
            }
        }
    }
}
