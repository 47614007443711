.DataSectionV2 {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-header {
        align-items: center;
        display: flex;
    }

    &-subheader {
        display: flex;
        color: var(--gray-500);
        font-weight: 400;
    }

    &-header-title {
        @include typography-h3;

        margin: 0;
    }

    &-header-icon {
        display: flex;
        height: 24px;
        width: 24px;

        & > * {
            height: 100%;
            width: 100%;
        }
    }

    &-footer {
        display: flex;
        justify-content: flex-end;
    }

    &-empty {
        display: flex;
        min-height: 30rem;
    }
    .card {
        margin: 0;
    }
}
