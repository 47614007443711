$dropdown-menu-width: 30rem;

.ChartsDropdown {
    margin-bottom: 1rem;

    .Glyph {
        fill: var(--gray-800);
    }

    &-title {
        align-items: center;
        display: flex;
        margin: 0;

        &-text {
            border-right: none;
            display: flex;
            margin: 0 1rem 0 0.5rem;
            padding-right: 0;
        }

        &:focus {
            outline: none;
        }
    }

    &-item {
        display: flex;

        &-title {
            color: var(--gray-800);
            font-size: 1.4rem;
        }
    }
}
