.Switcher {
    &__container {
        display: flex;
    }

    &.custom-switch.new-theme {
        .custom-control-label {
            font-size: 14px;
            line-height: 20px;
            text-transform: none;
            letter-spacing: normal;
            font-weight: normal;

            &::after {
                background-color: var(--jelly-bean-blue);
            }

            &::before {
                border: 1px solid var(--jelly-bean-blue);
                background-color: var(--gray-200);
            }
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: var(--gray-200);
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: var(--jelly-bean-blue);
        }
    }
}
