.BrandIconStack {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    column-gap: 2px;

    &-icon {
        .BrandIcon {
            pointer-events: none;
            width: 16px;
            height: 16px;
            display: block;
        }
    }
}
