.ChartsPage {
    &-cards {
        display: grid;
        gap: $grid-gap;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        margin: -1.5rem -1rem -1rem;
        padding: $grid-gap;
    }

    .ErrorV2.tableView {
        height: calc(100vh - 101px - var(--main-content-padding) * 2);
    }

    .ChartCard {
        text-align: left;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        box-shadow: $box-shadow;

        h4 {
            margin: 0;
        }
        h6 {
            align-items: center;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .PlatformIcon {
            margin-bottom: 0.5rem;
        }

        &-title {
            font-weight: normal;
            color: var(--text-primary);
            text-align: center;
        }

        &-links {
            display: flex;
            gap: 1rem;
        }

        &-link {
            margin: 0;
            padding: 0;
        }

        .dropdown > .btn {
            font-weight: normal;
            text-transform: none;
            letter-spacing: normal;
            padding: 0;
        }

        .dropdown-menu {
            overflow: auto;
            max-height: 50vh;
        }

        .SkeletonLoader:first-of-type .SkeletonLoader-item > div {
            width: 150px;
            height: 100px;
        }
    }
}
