.InviteUser {
    margin-top: 2rem;

    div {
        color: var(--text-secondary);
    }

    &-error-message {
        color: var(--red);
    }

    .badge {
        margin-left: 10px;
    }

    &-heading {
        display: flex;
        align-items: center;

        .Status {
            margin-left: 10px;
        }
    }

    h6 {
        margin-bottom: 0;
    }
}
