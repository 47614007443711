.SongMoreInfoModal {
    .ReactModal-body {
        display: flex;

        .SongMoreInfoModal-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .tab-content {
                overflow-y: auto;
            }
        }
    }

    .ReactModal-content {
        padding: 10px;
        max-height: calc(100vh - 50px);
        width: 1020px;
    }

    .ReactModal-close {
        top: 16px;
        position: relative;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;

        .CloseGlyph {
            display: block;
        }
    }

    .ReactModal-header {
        display: flex;
        justify-content: flex-end;
        border-bottom: none;
        height: 0;
    }

    .nav-tabs {
        box-shadow: 0 3px 3px -3px rgb(0 0 0 / 17.5%);
    }

    .SongMoreInfoModalInfoTab {
        &-table {
            margin-top: 10px;

            tr:last-child td {
                border-bottom: none !important;
            }

            td > div {
                display: inline-block;
            }
        }

        &-label {
            color: var(--text-secondary);
        }

        &-products {
            margin-top: 50px;

            &-title {
                color: var(--text-primary);
                font-size: 1.15rem;
                margin-bottom: 20px;
            }

            &-table-header {
                color: var(--text-secondary);
                padding: 7px 10px;
            }
        }
    }
}

.SongMoreInfoModalRightsTab-tooltip span {
    margin-left: 8px;
}
