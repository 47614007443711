$header: 124px;
$toolbar: 52px;

.ErrorV2 {
    width: 100%;

    &.full,
    .full {
        height: calc(100vh - var(--main-content-padding) * 2);
    }

    &.tableView,
    .tableView {
        height: calc(100vh - $header - $toolbar - var(--main-content-padding));
    }

    &.section,
    .section {
        height: 382px;
    }

    &.tinySection,
    .tinySection {
        height: 80px;

        & .card-body {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--text-secondary);
            font-size: 12px;
        }
    }

    &-actionBtn {
        margin-top: 20px;

        &.btn-secondary {
            padding: 11px 20px;
            height: auto;
        }

        &.btn-quaternary {
            color: var(--midnight-650, #276b9b);
        }
    }

    .InfoMessage {
        height: 100%;

        &-title {
            margin: 0;
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 5px;
            text-align: center;
            font-size: 12px;
        }
    }
}
