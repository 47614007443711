.ModalHeaderLoadingIndicator {
    display: flex;
    gap: 10px;

    &-metadata {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &-artist {
        display: flex;
        align-items: center;
        gap: 4px;

        &-cover {
            width: 20px;
        }
    }
}
