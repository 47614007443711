.ThumbnailSongLinkWithArtistCell {
    overflow: hidden;

    &-label {
        display: flex;
        flex-direction: column;
        gap: 2px;
        min-width: 0;
    }

    &-links {
        @include truncated-text;

        font-size: 12px;
        color: var(--blue);
        line-height: 18px;
    }

    .Tooltip {
        align-self: flex-start;
        max-width: 100%;
    }
}
