.SongPlaylistsGridTable {
    &-headerOverlayTooltip {
        .tooltip-inner {
            text-align: left;
        }
    }

    &-headerDaysOnOverlayTooltip {
        .tooltip-inner {
            max-width: 227px;
        }
    }
    &-headerCompletionOverlayTooltip {
        .tooltip-inner {
            max-width: 350px;
        }
    }
}
