.AccountTopCountries {
    .DataSection-content {
        gap: 20px 80px;

        .ContentPanel-empty {
            background-color: var(--white);

            &-content {
                color: inherit;
            }
        }
    }

    .DataSection-header button {
        height: 37px;
        line-height: initial;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .CountryFlag {
            margin-right: 10px;
        }
    }
}
