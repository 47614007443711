.SearchAllResultsPage-Entity {
    &-loading-section {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: $grid-gap;

        @media screen and (max-width: 1727px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .hidde-on-small-screen {
                display: none;
            }
        }
    }

    &-loadingIndicator.SkeletonLoader-horizontal {
        .SkeletonLoader-line {
            height: 80px;
            border-radius: 3px;
        }
    }
}
