.ParticipantSongFilter {
    .ParticipantSongFilterOptionSubtitle {
        color: var(--gray-500);
    }

    .Select__option--is-selected {
        .ParticipantSongFilterOptionSubtitle {
            color: var(--white);
        }
    }

    .Select__value-container {
        &.Select__value-container--has-value {
            .ParticipantSongFilterOptionSubtitle {
                display: none;
            }
        }
    }
}
