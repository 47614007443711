.Feedback {
    &-button {
        background: var(--midnight-7);
        border: none;
        color: var(--white);
        display: block;
        margin: 1rem auto;
        width: 200px;

        &:hover,
        &:focus,
        &:active {
            background: var(--midnight-6);
            border: none;
            color: var(--white);
            text-decoration: none;
        }
    }
}
