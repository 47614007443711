.ParticipantPlaylistsGridTable {
    .row-alt-hover,
    .row-hover {
        .ThumbnailPlaylistLinkCell {
            &-details {
                display: none;
            }
        }

        &:hover {
            .ThumbnailPlaylistLinkCell {
                &-details {
                    display: block;
                }
            }
        }
    }
}
