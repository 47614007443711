.PlaylistLinkCell {
    align-items: center;
    display: flex;
    min-width: 0;

    &-name-container {
        overflow: hidden;
    }

    &-link {
        overflow: hidden;

        &:hover .TruncatedText {
            text-decoration: underline;
        }
    }

    &-description {
        align-items: center;
        display: flex;
        font-size: 12px;
        line-height: 16px;

        .Icon {
            height: 18px;
            margin-left: 3px;
            width: 18px;

            &.Algorithmic {
                width: 16px;
                height: 16px;
            }
        }
        .PlaylistLinkCell-description-text {
            color: var(--gray-500);
        }
    }
}
