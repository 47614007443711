.SearchPageES {
    display: flex;
    flex: 1;
    flex-direction: column;

    .SearchPageHeader {
        .Glyph.SearchGlyph {
            height: 2rem;
            width: 2rem;
            fill: var(--gray-500);
            margin-left: 0.75rem;
        }

        &-input {
            flex-grow: 1;
            position: relative;

            input {
                border: 0;
                font-size: 1.75rem;

                &:focus {
                    box-shadow: none;
                }
            }

            &-spinner {
                position: relative;
                width: 50px;
                height: 30px;
                margin-right: 20px;
            }
        }

        &-input-options {
            position: absolute;
            background: white;
            width: 300px;
            box-shadow: $box-shadow;
            z-index: 1;
            padding: 1rem;
            color: #aaa;
        }

        &-input-option {
            padding: 0.5rem;

            &:hover {
                background-color: var(--info);
                color: var(--white);
            }
        }
    }

    .MyCatalogCheckbox-warning-tooltip {
        height: 28px;
    }

    .CatalogMetadata {
        padding: 0.5rem 0.75rem;
    }

    .InfoMessage {
        margin: 10rem auto;

        &-body {
            margin-top: 0;
            color: var(--gray-500);
            font-size: 1rem;
            text-align: center;
        }
    }

    .ErrorV2 {
        .InfoMessage {
            margin: 0;

            &-body {
                margin-top: 5px;
                color: var(--text-secondary);
                font-size: 12px;
            }
        }
    }

    .pagination {
        margin-bottom: 0;
    }
}
