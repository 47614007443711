.DateCell {
    white-space: nowrap;
}

.CoverArtCell {
    align-items: center;
    display: flex;
    min-width: 0;

    .CoverArt {
        margin-right: 10px;
    }
}

.ChartTable {
    .row-alt-hover {
        &:hover {
            cursor: pointer;
        }
    }

    .row-hover {
        &:hover {
            cursor: pointer;
        }
    }
}

.OfferTypeCell {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.StarredCell {
    cursor: pointer;
    outline: none;
}

.PlaylistLinkCell {
    align-items: center;
    display: flex;
    min-width: 0;

    &-name-container {
        overflow: hidden;
    }

    &-link {
        overflow: hidden;

        &:hover .TruncatedText {
            text-decoration: underline;
        }
    }

    &-description {
        align-items: center;
        display: flex;
        font-size: 12px;
        line-height: 16px;

        .Icon {
            height: 18px;
            margin-left: 3px;
            width: 18px;

            &.Algorithmic {
                width: 16px;
                height: 16px;
            }
        }

        .PlaylistLinkCell-description-text {
            color: var(--gray-500);
        }
    }
}

.ThumbnailPlaylistLinkCell {
    display: flex;
    width: 100%;

    .PlaylistLinkCell {
        flex: 1;
    }

    &-details {
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        margin-left: 10px;
        text-transform: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.StorefrontCountCell {
    align-items: center;
    cursor: pointer;
    display: flex;

    &-count {
        background-color: var(--text-secondary);
        border-radius: 3px;
        color: var(--white);
        padding: 0 7px;
    }
}

.ExpandedIconWithNumberCell {
    align-items: center;
    cursor: pointer;
    display: flex;

    &-icon {
        margin-left: 8px;
    }
}

.ProductLinkWithUpcCell,
.ProductCell {
    overflow: hidden;

    &.deleted .TruncatedText {
        text-decoration: line-through;
    }

    &-upc {
        color: var(--text-secondary);
        font-size: 0.85rem;
    }
}

.ExternalVideoLinkCell,
.ExternalChannelLinkCell {
    &-linkContainer {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .ExternalLinkGlyphIcon {
        color: var(--glyphs-info);
    }
}

.ExternalLinkCell {
    .ExternalLinkGlyphIcon {
        color: #0070e0;
    }
}

.ClaimStatusCell {
    font-size: 90%;
    font-weight: inherit;
    text-transform: none;
}

.ClaimPolicyCell {
    width: 100%;

    &-policy {
        display: flex;
        align-self: center;
        justify-content: space-evenly;

        &:hover {
            cursor: pointer;
        }
    }

    svg {
        margin-bottom: 3px;
    }
}

.ThumbnailSongLinkWithIsrcCell {
    display: flex;
    overflow: hidden;

    &-label {
        min-width: 0;
    }

    &-irsc {
        color: var(--text-secondary);
        font-size: 0.77rem;
    }

    &-irsc-v2 {
        color: var(--text-secondary);
        font-size: 12px;
    }
}

.CatalogAccountCell {
    display: flex;
    align-items: center;
    column-gap: 15px;
    min-width: 0;

    &-account {
        min-width: 0;

        &-type {
            color: var(--text-secondary);
            font-size: 0.85rem;
        }
    }
}

.AccountCell {
    overflow: initial !important;
    max-width: 100%;

    @at-root td.cell-account {
        overflow: initial !important;
    }
}

.NumberWithPercentageCell {
    columns: 2 auto;
    column-gap: 10px;
    display: block;
    width: 100%;

    &-percentage {
        color: var(--text-secondary);
        text-align: right;
    }
}

.TopLogoCell {
    display: grid;
    grid-template-rows: 1fr auto;

    .PlatformIcon {
        grid-column: 1 / span 3;
        place-self: start center;
        margin: 0 0 0.25rem;
    }
}

.SummaryTitleWithOnlyCell {
    display: flex;
}

.CatalogBrandCell {
    .BrandIcon {
        pointer-events: none;
    }
}

.VideoLinkWithTypeCell {
    overflow: hidden;

    &-type {
        color: var(--text-secondary);
        font-size: 0.85rem;
    }
}

.SongMetaDataCell {
    overflow: hidden;

    &-label {
        display: flex;
        flex-direction: column;
        width: calc(100% - 50px);
        gap: 2px;

        & > .Tooltip {
            align-self: flex-start;
            max-width: 100%;
        }
    }

    &-link {
        line-height: 20px;
    }

    &-isrc {
        color: var(--text-secondary);
        font-size: 12px;
        display: flex;
        align-items: center;
        line-height: 18px;
    }

    &-links {
        @include truncated-text;

        font-size: 12px;
        color: var(--blue);
        line-height: 18px;

        .LinkList > a {
            margin-right: 0.2rem;
        }
    }
}

.ThumbnailSongAndArtistLinkAndBrandCell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--padding-medium-small);

    &-data {
        min-width: 0;
    }
}

.NmfParametersCell {
    width: 100%;

    &-line {
        display: flex;
        justify-content: space-between;
    }

    &-label,
    &-value {
        font-size: 12px;
    }

    &-label {
        color: var(--text-secondary);
    }

    &-value {
        color: var(--text-primary);
    }
}

.NmfCountriesCell {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

$grid-table: '.GridTable';

#{$grid-table}.apply-local-utils {
    &.unset-loaders-size {
        #{$grid-table}-cell {
            .SkeletonLoader {
                min-width: unset;
                height: unset;
            }
        }
    }

    &.fixedColumnWithBorder {
        #{$grid-table}-row-cell#{$grid-table}-cell,
        #{$grid-table}-column#{$grid-table}-row-cell {
            padding: 12px 10px;
        }

        &.loading {
            #{$grid-table}-row-cell#{$grid-table}-cell,
            #{$grid-table}-column#{$grid-table}-row-cell {
                padding: 12px;
            }

            #{$grid-table}-cell:first-child {
                border-right: $table-border;
            }
        }

        #{$grid-table}-column.fixed,
        #{$grid-table}-cell.fixed {
            border-right: $table-border;
        }

        #{$grid-table}-container.scroll-left #{$grid-table}-row-cell.fixed {
            border-right: none;
        }
    }

    #{$grid-table}-header-row {
        #{$grid-table}-row-cell {
            height: 60px;

            .SkeletonLoader-line {
                height: 14px;
            }

            &.util-align-center {
                .SkeletonLoader {
                    width: auto;
                }
            }

            &.util-align-center-right {
                text-align: center;
                justify-content: center;
            }

            &.util-tooltip-align-right {
                #{$grid-table}-column-tooltip-icon {
                    order: initial;
                }
            }

            &#{$grid-table}-column-loading {
                &.util-align-center {
                    &:has(.SkeletonLoader) {
                        justify-content: center;

                        .SkeletonLoader {
                            width: auto;
                        }
                    }
                }

                &.util-align-right {
                    &:has(.SkeletonLoader) {
                        justify-content: flex-end;

                        .SkeletonLoader {
                            width: auto;
                        }
                    }
                }

                &.util-align-left {
                    &:has(.SkeletonLoader) {
                        justify-content: flex-start;

                        .SkeletonLoader {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    #{$grid-table}-body {
        #{$grid-table}-row {
            #{$grid-table}-cell {
                &.util-align-center-right {
                    justify-content: flex-end;
                }

                &:has(.align-center-loading-indicator) {
                    justify-content: center;

                    .SkeletonLoader {
                        width: auto;
                    }
                }

                &:has(.align-left-loading-indicator) {
                    justify-content: flex-start;

                    .SkeletonLoader {
                        width: auto;
                    }
                }

                &:has(.align-right-loading-indicator) {
                    justify-content: flex-end;

                    .SkeletonLoader {
                        width: auto;
                    }
                }
            }
        }
    }

    #{$grid-table}-column {
        &.util-align-right {
            #{$grid-table}-column-sort-indicator {
                order: initial;
            }
        }
    }
}

.SoundIdCell {
    display: flex;
    gap: 8px;
    align-items: center;
    .GlyphIcon {
        color: var(--midnight-650);
    }
}

.CreationIdCell {
    & > div {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 2px;
        .GlyphIcon {
            color: var(--midnight-650);
        }
    }
    & > span {
        color: var(--text-secondary);
        font-size: var(--font-size-small-body, 12px);
        font-weight: 400;
        line-height: var(--line-height-small-body, 18px);
    }
}
