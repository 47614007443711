.RelatedEntityCell {
    display: flex;
    align-items: center;
    min-width: 0;

    .CoverArt {
        margin-right: 8px;
    }

    &-metadata {
        display: flex;
        flex-direction: column;
        min-width: 0;
    }

    &-type {
        font-size: 12px;
        color: var(--text-secondary, #505962);
        text-transform: uppercase;
    }
}
