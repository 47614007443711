.AccountStickyHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-info {
        width: 100%;
    }
}
