.ParticipantPage {
    position: relative;

    .PageHeader {
        .DataList {
            height: auto;
        }
    }

    .ChartTable {
        tr.row-alt > td {
            background-color: #f9f9f9;
        }
    }
}
