.ParticipantSongsPage {
    &-filter {
        width: $filter-width;
        @media print {
            display: none;
        }
    }

    .Error.Error-noResults,
    .Error.Error-error,
    .Error.Error-noData {
        height: calc(100vh - 263px);
    }

    .ParticipantSongsGridTableV2 {
        .GridTable-table {
            overflow: visible;
            overflow-x: auto;

            .GridTable-cell {
                padding: 10px 10px 9px;
            }
        }

        .GridTable-header-row {
            .SkeletonLoader-line {
                height: 12px;
            }
        }

        &.loading {
            .LoaderIndicator {
                &--alignRight {
                    margin-left: auto;
                }
            }

            .ParticipantSongsGridTableV2-songMetaData-loadingIndicator {
                grid-template-columns: 40px 200px;

                .artwork {
                    min-width: 40px;
                    height: 40px;
                    width: 40px;

                    .SkeletonLoader-line {
                        height: 40px;
                    }
                }
            }

            .GridTable-column.GridTable-row-cell {
                .SkeletonLoader-line {
                    margin-left: auto;
                }

                &.col-songName,
                &.col-account,
                &.col-storeName {
                    .SkeletonLoader-line {
                        margin-left: 0;
                    }
                }
            }

            &:not(.table-extension-updateIndicator) {
                .GridTable-cell {
                    padding: 19px 8px 18px;
                }

                .GridTable-column.GridTable-row-cell {
                    height: 56px;
                }
            }
        }
    }
}
