.NewReleasesPageHeader {
    &-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .Icon {
            margin-right: 12px;
        }
    }
}
