.PerformanceOverTimeLayout {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-row,
    &-node {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &-node {
        &.alignRight {
            margin-left: auto;
        }
        &.wrap {
            flex-wrap: wrap;
        }
    }

    &-row {
        &.compact {
            column-gap: 10px;

            .PerformanceOverTimeLayout-node {
                column-gap: 10px;
            }
        }
        &.top {
            align-items: flex-start;
        }
        &.bottom {
            align-items: flex-end;
        }
    }
}
