.TopListItem {
    align-items: center;
    display: flex;
    padding: 0.35rem 0.5rem;
    width: 100%;
    white-space: nowrap;

    &-content {
        flex-grow: 1;
        flex-shrink: 1;
        line-height: 1.6;
        padding-left: 0.5rem;
        overflow: hidden;
    }

    &-position {
        color: var(--text-secondary);
        line-height: 2;
        min-width: 1rem;
        flex-shrink: 0;
    }

    &-title {
        font-size: 1.2rem;
    }

    &-additional {
        color: var(--gray-400);
        font-size: 10px;
    }

    &-value {
        font-size: 1.2rem;
        font-weight: 500;
        margin-left: auto;
        margin-right: 3rem;
    }

    &-progress-container {
        line-height: 2;
        text-align: right;
        width: 40%;
        flex-shrink: 0;
        margin-left: 0.5rem;
    }

    .ProgressBar {
        flex-direction: row-reverse;

        &-percentage {
            color: var(--text-primary) !important;
            width: 35%;
        }
    }

    &-highlighted {
        font-weight: 700;
        border-top: 5px solid var(--gray-200);
        border-bottom: 5px solid var(--gray-200);
    }
}
