.ProductCoverArtCard {
    display: grid;
    flex-shrink: 0;
    flex-direction: column;
    justify-items: center;

    &-format {
        color: var(--text-secondary);
        align-self: flex-start;
        margin-bottom: 5px;
    }

    .TruncatedText {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    .PeriodPercentage {
        border: none;
        display: flex;
        &-period {
            margin-left: 5px;
        }
    }
}
