.TiktokGainersFilters {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &-row {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .MarketsSelector {
        margin-right: 0;
    }
}
