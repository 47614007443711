$common-container-padding: 15px; //  through the app
$offset-top: 149px; // offset from the top of the page

.Error {
    .InfoMessageV2 {
        text-align: center;
        padding: 0;
        gap: unset;
    }

    &-fullSize {
        height: calc(100vh - #{$offset-top + $common-container-padding});

        .InfoMessageV2 {
            &-title {
                font-size: 24px;
                line-height: 32px;
            }

            &-message {
                padding-top: 5px;
                white-space: pre-wrap;
            }
        }

        .Error__button {
            margin-top: 20px;
        }
    }

    &-height420 {
        height: 420px;
    }

    &-height260 {
        height: 260px;
    }

    &-height420,
    &-height260 {
        .InfoMessageV2 {
            &-title,
            &-message {
                padding-top: 5px;
                white-space: pre-wrap;
            }
        }
    }

    &-no-border {
        border: none;
    }

    &CardBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
