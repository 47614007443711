.TopVideos {
    display: flex;
    padding: 0 1rem 1.5rem 1rem;

    .TopListItem-content {
        overflow: hidden;
    }

    .VideoLabel {
        font-size: 1rem;
        display: flex;
        align-items: center;
    }

    &-footer {
        margin: 0 42px $grid-gap;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .TopListItem-progress-container {
        width: 4rem;
    }
}
