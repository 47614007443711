.CoverArtWithTitle {
    align-items: center;
    display: flex;

    &-cover-art-container {
        position: relative;
    }

    &-content {
        overflow: hidden;
        margin-left: 8px;
    }

    .PlatformIcon {
        background: var(--white);
        border-radius: 3px;
        bottom: -3px;
        margin: 0;
        padding: 2px;
        position: absolute;
        right: -3px;

        &-deezer,
        &-spotify {
            border-radius: 50%;
            border-bottom-right-radius: 0;
        }
    }
}
