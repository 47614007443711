.StatusLine {
    color: var(--text-secondary);
    display: flex;
    align-items: center;

    .separator {
        margin: 0 3px;
    }

    .CountriesIndicator {
        &-pill {
            &-tooltip {
                margin-left: 12px;
            }
        }
    }
}
