.ProductOverviewPage {
    padding: 0 6px 6px 6px;

    &-info {
        margin-left: auto;
        margin-right: 0;
    }

    .ComparisonFilters-period .Select__menu-list {
        max-height: 500px;
    }
}
