.GlobalSearchResults-menu {
    &-heading {
        border-bottom: 1px solid var(--gray-200);
        color: var(--text-secondary);
        font-weight: bold;
        padding: 5px 10px;
    }

    &-footer {
        color: var(--link-color);
        cursor: pointer;
        padding: 5px 10px;
        text-align: right;
        width: 100%;

        &:hover {
            background: var(--gray-50);
            border-color: var(--gray-50);
        }

        &-static {
            padding: 1rem;
            text-align: center;
        }
    }

    &-empty {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        padding: 15px;
    }
}
