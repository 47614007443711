.SocialStats {
    &-content {
        display: grid;
        row-gap: 0.5rem;
        padding: 0 1.5rem 1.5rem;
        height: 100%;
    }

    &-noData {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 250px;
        justify-content: center;
    }

    &-details {
        display: inline-block;
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: none;
    }

    &-footer {
        padding-top: 1rem;

        .SeeAllLink {
            justify-content: flex-end;
            width: 100%;
        }
    }
}
