.DatePickerFilter {
    .DateRangePicker {
        padding: 0;
    }

    .Select {
        width: 250px;
    }

    &.hide-calender {
        .DateRangePicker-indicators-separator {
            display: none;
        }
    }
}
