.ChartsTabs {
    &-container {
        overflow-x: auto;
    }

    .btn {
        white-space: nowrap;
    }

    &-tooltip {
        &-overlay {
            .tooltip-inner {
                max-width: 301px;
                text-align: left;
                white-space: pre-wrap;
            }
        }
    }
}
