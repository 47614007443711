@mixin table-borders(
    $node,
    $verticalClass: null,
    $bottom-color: var(--gray-300),
    $side-color: var(--gray-200)
) {
    #{$node} {
        border-bottom: 1px solid $bottom-color;
    }

    @if $verticalClass {
        &.#{$verticalClass} {
            #{$node} + #{$node} {
                border-left: 1px solid $side-color;
            }
        }
    }
}

@mixin table-cell-size($name, $minWidth: 0) {
    th.chart-col-#{$name},
    td.cell-#{$name} {
        max-width: 0;
        width: auto;
        @if $minWidth != 0 {
            min-width: $minWidth;
        }
    }

    th.chart-col-#{$name} {
        word-wrap: break-word;
    }
}

@mixin cell-green {
    color: var(--green);
}

@mixin cell-red {
    color: var(--red);
}

@mixin cell-number {
    min-width: 70px;
}

@mixin cell-date {
    min-width: max-content;
}

@mixin cell-text-150 {
    min-width: 150px;
}

@mixin cell-text-200 {
    min-width: 200px;
}

@mixin table-loading-indicators($tableName) {
    .GridTable-header-row {
        .SkeletonLoader-line {
            height: 12px;
        }
    }

    .LoaderIndicator {
        &--alignRight {
            margin-left: auto;
        }
    }

    .#{$tableName}-songMetaData-loadingIndicator {
        grid-template-columns: 40px 200px;

        .artwork {
            min-width: 40px;
            height: 40px;
            width: 40px;

            .SkeletonLoader-line {
                height: 40px;
            }
        }
    }

    .GridTable-column.GridTable-row-cell {
        .SkeletonLoader-line {
            margin-left: auto;
        }

        &.col-songName,
        &.col-account,
        &.col-storeName {
            .SkeletonLoader-line {
                margin-left: 0;
            }
        }
    }

    &:not(.table-extension-updateIndicator) {
        .GridTable-cell {
            padding: 19px 8px 18px;
        }

        .GridTable-column.GridTable-row-cell {
            height: 56px;
        }
    }
}

.table thead th {
    border-bottom: 1px solid var(--gray-400);
    border-top: none;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    text-transform: uppercase;
}

.NumberWithCountryFlagCell {
    .CountryFlagCell-number-cell {
        .CountryFlag {
            margin-left: auto;
            margin-right: 7px;
            margin-top: -2px;
        }
    }
}
