.TopSongs {
    &-box {
        height: 300px;
    }
    &-content {
        display: grid;
        row-gap: 1rem;
        padding: 0 1.5rem 4rem;
    }
    &-empty {
        margin: 4rem auto;
    }

    &-details {
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: none;
    }

    &-section {
        .DataSection-header {
            .PeriodDropdown {
                position: relative;
                top: 1rem;

                .Select .Select__control {
                    width: 130px;
                }
            }
        }
    }

    &-footer {
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;

        .SeeAllLink {
            justify-content: flex-end;
            width: 100%;
        }
    }
}
