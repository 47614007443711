.VideoTopUGC {
    display: flex;
    height: auto !important;

    .TopListItem-content {
        overflow: hidden;
    }

    .VideoLabel {
        font-size: 1rem;
        display: flex;
        align-items: center;

        &:hover {
            .TruncatedText {
                text-decoration: underline;
            }
        }
    }

    &-anchor {
        overflow: hidden;
    }

    &-content {
        margin: 12px 20px 20px;
    }

    &-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 500;
        margin: 0 0.5rem;
        padding: 0 $grid-gap $grid-gap $grid-gap;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .ExternalLinkGlyph {
        fill: var(--blue);
    }

    .ExternalLinkGlyphIcon {
        color: var(--blue);
    }

    .TopListItem-progress-container {
        width: 4rem;
    }
}
