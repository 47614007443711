.TimeseriesCard {
    padding: 1rem;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
    }
    .TikTokStoreIcon {
        margin-right: 0.5rem;
    }
}
