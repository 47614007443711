.LinkList {
    > a {
        display: inline-block;
        margin-right: 0.5rem;
    }

    .CoverArt {
        margin-right: 0.3rem !important;
    }

    > a + a {
        padding-left: 0.5rem;
    }

    &.LinkList-comma-separated {
        > a {
            &:not(:last-of-type)::after {
                content: ',';
            }
        }

        > a + a {
            border-left: none;
            padding-left: 0;
        }
    }

    &-container {
        display: flex;
    }

    &-obsolete-link {
        text-decoration: line-through;
    }
}
