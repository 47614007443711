@use 'sass:map';

$colors: var(--dataviz-1) var(--dataviz-2) var(--dataviz-3) var(--dataviz-4)
    var(--dataviz-5) var(--dataviz-6) var(--dataviz-7) var(--dataviz-8)
    var(--dataviz-9) var(--dataviz-10) var(--dataviz-11) var(--text-secondary);

@import '~highcharts/css/highcharts';

@for $index from 1 through length($data-colour-index) {
    // Normalize the index to start from 0
    $name: 'data-#{$index}';
    $index-string: '#{$index}';
    $value: map.get($data-colour-index, $index-string);
    $active-1: map.get($value, 'active-1');
    $color: var(--#{$name});
    $bg-data-inactive-line-color: var(--#{$name}-inactive-line);

    // Normalize the index to start from 0
    $normalized-index: $index - 1;

    .highcharts {
        &-color-#{$normalized-index} {
            fill: $color;
            stroke: $color;
        }

        &-point-inactive,
        &-series-inactive {
            .highcharts-color-#{$normalized-index},
            &.highcharts-color-#{$normalized-index} {
                color: $bg-data-inactive-line-color !important;
                stroke: $bg-data-inactive-line-color !important;
                fill: $bg-data-inactive-line-color !important;
                opacity: 1;
            }
        }
    }
}

.highcharts {
    &-column-series,
    &-bar-series,
    &-pie-series {
        .highcharts-point {
            transition: fill 250ms;

            &-hover {
                fill-opacity: 100%;
            }
        }
    }
    &-point-inactive,
    &-series-inactive {
        opacity: 1;
    }

    &-tooltip-container {
        z-index: $z-index-highcharts-tooltip !important;
    }
}

.highcharts-xaxis .highcharts-axis-line {
    stroke: var(--gray-300);
    stroke-width: 2px;
}

.highcharts-grid-line {
    stroke: var(--gray-150);
}

[class^='highcharts-plot-bands-'] {
    display: none;
}
