.NewCorner {
    background-color: var(--orange);
    text-align: center;
    position: absolute;
    transform: rotate(45deg);

    &-Container {
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
    }

    span {
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}
