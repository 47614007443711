.ProductPageSubTitle {
    > div {
        display: inline-block;
        margin-right: 0.5rem;
    }

    > div + div {
        border-left: solid 1px var(--gray-100);
        padding-left: 0.5rem;
    }
}
