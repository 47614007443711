.SourceOfStreamsSourcesDetailsPanel {
    display: grid;
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    gap: 20px;

    &-header {
        align-items: center;
        display: flex;
        gap: 3px;
        margin-bottom: 12px;

        .InfoGlyphIcon {
            display: block;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ValuePanelPercentage {
            grid-auto-columns: 125px auto;

            &-progress {
                grid-template-columns: 40px 25px;
            }
        }
    }
}
