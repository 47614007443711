.entityLoadingIndicator {
    display: grid;
    grid-template-areas: 'image name';
    grid-template-columns: 30px 200px;
    column-gap: 10px;

    .artwork {
        grid-area: image;
        min-width: 30px;
        height: 30px;
        width: 30px;
        margin-left: 2px;

        .SkeletonLoader-line {
            height: 30px;
            border-radius: 3px;
        }
    }

    &--landscape {
        grid-template-columns: 53px 200px;

        .artwork {
            width: 53px;

            .SkeletonLoader-line {
                width: 53px;
            }
        }
    }

    .name {
        grid-area: name;
        align-self: center;
        width: 200px;
        height: 16px;
        margin-top: 0;

        .SkeletonLoader-line {
            height: 16px;
            border-radius: 3px;
        }
    }

    &--with-label {
        grid-template-areas:
            'image name'
            'image type';

        .artwork {
            margin-top: 10px;
        }

        .name {
            margin-top: 6px;
        }

        .label {
            margin-top: 2px;
            grid-area: type;
            width: 50px;
            height: 20px;

            .SkeletonLoader-line {
                height: 20px;
                border-radius: 3px;
            }
        }
    }

    &--with-meta-data {
        grid-template-areas:
            'image name'
            'image type';

        .artwork {
            margin-top: 4px;
            margin-left: 0;
        }

        .name {
            margin-top: 4px;
        }

        .meta-data {
            margin-top: 2px;
            grid-area: type;
            width: 200px;
            height: 12px;

            .SkeletonLoader-line {
                height: 12px;
                border-radius: 3px;
            }
        }
    }
}
