.SourceOfStreamsSourcesPanel {
    display: grid;
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    gap: 20px;

    &-header {
        align-items: center;
        display: flex;
        gap: 3px;

        .InfoGlyphIcon {
            display: block;
        }
    }

    .ValuePanelPercentage {
        line-height: 32px;
    }
}
