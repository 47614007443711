/* stylelint-disable declaration-no-important */
@media print {
    .SuiteApplication {
        grid-template-columns: [main-content] auto;
    }

    .SuiteApplication,
    #root {
        max-width: 100%;
        overflow: initial;
    }

    .MainNav,
    .MainNav.collapsible {
        display: none;
    }

    .d-print-hide {
        visibility: hidden !important;
    }

    .d-print-pagebreak {
        display: inline-block;
    }

    .d-print-pagebreak::before {
        content: '\A';
        white-space: pre;
    }

    .Select__control,
    .Select-control {
        font-weight: 500;

        .Select__indicators,
        .Select-arrow {
            display: none;
        }
    }

    .btn {
        background: none;
        border: none;
    }

    .PageToolbar {
        .ComparisonFilters-period {
            margin-right: 1.5rem;
        }
    }

    .CompareSection-footer {
        display: none;
    }

    .DropdownToggleChevron {
        display: none;
    }

    .HelpTooltip {
        display: none;
    }

    .GridTable-column {
        &-tooltip {
            display: inline-block;
        }

        &:not(.sorted) {
            .ArrowUpGlyphIcon,
            .ArrowDownGlyphIcon {
                display: none;
            }
        }
    }

    .MainContent {
        display: contents;
    }

    .SortDefaultGlyphIcon,
    .SortAscendingGlyphIcon,
    .SortDescendingGlyphIcon {
        display: none;
    }

    .ProgressBar {
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .TopPlaylistsSection {
        page-break-before: always;
    }

    .TopPlaylistItem-title a,
    .LinkList a,
    .ParticipantCompareItem-name a {
        color: black;
        font-weight: normal;
        text-decoration: none;
    }

    .LoadingSpinner {
        display: none;
    }

    .PageContainer {
        margin-top: 10px;
    }

    .StickyHeader {
        display: none;
    }
}
