@use 'sass:string';

@mixin center-vertical {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
}

@mixin vertical-align {
    align-items: center;
    display: flex;
}

@mixin horizontal-align {
    display: flex;
    justify-content: center;
}

@mixin divider($width: 2px) {
    position: relative;

    &::after {
        background-color: var(--divider);
        content: '';
        height: 70%;
        left: 0;
        position: absolute;
        top: calc(50% - 70% / 2);
        width: $width;
    }
}

@mixin divider-v2($width: 1px, $height: 20px) {
    position: relative;

    &::after {
        background-color: var(--gray-500);
        border-radius: 2px;
        content: '';
        height: $height;
        left: -1px;
        position: absolute;
        top: calc(50% - $height / 2);
        width: $width;
    }
}

/* stylelint-disable value-no-vendor-prefix */
@mixin line-clamp($clamp: 2) {
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $clamp;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
/* stylelint-enable value-no-vendor-prefix */

@mixin truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

@function mix-alpha-color($color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: change-color($color, $alpha: 1);
    @return mix($opaque, $background, $percent);
}

.d-inlineflex {
    display: inline-flex;
    vertical-align: top;
}

.width100 {
    width: 100%;
}

$controls-list-classnames: ('dropdown-menu', 'Select__menu');
$layout-sections: (
    sticky: $z-index-sticky,
    page-toolbar: $z-index-toolbar-controls,
    compare-panel: $z-index-compare-panel-controls,
    page-col: $z-index-page-col-controls,
);

@mixin controls-z-index($section) {
    @if map-has-key($layout-sections, $section) {
        @each $classname in $controls-list-classnames {
            .#{$classname} {
                z-index: map-get($layout-sections, $section);
            }
        }
    }
}

// Function to find the last index of a character in a string
// Returns null if the character is not found
@function str-last-index($string, $char) {
    $last-index: null;
    $length: str-length($string);
    @for $i from 1 through $length {
        @if str-slice($string, $i, $i) == $char {
            $last-index: $i;
        }
    }
    @return $last-index;
}

// Function to extract the base colour from a colour string
// Example: extract-base-color('sup-green-1') returns 'sup-green'
@function extract-base-color($color-string) {
    $index-of-last-hyphen: str-last-index($color-string, '-');
    @if $index-of-last-hyphen {
        @return str-slice($color-string, 1, $index-of-last-hyphen - 1);
    } @else {
        @return $color-string;
    }
}

// Function to find the sub-map by colour in $data-colour-index (OSP) and return the sub-map of $base-colour
// Returns null if the sub-map is not found
// Example: find-sub-map-by-colour($data-colour-index, 'sup-green') returns the sub-map of 'sup-green'
@function find-sub-map-by-colour($data-map, $base-colour) {
    @each $key, $value in $data-map {
        @if map-get($value, 'colour') == $base-colour {
            @return $value;
        }
    }
    @return null;
}
