.ValuePanelPercentage {
    display: grid;
    align-items: center;
    grid-auto-columns: 75px auto;
    grid-auto-flow: column;
    column-gap: 10px;

    &-container {
        display: flex;
        row-gap: 10px;
        flex-direction: column;
    }

    &-value {
        overflow: hidden;

        .TruncatedText {
            display: block;
        }
    }

    &-progress {
        font-size: 12px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 90px 35px;
        column-gap: 10px;
        text-wrap: nowrap;

        &-value {
            color: var(--text-secondary);
            order: 1;
        }

        &-bar {
            &.unknown {
                background-color: var(--text-secondary);
                border-color: var(--text-secondary);
            }
        }

        .ProgressBar-container {
            height: 10px;
        }
    }
}
