.ChannelPage {
    .DataSection {
        box-shadow: 0 0 4px 0 var(--border);

        &-header {
            padding: $grid-gap $grid-gap 0;

            &-title {
                font-weight: 400;
                line-height: 1.2;
                margin-bottom: 5px;
            }
        }

        &-subheader {
            padding: 0 0 $grid-gap $grid-gap;
            margin: 0;
        }
    }

    .ContentPanel-empty {
        min-height: 15rem;
    }

    .highcharts-tooltip-date {
        display: none;
    }
}
