.ControlGroup {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    margin-bottom: 0;

    &-clearButton {
        padding: 10px;
        border: none;
        background: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &:hover,
        &:active {
            cursor: pointer;
            background-color: transparent;
            box-shadow: none;
            border: none;
        }

        &-icon {
            color: var(--text-secondary);
        }
    }
}
