.ColoredSection {
    @for $i from 1 through 10 {
        &-color-#{$i} {
            border-top: 4px solid var(--dataviz-#{$i});
            border-radius: 0.5rem;

            & > :first-child {
                border-top: none;
            }
        }
    }
}

.SuiteColorSchemeV2 {
    .ColoredSection {
        @for $index from 1 through length($data-colour-index) {
            &-color-#{$index} {
                border-top: 4px solid var(--data-#{$index});
                border-radius: 0.5rem;

                & > :first-child {
                    border-top: none;
                }
            }
        }
    }
}
