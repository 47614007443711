.SongUGCPage {
    .InfoMessage-body {
        text-align: center;
        color: var(--gray-500);
    }

    .ErrorV2 .tableView {
        height: calc(100vh - 253px);
    }
}

.Error-without-tabs.ErrorV2 .tableView {
    height: calc(100vh - 201px);
}
