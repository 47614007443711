.CountryCard {
    padding: 0;

    &.tableView {
        width: 50px;
    }

    &.modalView {
        width: 72px;
    }

    &-with-minWidth {
        min-width: 72px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
    }

    &-position {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px;
    }

    &-country {
        &-container {
            display: flex;
            align-items: center;

            .CountryFlag {
                pointer-events: none;
            }

            p {
                text-transform: uppercase;
                font-size: 12px;
                line-height: 16px;
                margin: 0 0 0 2px;
            }
        }
    }

    &-not-featured {
        .CountryCard-position {
            color: var(--text-primary-disabled);
        }

        .CountryCard-country-container {
            p {
                color: var(--gray-400);
            }

            .CountryFlag {
                opacity: 0.6;
            }
        }
    }
}
