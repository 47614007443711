@keyframes show-overflow {
    from {
        overflow: hidden;
    }
}

.CollapsePanel {
    &-toggle {
        position: absolute;
        right: 0;
    }

    &-content {
        max-height: 0;
        overflow: hidden;

        &.expanded {
            overflow: visible;
            max-height: 1000px;
        }

        &.animate {
            transition: max-height 0.5s;

            &.expanded {
                animation: show-overflow 1s;
            }
        }
    }
}
