.PerformanceOverTime {
    gap: 20px;
    padding: 20px;

    .InsightsGridTable-section-table:has(.InsightsGridTable.zebra-variant) {
        border: 1px solid var(--dividers-light-stroke);
    }

    .InsightsGridTable {
        .GridTable-container {
            max-height: 310px;
            overflow-y: auto;
        }

        .col-checkbox + .GridTable-column,
        .cell-checkbox + .GridTable-cell {
            border-left: none;
        }
    }

    &-loader {
        height: 262px;
        align-items: center;
        .SkeletonLoader-item {
            display: flex;
            justify-content: center;
        }
    }

    .InfoMessageV2 {
        height: 446px;
        padding: 0;
        justify-content: center;
    }

    &-metric-dropdown {
        margin-left: 1rem;
    }

    .DataSection-header {
        padding: 0;
    }

    &HeaderMenu {
        align-items: center;
        align-self: flex-start;
        display: flex;
        gap: 20px;
        &.V2 {
            gap: 10px;
            .SegmentedButton {
                margin-right: 10px;
            }
        }

        .Glyph {
            fill: var(--text-secondary);
        }

        &-view-button {
            height: 40px;
            svg {
                transform: scale(1.5);
            }
        }

        &-separator {
            border-bottom: 1px solid var(--dividers-default);
        }
    }

    &-chart-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 1rem 0;

        .highcharts-title {
            font-size: 1rem;
            fill: var(--text-primary);
        }

        .PerformanceChart {
            border-bottom: 1px solid var(--gray-200);
            flex-basis: 33%;

            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(8),
            &:nth-child(11) {
                border-left: 1px solid var(--gray-200);
                border-right: 1px solid var(--gray-200);
            }
        }

        &.duo > .PerformanceChart {
            flex-basis: 50%;
        }
    }

    &-table {
        &.totals {
            .GridTable-row:first-of-type {
                font-weight: bold;
            }
        }

        .cell-checkbox,
        .col-checkbox {
            padding-right: 0;
        }

        .col-checkbox {
            padding-left: 8px;

            .SkeletonLoader-line {
                width: 16px !important;
                height: 16px;
            }
        }

        .cell-title {
            .only {
                color: var(--blue);
                cursor: pointer;
                margin-left: 1rem;
                opacity: 0;
                outline: none;
                transition: opacity ease 0.2s;
            }

            &:hover {
                .only {
                    opacity: 1;
                }
            }
        }

        &-title-only {
            display: flex;
        }
    }

    &-table-title-cell-upc {
        color: var(--text-secondary);
        font-size: 0.85rem;
    }

    .HeaderMenuV2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-title {
            @include typography-h3;
        }
        &-first-level {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 32px;
            & > div {
                display: flex;
                align-items: center;
                gap: 20px;
            }
            & .withTooltip {
                gap: 8px;
            }
        }
        &-second-level {
            display: flex;
            justify-content: space-between;
            height: 32px;
            align-items: center;
            gap: 20px;
            &-filters {
                gap: 10px;
                display: flex;
            }

            &-controls {
                gap: 20px;
                display: flex;
            }
        }
    }

    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 130px;
        }
    }
}
