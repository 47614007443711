.SearchAllResultsPage {
    h2 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &-container {
        width: 100%;

        &--two-column {
            display: flex;
            justify-content: space-between;
            gap: $grid-gap;
        }
    }

    &-section {
        flex-basis: 50%;
    }

    &-row {
        margin-top: 19px;

        &-list {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: $grid-gap;

            @media screen and (max-width: 1727px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));

                .hidde-on-small-screen {
                    display: none;
                }
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 19px;
    }

    .ErrorV2 .section {
        height: 420px;
    }
}
