.GridTable {
    .GridTable-cell {
        padding: 8px;

        .percentageIncrease {
            color: var(--text-success);
        }

        .percentageDecrease {
            color: var(--text-error);
        }
    }

    .ExternalLinkCell {
        min-width: 0;

        .ExternalLinkGlyph {
            fill: var(--blue);
        }

        .ExternalLinkGlyphIcon {
            color: var(--blue);
        }
    }

    .StarredCell {
        cursor: pointer;
        outline: none;
    }

    .ParticipantLinkWithCounterCell {
        display: flex;
        min-width: 0;

        &_counter {
            min-width: min-content;
            padding-left: 5px;
        }
    }

    .ParticipantLinkCellWithArtistTypeCell {
        min-width: 0;

        .LinkList {
            @include truncated-text;
        }
    }
}
