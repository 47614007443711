.PlaylistHighlights {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: $grid-gap;

    &-no-data {
        margin: auto 0;
        text-align: center;
    }

    &-header {
        color: var(--text-secondary);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding: 20px 0 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        min-width: 0;
        overflow: hidden;
        row-gap: 10px;

        &-metadata {
            align-self: stretch;
        }

        &-cover,
        &-link {
            &.btn {
                height: auto;
            }
        }

        &-cover {
            padding: 0;
            position: relative;

            .AppleMusicStoreIcon,
            .AmazonStoreIcon,
            .SpotifyStoreIcon {
                position: absolute;
                width: 24px;
                height: 24px;
                bottom: -4px;
                right: -4px;
            }
        }

        &-link {
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: normal;
            text-transform: none;
            max-width: 100%;
            padding: 0;
            line-height: normal;

            .TruncatedText {
                display: block;
            }
        }

        &-song {
            line-height: 20px;
        }

        &-streams {
            margin-top: var(--padding-medium-small, 8px);
            line-height: 16px;
            color: var(--text-secondary);
        }
    }
}
