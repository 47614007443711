.InfoMessage {
    @include center-vertical;

    .GlyphIcon {
        color: var(--gray-300);
        height: 6rem !important;
        width: 6rem !important;
    }

    &-title {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: pre-wrap;
        margin: 1rem auto;
        font-weight: normal;
    }

    &-body {
        font-size: 0.9rem;
        margin-top: 1rem;
        text-align: left;
        white-space: pre-wrap;
    }
}
