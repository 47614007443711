.ThumbnailPlaylistLoadingIndicator {
    display: flex;
    align-items: center;
    gap: 8px;

    &-titleContainer {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &-metaContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
}
