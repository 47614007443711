.TrendingPageHeaderLastUpdated {
    display: flex;
    gap: 4px;
    white-space: nowrap;
    align-items: center;

    &:not(:last-of-type)::after {
        content: '|';
        position: relative;
        right: -4px;
    }

    &-title {
        font-weight: var(--weight-bold);
    }

    .SkeletonLoader {
        display: block;
    }
}
