.SourceOfStreamsTotalsPanel {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 20px;

    .ValuePanel {
        &-value {
            line-height: 32px;
        }
    }
}
