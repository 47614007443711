.YoutubePolicyModal {
    .ReactModal {
        &-body {
            display: flex;
        }

        &-content {
            padding: 10px;
            max-height: calc(100vh - 50px);
            width: 1020px;
        }

        &-close {
            top: 20px;
            position: relative;
        }

        &-header {
            border-bottom: none;
            height: 0;
            padding: 0 10px;
        }
    }

    &-container {
        display: grid;
        grid-template-columns: 225px 1fr;
        width: 100%;
    }

    &-table {
        margin-top: 10px;

        tr:last-child td {
            border-bottom: none !important;
        }

        td > div {
            display: inline-block;
        }
    }

    &-label {
        color: var(--text-secondary);
    }

    &-policies {
        margin-left: 70px;
    }

    &-countries {
        display: grid;
        grid-template-columns: repeat(18, 35px);
        margin-top: 10px;
    }

    &-country {
        padding: 0.5rem;
        color: var(--text-primary);
        cursor: pointer;
    }

    &-tooltip span {
        margin-left: 8px;
    }

    &-metadata {
        padding-left: 0.75rem;
    }

    &-link {
        display: block;
        margin-bottom: 0.75rem;
        overflow-wrap: break-word;

        .ExternalLinkGlyph {
            fill: var(--blue);
        }

        &:hover .ExternalLinkGlyph {
            fill: #004a94;
        }

        .ExternalLinkGlyphIcon {
            color: var(--blue);
        }

        &:hover .ExternalLinkGlyphIcon {
            color: #004a94;
        }
    }
}
