.ErrorV2 {
    .tableView {
        height: calc(100vh - 169px);
    }

    &.withFilter {
        .tableView {
            height: calc(100vh - 221px);
        }
    }
}
