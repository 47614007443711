.SocialLinks {
    margin-left: auto;
    padding: 0.3rem 0;

    &-label {
        color: var(--text-secondary);
        font-size: 0.85rem;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-right: 0.5rem;
    }

    &-link .PlatformIcon {
        display: inline-block;
    }

    &-link:last-child .PlatformIcon {
        margin-right: 1rem;
    }
}
