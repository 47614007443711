.SourceOfStreamsChart {
    .highcharts-data-label {
        opacity: 0;
    }
    .highcharts-series-hover .highcharts-data-label {
        opacity: 1;
    }

    &-x-labels {
        text-align: right;
    }

    &-labels {
        width: 130px;
    }
}
