.dot {
    border-radius: 100%;
    display: inline-block;
}

.dot-sm {
    height: 7px;
    width: 7px;
}

.dot-md {
    height: 12px;
    width: 12px;
}
