.CountriesIndicator {
    display: flex;
    align-items: center;
    gap: 8px;

    &-pill {
        &-content {
            display: flex;
            align-items: center;
            font-size: 12px;

            .CountryFlag {
                margin-right: 4px;
            }

            b {
                margin-left: 4px;
            }
        }

        &-tooltip {
            &-content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 2px 12px;

                & > div {
                    display: flex;
                    align-items: center;

                    .CountryFlag {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

/* stylelint-disable selector-id-pattern */
#CountriesIndicator-pill-tooltip-TooltipMessage {
    .tooltip-inner {
        max-width: 880px;
    }
}
/* stylelint-enable selector-id-pattern */
