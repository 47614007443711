.ChartsToolbar {
    margin-bottom: 20px;
    margin-top: 17px;

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &-switcher {
        margin-right: 15px;
        margin-top: 2px;
    }

    .left,
    .right {
        display: flex;
        align-items: center;
    }

    .left {
        gap: 10px;
    }

    .right {
        margin-left: auto;
    }
}
