.InsightsGridTable {
    &-section-table {
        box-shadow: none;
    }

    &.zebra-variant {
        .GridTable-column {
            .GridTable-column-container {
                gap: 8px;
            }

            &.util-align-right {
                .GridTable-column-container {
                    .GridTable-column-sort-indicator {
                        order: -2;
                    }
                }
            }
        }

        .GridTable-row-cell {
            &.fixed,
            &:first-child,
            &:last-child {
                &::before {
                    pointer-events: none;
                }
            }

            &.fixed:not(:first-child):not(:last-child)::before {
                border-radius: 0;
            }
        }

        .GridTable-header-row {
            .GridTable-row-cell {
                height: unset;

                &.sorted-1 {
                    .GridTable-column-sort-indicator {
                        display: none;
                    }
                }
            }
        }

        &.with-sticky-header.with-auto-height {
            .GridTable-header-row {
                &.fixed {
                    .GridTable-row-cell {
                        border-bottom-width: 3px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .InsightsGridTable {
        --max-width-290: 290px;
        --max-width-325: 325px;
    }
}
