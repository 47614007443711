.SuiteColorSchemeV2 {
    .SegmentedButton-btn {
        &.active {
            &:disabled {
                color: var(--gray-500);

                &::after {
                    border-bottom: 1px solid var(--gray-500);
                }
            }
        }
    }
}
