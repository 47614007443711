.SeriesValueComparison {
    &-row {
        @include vertical-align;

        flex-direction: row;
    }

    &-icon {
        margin-right: 5px;
    }

    .TruncatedText {
        display: block;
        flex: 1;
    }
}
