.ProductTracksPage {
    &-filter {
        width: $filter-width;
        @media print {
            display: none;
        }
    }

    &-toolbar {
        display: flex;
        justify-content: space-between;

        &-info {
            display: flex;
            align-items: center;
            gap: 10px;

            .Dropdown {
                margin-left: 1rem;
            }
        }
    }
}
