$dropdown-menu-width: 30rem;

.DropdownMenu {
    .dropdown-menu {
        box-shadow: $box-shadow;
        width: $dropdown-menu-width;
    }

    .show > .dropdown-menu {
        display: block;
    }

    &-item {
        align-items: center;
        display: flex;
        padding: 0.8rem 1rem;
        position: relative;

        &-submenu {
            display: none;
            height: 60vh;
            left: $dropdown-menu-width - 0.2;
            overflow-y: scroll;
            position: absolute;
            top: 0;

            &.show {
                display: block;
            }
        }

        &-submenu-icon {
            fill: var(--gray-800);
            text-align: right;
            margin-left: auto;
        }

        &:hover {
            background-color: $bg-hover;
            text-decoration: none;
        }
    }
}
