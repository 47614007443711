.DataSourcesStatus {
    display: inline-block;

    .popover {
        max-width: none;
        z-index: 9;
    }

    &-trigger {
        @include vertical-align;

        color: var(--gray-500);
        font-weight: bold;
        text-transform: none;
        gap: 5px;

        &-inverted {
            flex-direction: row-reverse;
        }

        &:focus {
            text-decoration: none;
        }

        &-icon {
            display: flex;
            align-items: center;
        }
    }

    &-table {
        td {
            vertical-align: middle;
        }
        td:not(:first-child) {
            text-align: center;
        }
        td:first-child {
            padding-right: 30px;
        }
    }

    &-header {
        @include vertical-align;

        border-bottom: 1px solid var(--border);
        justify-content: space-between;
        padding: 1rem;

        &-link {
            @include vertical-align;
        }

        &-title {
            margin-bottom: 0;
            margin-right: 5px;
        }
    }

    &-footer {
        @include vertical-align;

        border-top: 1px solid var(--border);
        padding: 1rem;
    }

    &-legend {
        @include vertical-align;

        margin-right: 1rem;

        .dot {
            margin-right: 0.5rem;
        }
    }
}
