.ErrorMessage {
    @include center-vertical;

    .Glyph {
        height: 5rem;
        width: 5rem;
        margin-bottom: 2rem;
    }

    .Glyph.WarningGlyph {
        height: 6rem;
        width: 6rem;
        fill: var(--danger);
        margin-bottom: 1rem;
    }

    .card-header-tabs {
        margin: 0;
    }

    &-title {
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    &-details {
        max-width: 800px;
        width: 100%;

        .tab-content {
            height: 300px;
            overflow: auto;
        }
    }

    &-details-toggle {
        margin-bottom: 1rem;
    }

    &-report {
        margin-bottom: 1rem;
    }

    &-text {
        font-size: 0.9rem;
        margin-top: 1rem;
        text-align: left;
        white-space: pre-wrap;
    }

    &-logoContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2.5rem;
    }

    &-logoTitle {
        margin-left: 10px;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.65px;
    }

    &-errorMessage {
        color: var(--gray-500);
        font-size: 1.4rem;
        max-width: 800px;
        margin-bottom: 3rem;
    }

    &-refreshButton {
        padding: 1rem 5rem;
        border-radius: 3rem;
        margin-bottom: 3rem;
    }
}
