.ParticipantPlaylists {
    &-full-error {
        margin-top: 20px;
    }

    .ParticipantPlaylistsFilters {
        display: flex;
        flex-wrap: wrap;
        margin: 19px 0 14px 0;
        box-sizing: border-box;
        gap: 10px;

        &-search {
            flex-shrink: 0;
            margin-bottom: 0;
        }
    }

    .ParticipantPlaylistsGridTable {
        &-playlistName {
            border-right: none !important;
        }

        &-songLoadingIndicator {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }
}
