.ArtistResultsGridTable {
    .GridTable-column.separator,
    .GridTable-cell.separator {
        &.col-country {
            border-left: none;
        }
    }

    .GridTable-cell.cell-country.separator {
        border-left: none;
    }

    .GridTable-column.GridTable-row-cell {
        &.col-monthlyListeners,
        &.col-streams7Days,
        &.col-streams28Days {
            padding: 9px;
        }
    }

    .CountryFlag {
        width: 20px;
    }

    .CoverArtCell {
        padding-top: 1px;
        padding-bottom: 2px;
    }
}
