.SearchAllResultsPage-Entity-Error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: var(--white);
    box-shadow: 0 0 5px 0 #1c1c1c26;

    &-button {
        padding-bottom: 0;
    }

    .InfoMessageV2 {
        padding: 0;
        gap: 0;
        white-space: pre-wrap;
        text-align: center;
    }
}
