.pillLoadingIndicator {
    width: 100px;

    &__skeleton {
        .SkeletonLoader-line {
            border-radius: 32px;
            height: 32px;
        }
    }
}
