.MultiProductToggle {
    display: flex;
    align-items: center;
    line-height: 1.4rem;
    color: var(--gray-800);

    &-switch {
        margin: 0 6px;

        > .custom-control-label {
            cursor: pointer;
        }
    }

    &-help-tooltip {
        padding-bottom: 3px;
        .InfoGlyph {
            fill: var(--text-secondary);
        }
        &-overlay {
            .tooltip-inner {
                max-width: 324px;
                text-align: left;
                white-space: pre-wrap;
            }
        }
    }
}
