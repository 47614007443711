.CatalogTable {
    td {
        display: flex;
        align-items: center;
    }
    th.chart-col-artistName,
    th.chart-col-labelName,
    td.cell-artistName,
    td.cell-labelName {
        max-width: 200px;
        min-width: 150px;
        width: auto;
    }
    td.cell-songName {
        min-width: 200px;
    }
    td.cell-genre {
        min-width: max-content;
    }
    td.cell-releaseDate {
        min-width: max-content;
    }
    td.cell-streams1Day,
    td.cell-growthPercentage1Day,
    td.cell-streams7Days,
    td.cell-growthPercentage7Days,
    td.cell-streams28Days,
    td.cell-growthPercentage28Days,
    td.cell-streamsAllTime {
        min-width: 70px;
    }
    td span.percentageIncrease {
        color: var(--text-success);
    }
    td span.percentageDecrease {
        color: var(--text-error);
    }
}
