.EditableText {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
        margin-bottom: 0;
    }

    &-text-container,
    &-field {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 12px;
    }

    &-text-container {
        margin-bottom: 20px;
        max-width: 440px;
    }

    &-text {
        margin-top: 0;
        line-height: 24px;
        font-size: 14px;
        color: var(--text-primary, #22262a);
    }

    &-control-container {
        flex-grow: 1;
        position: relative;
    }

    &-control {
        height: 32px;

        .ControlGroup-clearButton {
            padding: 4px;
        }
    }

    &-clear-button.btn {
        position: absolute;
        right: 8px;
        top: 50%;
        padding: 4px;
        transform: translateY(-50%);

        .ClearGlyphIcon {
            color: var(--text-secondary);
        }
    }

    &-btn-edit.btn {
        display: none;
        padding: 6px;
        border: none;

        .EditGlyphIcon {
            color: var(--midnight-700, #225d87);
        }
    }

    &:hover {
        .EditableText-btn-edit {
            display: inline-flex;
        }
    }

    &-btn-submit {
        border-radius: 12px;
        background: var(--midnight-150, #cee3f3);
        padding: 6px;
        border: none;

        .CheckGlyphIcon {
            color: var(--midnight-750, #1d4f72);
        }

        &:hover {
            background: var(--midnight-200, #bad8ee);
        }
    }

    &-btn-cancel {
        .CloseGlyphIcon {
            color: var(--midnight-650, #276b9b);
        }
    }

    &-popover-content {
        padding: 12px;
        color: #a11e12;
        line-height: 18px;
        font-size: 12px;
    }

    .OrchardSuiteFramework.popover {
        top: 36px !important;
        width: 230px;
    }
}
