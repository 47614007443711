.CompactComparisonFilters {
    display: flex;
    gap: 10px;

    .MarketsSelector {
        margin-right: unset;
    }

    .ProfitCenterFilter {
        margin-left: unset !important;
    }

    &-period {
        @include vertical-align;

        gap: 8px;

        .HelpTooltip {
            color: var(--gray-500);
        }
    }
}
