.DefaultStoreSection {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-header {
        @include vertical-align;

        column-gap: 8px;
    }
}
