.SpotifyPlayer {
    background: rgb(0 0 0 / 80%);
    bottom: 2rem;
    color: var(--white);
    display: flex;
    flex-direction: row;
    opacity: 0;
    padding: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 2.5rem;
    transition: all ease 0.5s;
    width: 20rem;
    z-index: 10;

    &.ready {
        opacity: 1;
        pointer-events: auto;
    }

    &-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0.5rem;
    }

    &-artists,
    &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-artists {
        font-size: 0.9rem;
    }

    &-controls {
        align-items: center;
        display: flex;
        margin-left: auto;
    }

    &-play-btn {
        outline: none;
        margin-right: 0.5rem;
    }

    &-play-btn,
    &-pause-btn {
        path {
            fill: var(--white);
        }
    }

    &-close-btn {
        align-self: flex-start;
        outline: none;
        padding: 2px;

        .CloseGlyph {
            display: block;
            height: 10px;
            width: 10px;
        }
    }
}
