.ChartsViewToggle {
    .btn {
        &.btn-secondary {
            box-shadow: none;
            background: none;
            border: none;
            position: relative;
            padding: 0.393rem 0.929rem;

            .GlyphIcon {
                color: var(--text-secondary);
            }

            &:not(:last-of-type)::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-46%);
                width: 1px;
                height: 20px;
                background-color: var(--gray-chateau);
            }

            &:disabled,
            &.disabled {
                opacity: 1;

                .GlyphIcon {
                    opacity: 1;
                    color: var(--gray-chateau);
                }
            }

            &:not(:disabled):not(.disabled) {
                box-shadow: none;
                border: none;
                background: none;
            }

            &:active:not(:disabled):not(.disabled),
            &.active:not(:disabled):not(.disabled) {
                box-shadow: none;
                border: none;
                background: none;

                .GlyphIcon {
                    color: var(--midnight-800);
                }

                &:focus {
                    box-shadow: none;
                    border: none;
                    background: none;
                }
            }

            &:not(.active):not(.disabled):not(:disabled):hover {
                .GlyphIcon {
                    color: var(--midnight-700);
                }
            }
        }
    }
}
