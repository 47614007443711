.DataList {
    display: flex;
    flex-direction: row;

    .DataListItem {
        display: flex;
        font-size: 0.85rem;
        margin: 0 0.75rem;
    }

    .DataListItem:first-child {
        margin-left: 0;
    }

    .DataListItem:last-child {
        margin-right: 0;
    }

    .DataListItemLabel {
        color: var(--text-secondary);
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 0.4rem;
    }
}
