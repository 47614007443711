.VideoComparePanel {
    &-video-info {
        flex: 1;
        width: 0;
    }

    &-name {
        font-size: $h4-font-size;
    }
}
