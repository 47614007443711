@mixin streams-by-stores-layout($name, $color) {
    &-#{$name} {
        color: $color !important;

        &,
        circle {
            fill: $color !important;
            stroke: none;
        }
    }
}

.PlaylistStreamsByStores {
    margin-bottom: $grid-gap;

    .DataSection {
        height: 100%;
        margin-bottom: 0;

        &-header {
            padding: $grid-gap;
        }
    }

    .highcharts-markers {
        stroke: white !important;
    }

    .highcharts-area {
        fill-opacity: 100%;
    }

    &-spotify {
        $color-spotify-light: mix-alpha-color(
            rgba($color-spotify, 0.4),
            $white
        );

        @include streams-by-stores-layout('playlists', var(--color-spotify));
        @include streams-by-stores-layout('all', $color-spotify-light);
    }
    &-amazonMusic {
        $color-amazon-music-light: mix-alpha-color(
            rgba($color-amazon-music, 0.4),
            $white
        );

        @include streams-by-stores-layout(
            'playlists',
            var(--color-amazon-music)
        );
        @include streams-by-stores-layout('all', $color-amazon-music-light);
    }
    &-appleMusic {
        $color-apple-music-light: mix-alpha-color(
            rgba($color-apple-music, 0.4),
            $white
        );

        @include streams-by-stores-layout(
            'playlists',
            var(--color-apple-music)
        );
        @include streams-by-stores-layout('all', $color-apple-music-light);
    }

    .SeriesValueComparison {
        width: 50%;
    }
    .PerformanceChart {
        &-error {
            top: 0;
        }

        .LoadingSpinner {
            top: 30%;
            z-index: 1;
        }
    }
}
