.ChartDigest {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &-card {
        padding: 10px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &:not(.no-hover):hover {
            box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
        }

        .d-flex-center {
            display: flex;
            align-items: center;
        }

        &-title {
            font-size: 16px;
            line-height: 22px;
            color: var(--text-primary);
        }

        &-subtitle {
            font-size: 12px;
            line-height: 18px;
            color: var(--text-secondary);
            margin-right: 5px;
        }

        &-value {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &Content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &ComparisonMode {
            display: flex;
        }

        &-container {
            &-cardsList {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px;
                margin-top: 20px;

                &.no-data {
                    grid-template-columns: 1fr;
                }
            }
        }

        &-subtitle {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            margin-bottom: 0;

            &Container {
                display: flex;
                align-items: center;
                .HelpTooltip {
                    margin-left: 5px;
                    margin-bottom: 2px;
                }
            }
        }

        &-error {
            margin-top: 20px;
        }
    }

    &Footer {
        display: flex;
        justify-content: flex-end;

        &-all-charts {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1px;
        }
    }

    &HelpTooltip {
        &-overlay {
            .tooltip-inner {
                text-align: left;
                white-space: pre-wrap;
                max-width: 300px;
            }
        }
    }
}
