.AccountRecentReleases {
    .DataSection-content {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: space-between;
    }
    .DataSection-header a {
        font-size: 12px;
        font-weight: var(--weight-bold);
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}
