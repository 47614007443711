.InfoMessageV2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 40px 65px 40px;
    gap: 10px;

    &-title {
        @include typography-body;
    }

    &-message {
        @include typography-small;

        color: var(--text-secondary);
    }
}
