.ComparisonTableHeader {
    align-items: center;
    background-color: var(--gray-100);
    border-radius: 3px;
    display: flex;
    font-size: 14px;
    padding: 0.75rem;
    min-height: 4rem;
    max-height: 4rem;

    .DotGlyphIcon {
        display: block;
    }

    &-content {
        flex: 1;
        font-weight: $font-weight-bold;
        min-width: 0;
        padding: 0 10px;
    }
}
