.SaveScreenPreview {
    margin-top: 2rem;

    &-text {
        color: var(--text-secondary);
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    button {
        margin-bottom: 0;
        padding: 0.8rem 3.8rem;
        border: 1px solid var(--gray-500);
        color: var(--text-primary);
        border-radius: 100px;
    }
}
