.ParticipantSearch {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 17%);
    position: absolute;
    right: 0;
    width: 90%;
    z-index: 10;

    &-empty {
        &-text {
            background-color: var(--white);
            border: 1px solid var(--gray-400);
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-top-color: var(--gray-200);
            padding: 1.7rem;
            text-align: center;
        }

        .Select-control {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .Select-menu {
        max-height: 40vh;
    }

    .GlobalSearchResults-footer {
        padding: 0;

        &-static {
            font-style: italic;
            padding: 2rem;
        }
    }

    .is-focused {
        .Select-control {
            border-color: var(--gray-400);
        }
    }
}
