.PlaylistLink {
    align-items: center;
    display: flex;
    overflow: hidden;

    .TruncatedText {
        display: block;
    }

    .ExternalLinkGlyph {
        display: block;
        overflow: visible;
        margin: 0 5px;
    }

    .ExternalLinkGlyphIcon {
        margin: 0 5px;
        color: var(--midnight-650, #276b9b);
    }
}
