.AccountPageVendorRowBrandItem {
    align-items: center;
    display: flex;

    .BrandIcon {
        display: inline-block;
        height: 1rem;
        width: 1rem;
        margin: 0 5px 0 0;
        pointer-events: none;
    }
}
