.AppearancesDropdown {
    .Select__single-value {
        .Dropdown-option {
            @include truncated-text;

            display: block;
        }
    }
    .TruncatedText {
        display: flex;
    }
}
