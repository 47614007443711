.CatalogProductPage {
    &-filter {
        width: $filter-width;
        min-width: $filter-width;
    }

    &-toggle {
        justify-content: flex-end;
    }
}
