.TopPositionsCard {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-title {
        margin-top: 2px;
    }

    &-placements {
        display: flex;
        align-items: baseline;

        .ChartDigest-card-subtitle {
            margin-right: 3px;
        }
    }

    &-value {
        margin-top: 2px;
    }

    &-stores {
        display: flex;
        margin-top: 7px;

        .PlatformIcon {
            &:not(:last-child) {
                margin-right: 5px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &-empty {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
        }
    }
}

.ChartDigestContent-container {
    &.NoTopPositions {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        color: var(--text-secondary);
        height: 60px;

        & > p {
            margin-bottom: 20px;
        }
    }
}
