.SongComparisonSelection {
    min-height: initial;
    display: flex;
    gap: 20px;
    padding: 20px;

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-compareItems {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        gap: 20px;
        justify-content: space-between;
    }
}

.ComparisonSearch {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 100%;
    min-width: 560px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 4px 4px 20px 4px rgba(13 36 53 / 4%);
    z-index: 1000;

    &Container {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-radius: 8px;
        box-shadow: 1px 1px 4px 1px rgba(13 36 53 / 8%);
        gap: 6px;

        &-button.btn {
            flex: 1;
            justify-content: center;
            height: 100%;
            border-radius: 8px;
        }
    }

    &OptionLabel {
        display: flex;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            border-radius: 2px;
            margin-right: 8px;
        }

        &-title {
            display: flex;
            gap: 4px;
            align-items: center;
            color: var(--text-primary);
        }

        &-subtitle {
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: space-between;
            color: var(--text-secondary);
            line-height: 18px;
        }

        .GlyphIcon {
            margin-right: 4px;
            min-width: fit-content;
        }

        &-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: 0;
            white-space: nowrap;
        }
    }
    .SuiteListView-option-content {
        width: 100%;
    }
    .SuiteListView-option:hover {
        background: linear-gradient(88deg, #deedf7 0%, #fff 100%);

        &-content {
            width: 100%;
        }
    }
    .SuiteListView-header {
        flex-direction: column;

        &-recentSelections {
            color: var(--gray-600, #67737e);
            text-transform: initial;
            margin-top: 18px;
            margin-bottom: -5px;

            @include text-bold;
        }
    }
}

.ComparisonItem {
    display: flex;
    flex: 1;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    box-shadow: 1px 1px 4px 1px rgba(13 36 53 / 8%);
    align-items: center;

    &-title {
        display: flex;
        justify-content: space-between;
        @include text-heading(4);
    }

    &-artwork {
        width: 80px;
        height: 80px;
        border-radius: 2px;
        overflow: hidden;
    }

    &-metadata {
        display: flex;
        flex: 1;
        gap: 12px;
        flex-direction: column;
        overflow: hidden;
    }

    &-artist {
        margin-bottom: 8px;
        height: 20px;
        @include text-body;
    }

    &-releaseDate {
        height: 18px;
        color: var(--text-secondary);
        @include text-small;
    }
}
