.ArtistSearchFilters {
    background-color: var(--white);
    border-left: $border;
    bottom: 0;
    box-shadow: -2px 0 5px 1px rgb(0 0 0 / 6%);
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;

    &-content {
        height: 100%;
        overflow: auto;
        padding: 15px;
        padding-bottom: 75px;
    }

    &-section {
        margin-bottom: 15px;
    }

    &-filters {
        > .btn {
            color: var(--gray-400);
        }
    }

    &-facets {
        position: relative;
        min-height: 400px;

        .LoadingSpinner.show {
            position: absolute;
            top: 0;
        }
    }

    &-footer {
        background-color: var(--white);
        border-top: $border;
        bottom: 0;
        padding: 15px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;

        .btn {
            margin-right: 10px;
        }
    }

    &-feature {
        border: 1px solid var(--gray-400);
        padding: 10px;
        display: block;
        cursor: pointer;

        &:hover {
            border-color: var(--text-secondary);
        }

        &.enabled {
            border-color: var(--text-secondary);
            background-color: var(--gray-100);

            &:hover {
                border-color: var(--gray-400);
            }
        }

        input,
        label {
            cursor: pointer;
        }

        label {
            font-weight: bold;
        }
    }
}

.ArtistSearchFacets {
    margin-bottom: 15px;

    .checkbox {
        display: flex;
        align-items: center;
    }

    &-item {
        display: flex;
    }
}

.ArtistSearchFilter {
    margin-bottom: 15px;

    &-filter {
        input:-webkit-autofill {
            box-shadow: 0 0 0 30px white inset !important;
        }
    }
}

.ArtistRangeFilter {
    margin-bottom: 15px;
}
