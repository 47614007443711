@use 'sass:map';
@use 'sass:list';

.PerformanceOverTime {
    .SummaryRowCheckbox {
        // We need this override because OSP component has incorrect width with colorIndex,
        // we can remove it once it will be fixed
        @each $name, $value in $data-colour-index {
            $active-1: map.get($value, 'active-1');
            $colour-name: map.get($value, 'colour');
            $active-color-1: var(--#{$colour-name}-#{$active-1});
            &.dataviz-#{$name}.checked {
                input {
                    background-color: $active-color-1 !important;
                    border-color: $active-color-1 !important;
                }
            }
        }

        // We need this override because OSP component doesn't support platform colors
        @each $name, $value in $extended-stores-chart-colors {
            $color-identifier: list.nth($value, 1);
            $colour-name: extract-base-color($color-identifier);
            $base-color-map: find-sub-map-by-colour(
                $data-colour-index,
                $colour-name
            );
            $active-1: map.get($base-color-map, 'active-1');
            $active-color-1: var(--#{$colour-name}-#{$active-1});

            &.dataviz-platform-#{$name}.checked {
                input {
                    background-color: $active-color-1 !important;
                    border-color: $active-color-1 !important;
                }
            }
        }
        &.dataviz-platform-pandora.checked {
            input {
                background-color: var(--indigo-1000) !important;
                border-color: var(--indigo-1000) !important;
            }
        }
    }
}
