.ChangeCell {
    .moveUp,
    .moveDown {
        display: flex;
        align-items: center;
    }

    .moveUp {
        color: var(--text-success);
    }

    .moveDown {
        color: var(--text-error);
    }

    .Glyph {
        width: 1.4rem;
        height: auto;

        &.ArrowUpGlyph,
        &.ArrowUp {
            fill: var(--green);
        }

        &.ArrowDownGlyph,
        &.ArrowDown {
            fill: var(--red);
        }
    }

    .GlyphIcon {
        &.ArrowUpGlyphIcon {
            color: var(--text-success);
        }

        &.ArrowDownGlyphIcon {
            color: var(--text-error);
        }
    }

    .newEntry {
        .DotGlyphIcon {
            color: var(--info);
        }
    }
}
