.ThumbnailPlaylistLinkCell {
    display: flex;
    width: 100%;

    .PlaylistLinkCell {
        flex: 1;
    }

    &-details {
        display: none;
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        margin-left: 10px;
        text-transform: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .AlgorithmicGlyph,
    .PersonalizedGlyph {
        fill: var(--gray-400);
    }
}

.GridTable-row {
    &:hover {
        .ThumbnailPlaylistLinkCell {
            &-details {
                display: block;
            }
        }
    }
}
