.TiktokTrendScoreValue {
    align-items: center;
    display: flex;
    column-gap: 10px;

    &-link {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &:visited {
            color: inherit;
        }
    }
}
