.UniversalLink {
    &-text {
        color: var(--text-secondary);
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    &-field {
        height: 40px;
        display: flex;
        padding: 1rem;
        border: 1px solid var(--gray-300);
        border-radius: 3px;
        justify-content: space-between;
        align-items: center;

        button {
            margin-left: 1rem;
            border: none;
        }

        .SkeletonLoader {
            display: flex;
            flex: 1;
        }
    }

    &-copied-button {
        color: var(--green);
        font-size: 0.8rem;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: none;
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
    }
}
