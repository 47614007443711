.PlaylistBreakdownTable {
    margin-left: $grid-gap;
    text-align: right;

    &-header {
        color: var(--text-secondary);
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 0 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-row {
        line-height: $breakdown-line-height;
    }

    &-streams {
        padding-left: 4px;
        padding-right: 4px;
    }

    &-count {
        padding-left: 15px;
        padding-right: 4px;
    }

    &-streams,
    &-count {
        cursor: pointer;
    }

    &-selected {
        background-color: var(--tables-hover);
    }

    th.PlaylistBreakdownTable-selected {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    tr:last-child {
        .PlaylistBreakdownTable-selected {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }
}
