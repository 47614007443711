$component-class: 'StickyHeader';
$breakpoint: 1759px;

.StickyHeader {
    background-color: var(--white);
    box-shadow: 4px 4px 12px 4px rgba(10 12 13 / 4%);
    left: $main-nav-width;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(-100px);
    transition: all ease-in-out 0.3s;
    z-index: $z-index-sticky;
    padding: 10px 20px;
    height: 58px;
    align-items: center;
    display: flex;

    &-shifted {
        left: $main-nav-collapsed-width;
    }

    .CoverArt {
        margin-right: 10px;
    }

    // Hide metadata when there are 3 or 4 comparison items and 2 or 3 filters
    [data-comparisson-items-count='3'][data-filters-count='2'],
    [data-comparisson-items-count='3'][data-filters-count='3'],
    [data-comparisson-items-count='4'][data-filters-count='2'],
    [data-comparisson-items-count='4'][data-filters-count='3'] {
        @media screen and (max-width: $breakpoint) {
            flex-grow: initial;

            .#{$component-class}-metadata {
                display: none;
            }

            .CoverArt {
                margin-right: 0;
            }
        }
    }

    &-with-scroll-to-top {
        padding-right: 63px;
    }

    .DotGlyphIcon {
        margin-right: 8px;
    }

    &-container {
        display: flex;
        align-items: center;
        width: 100%;

        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &-comparison-items {
        display: flex;
        gap: 20px;
    }

    &-active {
        opacity: 1;
        transform: translateY(0);
    }

    &-comparison-item,
    &-comparison-item-content {
        align-items: center;
        display: flex;
        flex-grow: 1;
        max-width: 100%;
    }

    &-scroll-to-top {
        position: absolute !important;
        right: 20px;
    }

    &-filters {
        @include vertical-align;

        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        margin-left: 20px;
    }

    &-filter {
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 10px;
        display: flex;
        align-items: center;

        .CountryFlag,
        .StoreIcon {
            margin-right: 6px;
        }

        .PlatformIcon {
            margin-right: 0;

            & > svg {
                width: 20px;
                height: 20px;
            }
        }

        .StickyHeader-v2-StoreDropdown {
            &-icon {
                margin-right: 4px;
            }
        }
    }

    .filter-value + .filter-value {
        @include divider-v2;

        padding-left: 10px;
    }

    &-title {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
        margin-bottom: 0;
        color: var(--text-primary);

        .TruncatedText {
            width: 0;
            flex-grow: 1;
        }
    }

    &-content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
    }

    &-single-item-info {
        display: flex;
        align-items: center;
        width: 100%;

        .#{$component-class}-title {
            width: 100%;
        }
    }

    &-metadata {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
    }

    &-releasedate,
    &-account {
        color: var(--gray-davy);
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0;
        display: flex;
        flex-wrap: nowrap;

        &-value {
            font-weight: 400;
            letter-spacing: normal;
            margin-left: 4px;
        }
    }

    &-tooltip {
        &-overlay {
            .tooltip-inner {
                text-align: left;
            }
        }
    }
}
