.FormattedChange {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    &.empty {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    &.s16 {
        font-size: 16px;
        line-height: 22px;
    }

    &-position {
        font-size: 14px;
        line-height: 20px;
    }

    .positive {
        color: var(--text-success);

        .GlyphIcon {
            color: var(--text-success);
        }
    }

    .negative {
        color: var(--text-error);

        .GlyphIcon {
            color: var(--text-error);
        }
    }

    .zero {
        color: var(--text-secondary);

        .GlyphIcon {
            color: var(--text-secondary);
        }
    }

    .new {
        color: var(--sky-blue);

        .GlyphIcon {
            color: var(--sky-blue);
        }
    }

    &-trend {
        &Change {
            margin-left: 5px;

            &Icon {
                margin-bottom: 3px;
                margin-left: 9px;
            }
        }
    }
}
