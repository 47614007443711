.ContentPanel {
    &-empty {
        align-items: center;
        background-color: var(--gray-100);
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 0.75rem;

        &-content {
            color: var(--text-secondary);
            text-align: center;
        }
    }
}

@media print {
    .ContentPanel-empty {
        background: none;

        &-content {
            color: var(--black);
        }
    }
}
