.DataValueField {
    align-items: center;
    display: flex;

    &-vertical {
        flex-direction: column;
        align-items: stretch;
    }

    &-title {
        flex: 1;
        margin-right: 10px;
        min-width: 0;
        white-space: nowrap;
    }

    &-value {
        flex: 1;
        min-width: 0;
    }
}
