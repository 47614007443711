.ProfileSettings {
    &-spotify {
        display: flex;
        align-items: center;

        &-value {
            margin-left: auto;
        }

        &-switch > .custom-control-label {
            cursor: pointer;
        }
    }
}
