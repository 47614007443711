.TrendingSongsGridTable {
    &-store-header {
        display: flex;
        align-items: center;
        gap: var(--padding-medium-small, 8px);
    }

    &-header-tooltip {
        &-overlay {
            .tooltip-inner {
                text-align: left;
                min-width: 450px;
            }
        }
    }
}
