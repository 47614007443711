.PageToolbar {
    @include controls-z-index('page-toolbar');

    gap: 1.5rem;

    > *,
    > .form-control,
    .MarketsSelector {
        margin: 0;
    }

    .MarketsSelector-right {
        margin-left: auto;
    }

    > .SuiteSelect:not(.MarketsSelector) {
        max-width: 300px;
    }

    .blockLeft {
        display: flex;
        margin-left: auto;
    }
}

.SuiteColorSchemeV2 {
    .PageToolbar {
        gap: 10px;
        margin-bottom: 20px;

        & + .PageToolbar {
            margin-top: -10px;
        }
    }
}

.PageCol {
    @include controls-z-index('page-col');
}
