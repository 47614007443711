.OrchardGoBanner {
    @media screen and (max-width: 450px) {
        width: 100vw;
        height: 100dvh;
        display: flex;
        align-items: end;
        bottom: initial;
        left: initial;
        right: initial;
        top: initial;
    }

    .ReactModal-content {
        border-radius: 20px;
        height: 509px;
        min-height: 509px;
        max-width: 360px;
        border: none;
        padding-top: 20px;

        @media screen and (max-width: 450px) {
            max-width: 100%;
            border-radius: 20px 20px 0 0;
        }
    }

    .ReactModal-body {
        padding-right: 0;
        padding-bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    &-logo {
        color: var(--blue-med);
        font-size: 18px;
        font-weight: 500;
        word-wrap: break-word;

        img {
            margin-right: 0.5rem;
        }
    }

    &-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        word-wrap: break-word;
        margin-top: 20px;

        p:last-child {
            color: var(--dataviz-1);
        }
    }

    &-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        &-phone {
            width: 170px;
        }
    }

    &-footer {
        margin-top: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            width: fit-content;
            font-size: 12px;
        }

        .btn-link {
            margin-top: 12px;
            margin-bottom: 16px;
        }
    }

    &-tooltip {
        position: absolute;
        width: 220px;
        top: 55%;
        left: 50%;
        transform: translateX(calc(-50% - 20px));
        display: flex;
        flex-direction: column;
        align-items: center;

        &-text {
            padding: 8px;
            background: linear-gradient(
                    0deg,
                    rgb(255 255 255 / 80%) 0%,
                    rgb(255 255 255 / 80%) 100%
                ),
                linear-gradient(0deg, rgb(0 0 0 / 5%) 0%, rgb(0 0 0 / 5%) 100%);
            border-radius: 12px;
            backdrop-filter: blur(4px);
            display: flex;
            color: black;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            word-wrap: break-word;

            img {
                margin-right: 8px;
            }

            p:last-child {
                font-weight: 400;
            }
        }

        &-bottom1 {
            height: 8px;
            background: linear-gradient(
                    0deg,
                    rgb(255 255 255 / 60%) 0%,
                    rgb(255 255 255 / 60%) 100%
                ),
                linear-gradient(0deg, rgb(0 0 0 / 5%) 0%, rgb(0 0 0 / 5%) 100%);
            backdrop-filter: blur(4px);
            border-radius: 0 0 12px 12px;
            width: 85%;
        }

        &-bottom2 {
            height: 8px;
            background: rgb(0 0 0 / 30%);
            background: linear-gradient(
                    0deg,
                    rgb(255 255 255 / 40%) 0%,
                    rgb(255 255 255 / 40%) 100%
                ),
                linear-gradient(0deg, rgb(0 0 0 / 5%) 0%, rgb(0 0 0 / 5%) 100%);
            backdrop-filter: blur(4px);
            border-radius: 0 0 12px 12px;
            width: 70%;
        }
    }

    &-star1 {
        position: absolute;
        top: 0;
        left: 135px;
        width: 30px;
    }

    &-star2 {
        position: absolute;
        left: 9px;
        bottom: 28px;
        width: 33px;
    }

    &-star3 {
        position: absolute;
        left: 20px;
        bottom: 0;
    }
}
