.ParticipantChartHighlightsList {
    display: flex;
    flex: 1;

    &-spaceBetween {
        justify-content: space-between;
    }

    &-empty {
        margin: 4rem auto;
    }

    .ParticipantChartHighlightsItem {
        flex: 1;
        min-width: 0;

        & + .ParticipantChartHighlightsItem {
            margin-left: 1rem;
        }
    }

    .Metric-container {
        margin-top: 8px;

        .Metric {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}
