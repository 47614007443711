.RecentSearchedItem {
    &-container {
        display: flex;
        align-items: center;
        padding: 2px 0 1px;
    }

    &-image {
        padding-left: 13px;
        padding-right: 22px;
        max-width: 65px;

        &--letterbox {
            padding-left: 2px;
            padding-right: 10px;

            .CoverArt.letterbox {
                height: 30px;
            }
        }
    }

    &-link {
        line-height: 20px;
        align-self: flex-start;
    }

    &-metadata {
        display: flex;
        flex-direction: column;
        color: var(--text-secondary);
    }

    &-entityData {
        display: flex;
        align-items: center;
        margin-top: 2px;

        .CountryFlag {
            width: 20px;
            height: 14px;
            margin-left: 2px;
        }
    }

    &-tag {
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 1px 8px;

        &__bolder {
            div {
                font-weight: 500;
                line-height: 18px;
            }
        }
    }
}
