.YoutubeRevenue {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 0 20px 20px;

    .GridTable-body {
        .GridTable-cell {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &-revenue-table {
        .GridTable-row:first-child {
            .GridTable-cell.cell-metric,
            .GridTable-cell.cell-value {
                font-weight: 500;
            }
        }
    }
}
