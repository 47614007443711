.SearchAllResultsPageArtists {
    &-list,
    &-loading-section {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: $grid-gap;

        @media screen and (max-width: 1727px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .SearchAllResultsPageArtistsItem.hidde-on-small-screen,
            .SearchAllResultsPageArtists-loadingIndicator.hidde-on-small-screen {
                display: none;
            }
        }
    }

    &-loadingIndicator.SkeletonLoader-horizontal {
        & + & {
            margin-top: 0;
        }

        .SkeletonLoader-line {
            width: 100%;
            height: 201px; // actual height in design is 200px, 201px because of the border
            border-radius: 3px;
        }
    }
}
.SearchAllResultsPageArtistsItem {
    &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 201px; // actual height in design is 200px, 201px because of the border
        padding: 10px;
    }

    &:hover {
        text-decoration: none;

        .SearchAllResultsPageArtistsItem-inner {
            box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
        }
    }

    &-inCatalog {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &-cover {
        position: relative;

        .CoverArt {
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 30px;
                background: linear-gradient(
                    0deg,
                    var(--black) 1.82%,
                    rgb(0 0 0 / 0%) 100%
                );
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    &-flag {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        img.CountryFlag {
            width: 20px;
            height: 14px;
        }
    }

    &-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: var(--text-primary);
        text-align: center;
        margin-bottom: 0;

        &-container {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            justify-content: center;
            padding-top: 3px;
            max-width: 100%;
            min-width: 0;
        }
    }
}
