.ProductMoreInfoModal {
    .ReactModal-body {
        display: flex;

        .ProductMoreInfoModal-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .tab-content {
                overflow-y: auto;
            }
        }
    }

    .ReactModal-content {
        padding: 10px;
        max-height: calc(100vh - 50px);
        width: 1020px;
    }

    .ReactModal-close {
        top: 20px;
        position: relative;
    }

    .ReactModal-header {
        border-bottom: none;
        height: 0;
        padding: 0 10px;
    }

    .nav-tabs {
        box-shadow: 0 3px 3px -3px rgb(0 0 0 / 17.5%);
    }

    .ProductMoreInfoModalInfoTab {
        display: flex;

        &-title-deleted {
            text-decoration: line-through;
        }

        &-table {
            margin-top: 10px;
            width: 50%;

            tr:last-child td {
                border-bottom: none !important;
            }
        }

        &-label {
            color: var(--text-secondary);
        }

        &-products {
            margin-top: 50px;

            &-title {
                color: var(--text-primary);
                font-size: 1.15rem;
                margin-bottom: 20px;
            }

            &-table-header {
                color: var(--text-secondary);
                padding: 7px 10px;
            }
        }

        &-title {
            display: flex;
            align-items: center;

            &-status-deleted {
                display: block;
                margin-left: 10px;
            }
        }

        .AccountsDetailsMenu {
            height: fit-content;
            margin-top: 25px;
            cursor: default;
        }
    }
}
