.countryLoadingIndicator {
    display: grid;
    grid-template-rows: 13px 24px 13px;
    grid-template-columns: 0 24px 10px;

    .country {
        min-width: 24px;
        width: 24px;
        height: 24px;
        grid-row-start: 2;
        grid-column-start: 2;

        .SkeletonLoader-line {
            width: 24px;
            height: 24px;
            border-radius: 3px;
        }
    }
}
