.TimeseriesCardParticipant {
    padding: 0;

    @media print {
        &-header {
            font-size: 0.95rem;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
    }
}
