.SubaccountsToggle {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    padding: 0 10px;

    &-title {
        color: var(--gray-800);
        white-space: nowrap;
    }

    &-switch {
        .custom-control-label {
            cursor: pointer;
        }
    }
}
