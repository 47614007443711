.ParticipantPlaylistDigest {
    padding: $grid-gap;

    .DataSection-header,
    .DataSection-subheader {
        padding: 0 !important;
    }

    &-header {
        color: var(--text-secondary);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding: 20px 0 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .PerformanceChart-error {
        top: -4rem;
    }

    &-metrics {
        display: flex;
        flex-direction: row;
        margin-top: $grid-gap;

        .Metric {
            border-top: 2px solid var(--gray-300);
            flex-grow: 1;
            font-size: 18px;
            padding: 10px 0 0;
            text-align: left;

            &:first-child {
                margin-right: 40px;
            }
        }
    }

    .LoadingIndicator {
        /* Avoid layout shift */
        height: 480px;
    }

    .PlaylistDigestCatalogContentError {
        align-items: center;
        background-color: var(--gray-200);
        color: var(--gray-500);
        display: flex;
        height: 160px;
        justify-content: center;
        padding: 20px;
        text-align: center;
    }

    .highcharts-markers {
        stroke: white !important;
    }

    .highcharts-area {
        fill-opacity: 100%;
    }

    &-compare {
        .PlaylistDigestCatalogContentError {
            height: 200px;
        }

        .PerformanceChart-error {
            top: 0;
        }

        .ParticipantPlaylistDigest-store-streams-header {
            padding-top: 10px;
            border-top: 2px solid var(--gray-300);
        }

        .PlaylistTypeBreakdown,
        .SongPlaylistHighlights {
            margin: 0;
        }

        .SongPlaylistHighlights {
            &-items {
                column-gap: 20px;
                display: grid;
                grid-auto-flow: column;
                grid-auto-columns: 1fr;
            }

            .CoverArt,
            .CoverArt-image {
                width: 80px;
                height: 80px;
            }
        }

        .PlaylistStoreBreakdown {
            display: flex;

            &-chart {
                flex: 1;
                min-width: 0;
            }

            .PieChart-chart {
                height: auto;
                width: 100%;

                .highcharts-root {
                    width: 100%;
                }
            }

            .PieChart-row {
                &-second {
                    min-width: 0;

                    .TruncatedText {
                        display: block;
                    }
                }
            }
        }
    }

    &-compact {
        .PlaylistStoreBreakdown {
            .PieChart {
                &-table {
                    max-width: 100%;
                }
                &-chart {
                    display: none;
                }
                &-row-first {
                    display: none;
                }
                &-row-second {
                    margin: 0;
                }
            }
        }
        .PlaylistTypeBreakdown {
            .ProgressBar {
                display: none;
            }
        }
        .PlaylistBreakdownTable {
            margin-left: 10px;
        }
    }
}
