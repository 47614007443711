.AccountYoutubeViews {
    &-link {
        @include typography-label;

        text-align: right;
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-graph {
        height: 185px;
        display: flex;
        justify-content: center;
        align-items: center;
        .SkeletonLoader-item {
            display: flex;
            justify-content: center;
        }
    }

    &-legend {
        height: 55px;
        display: flex;
        gap: 20px;
        &-title {
            @include typography-label;

            color: var(--text-secondary);
            text-transform: uppercase;
        }
        &-value {
            @include typography-h2;
        }
        .SkeletonLoader-line {
            height: 55px;
        }
    }
}
