.YoutubeAnnotationMarker {
    position: relative;

    &-count {
        display: none;
    }
}

.YoutubeAnnotationMarker-multi {
    .play-icon {
        display: none;
    }

    .YoutubeAnnotationMarker-count {
        color: white;
        display: block;
        font-size: 10px;
        position: absolute;
        text-align: center;
        width: 16px;
        top: 5px;

        &:hover {
            cursor: default;
        }
    }
}
