.ThumbnailPlaylistPlacementLinkCell {
    display: flex;
    width: 100%;

    button {
        height: 40px;
        min-width: 0;
        padding: 0;
        text-transform: initial;
        letter-spacing: initial;
    }

    .PlaylistLinkCell-description-text {
        @include typography-small;
    }
}
