.SocialLinkEditable {
    display: flex;
    height: 42px;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    .PlatformIcon {
        margin-right: 10px;
    }

    .WarningGlyphIcon {
        color: var(--glyphs-error);
        margin-right: 20px;
    }

    &-tooltip-warning {
        align-self: flex-end;
    }

    &-tooltip-pending {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .InProgressGlyphIcon {
            color: var(--glyphs-warning);
        }
    }

    &-external-link {
        display: flex;
        align-self: center;
        padding: 8px;
        background: var(--midnight-150, #cee3f3);
        border-radius: 50%;

        .ExternalLinkGlyphIcon {
            color: var(--midnight-750, #1d4f72);
        }

        &:hover {
            background: var(--midnight-200, #bad8ee);
        }
    }

    &-editable-control {
        position: relative;
    }

    &-editable-field {
        position: absolute;
        top: 18px;
        left: 0;
    }
}
