.ArtistFilter {
    .SuiteSelect-input {
        .ArtistFilter-img.CoverArt {
            height: 20px;
            width: 20px;

            .CoverArt-image {
                height: 20px;
                width: 20px;
            }
        }
    }
}
