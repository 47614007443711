.ParticipantChartHighlights {
    &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 1.5rem 1.5rem;
    }

    &-details {
        display: inline-block;
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: none;
    }

    &-footer {
        padding-top: 1rem;

        .SeeAllLink {
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.ParticipantChartHighlightsSection {
    .Metric {
        text-align: center;

        &-container {
            .Metric + .Metric {
                @include divider-v2;

                &::after {
                    height: 28px;
                    top: 6px;
                }
            }

            &.MetricsHeader {
                .Metric {
                    text-align: right;
                }
            }
        }
    }
}
