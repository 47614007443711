.SongRelatedContent {
    display: flex;
    padding: 12px 20px;

    .ContentPanel-empty {
        padding: 2rem 0;
        height: 100%;
    }

    &-header {
        padding: 20px 20px 0;
    }

    &-section {
        height: auto !important;

        .ErrorV2.section {
            height: 399px;
        }
    }

    &-comparisonHeader {
        margin-bottom: 15px;
    }

    &-container {
        flex: 1;
        width: 0;

        & + & {
            margin-left: 10px;
        }
    }

    &-footer {
        padding-left: 20px;
        padding-bottom: 20px;
    }
}
