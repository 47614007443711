.CatalogSongsPage {
    &-table-info {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &-filter {
        width: $filter-width;
        font-size: 1rem;
    }
    &-rhs {
        position: absolute;
        right: 0;
    }
}
