.ChartGridView {
    &-section {
        margin-bottom: 1.5rem;

        &:not(:first-child) {
            margin-top: 1.5rem;
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            &-columns-2 {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
