.NmfModalHeader {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    padding-bottom: 20px;
    padding-right: 20px;

    &-metadata {
        display: flex;
        flex-grow: 1;
        align-items: center;
        gap: 10px;

        &-title {
            margin-bottom: 0;
        }
    }

    &-parameters {
        display: flex;
        gap: 12px;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 2px;

            &-label {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--text-secondary);
            }

            &-value {
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                color: var(--text-primary);
            }
        }

        &-loader {
            width: 50px;
        }
    }

    &-country-loader {
        width: 72px;

        .SkeletonLoader-square {
            border-radius: var(--radius-medium);
        }
    }
}
