.GainersPage {
    .InfoMessageV2 {
        background-color: var(--white);

        &-title {
            @include typography-h2;

            margin: 0;
        }

        .Error__button {
            margin-top: 20px;
        }
    }
}
