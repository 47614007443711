.TermFilter {
    width: $filter-width;
    min-width: $filter-width;
    font-size: 1rem;
}
.TermFilterV2 {
    margin-bottom: 0;

    &_disabled {
        pointer-events: none;
    }
}
