.SongPage {
    .InfoMessage {
        margin-top: 5rem;
    }

    .ErrorV2,
    .SongPerformance-section {
        .InfoMessage {
            margin-top: 0;
        }
    }

    .PageHeader {
        .SpotifyPlayButton {
            margin-left: auto;
            border-left: 1px solid var(--gray-300);
            padding-left: 1rem;
        }
    }

    .DataSection-subheader {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
