.VideoRelatedContent {
    padding: 0 $grid-gap $grid-gap $grid-gap;
}

.VideoRelatedContentSection {
    height: auto !important;

    .DataSection {
        &-header {
            &-title {
                margin-right: 20px !important;
            }
        }
    }
}
