.SearchAllResultsPage-EntityCard {
    flex-direction: row;
    align-items: center;

    &:hover {
        box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
    }

    &-Anchor {
        &:hover {
            text-decoration: none;
        }
    }

    &-Catalog {
        align-self: flex-start;
        margin-top: 7px;
        margin-right: 10px;
    }

    &-Metadata {
        margin-left: 10px;
        flex-grow: 1;

        &-Heading {
            color: var(--text-primary);
            font-size: 16px;
            line-height: 22px;
        }

        &-Info {
            display: flex;
            align-items: center;
            color: var(--text-secondary);
            font-size: 12px;
            margin-top: 3px;
        }
    }

    .CoverArt.letterbox {
        background-color: var(--midnight-850);
        margin: 0;
        display: flex;
        align-items: center;
        height: 80px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .CoverArt.round {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        height: 70px;
        border-radius: 0;
        margin: 5px;

        img {
            border-radius: 100%;
        }
    }

    .CoverArt.square {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
