.CheckboxDropdown {
    &-option {
        align-items: center;
        display: flex;

        .form-control {
            height: 1rem;
            width: 1rem;
        }
    }
}
