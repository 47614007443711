.ChartsDropdownFilter {
    &-chart-option {
        display: flex;

        &-label {
            flex: 1;
            min-width: 0;
        }

        &-appearances {
            color: var(--gray-500);
            font-weight: bold;
            padding-left: 1rem;
        }
    }
}
