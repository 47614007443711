.TopChannelCountries {
    display: flex;
    padding: 0 1rem 1.5rem 1rem;

    .CountryLabel-flag {
        line-height: 1rem;

        img {
            border-radius: 3px;
            width: inherit !important;
        }
    }

    .CountryLabel-name {
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    @include permanent-scrollbar;
}
