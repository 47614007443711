.ProgressBar {
    align-items: center;
    display: flex;
    column-gap: 5px;

    &-container {
        background-color: var(--gray-150);
        border-radius: 3px;
        flex: 1;
        height: 10px;
        overflow: hidden;
    }

    &-percentage {
        width: 3rem;

        &-only {
            flex: 1;
        }
    }

    &-bar {
        background-color: var(--other-bar);
        border-radius: 3px;
        height: 100%;
    }
}
