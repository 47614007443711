.PieChartWithGridGridTable {
    width: 100%;

    &-chart {
        margin: auto;
        max-height: 145px;
        max-width: 145px;
        margin-bottom: 39px;
    }

    @media print {
        .PieChart-chart {
            width: 145px;
            height: 145px;
        }
    }

    &-subtitle {
        font-size: 12px;
        text-align: center;
        font-weight: normal;
        margin-bottom: 18px;
    }

    &-table {
        align-self: center;
        flex: 1;
        flex-direction: column;
        flex-shrink: 0;
        margin: auto;

        .GridTable-cell {
            height: 44px;

            &.cell-colorIndex {
                padding: 12px;
                padding-right: 0;
            }
        }
    }

    .no-data .highcharts-point {
        display: none;
    }

    .highcharts-point-hover {
        fill-opacity: 100%;
    }

    .highcharts-tooltip {
        border: none;
        filter: none;
        margin: 0;
        padding: 0;

        &-container {
            background-color: white;
            border: 1px solid var(--gray-400);
            border-radius: 0.25rem;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
            color: black;
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
            /* stylelint-disable declaration-no-important */
            overflow: visible !important;
            /* stylelint-enable */
        }

        &-data {
            display: flex;
        }

        &-value {
            font-weight: 500;
            margin-left: auto;
        }

        &-box {
            fill: none;
            stroke: none;
        }
    }
}
