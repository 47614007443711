@mixin no-appearance {
    appearance: none;
    background: transparent;
    box-shadow: none;
    border: none;
}

@mixin input-thumb {
    -webkit-tap-highlight-color: transparent;
    appearance: none;
    background: transparent;
    box-sizing: border-box;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    position: relative;
    pointer-events: all;
    z-index: 2;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    touch-action: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background: var(--midnight-700);
        box-shadow: 0 0 0 4px var(--range-thumb-hover);
    }
}

@mixin track-tick {
    width: 8px;
    height: 8px;
    background-color: var(--range-track-color);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.RangeSlider {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 32px;

    &-track,
    &-range {
        border-radius: 12px;
        position: absolute;
        height: 4px;
        top: 6px;
    }

    &-range {
        position: absolute;
        background-color: var(--jelly-bean-blue);
        z-index: 1;
    }

    &-track {
        position: absolute;
        background-color: var(--range-track-color);
        z-index: 0;
        left: 0;
        right: 0;

        &-ticks {
            color: var(--text-secondary);

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;

                @include track-tick;

                transform: translateY(-80%);
            }

            &::after {
                right: -4px;
            }

            &::before {
                left: -4px;
            }

            &.first-active {
                &::before {
                    background-color: var(--jelly-bean-blue);
                }
            }

            &.last-active {
                &::after {
                    background-color: var(--jelly-bean-blue);
                }
            }
        }

        &-tick {
            position: absolute;

            @include track-tick;

            transform: translateX(-50%) translateY(-80%);

            &.active {
                background-color: var(--jelly-bean-blue);
            }

            &-label {
                position: absolute;
                font-size: 12px;
                line-height: 18px;
                bottom: -26px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &-thumb {
        @include input-thumb;

        background: var(--jelly-bean-blue);
        position: absolute;
        transform: translateX(-8px);
        z-index: 1;
    }

    &-tooltip {
        position: relative;
        height: 16px;
        display: inline-block;
    }

    &-controls {
        position: relative;
        margin: 0 8px;
    }

    &-inputs {
        height: 16px;
        position: relative;
    }

    &-labels {
        color: var(--text-secondary);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
        padding: 0 8px;
        width: 100%;
    }

    &-input {
        width: 100%;
        height: 16px;
        outline: none;
        position: absolute;
        background: transparent;
        box-sizing: border-box;
        appearance: none;
        pointer-events: none;

        &:active,
        &:focus {
            outline: none;
        }

        &::-moz-focus-outer {
            border: 0;
        }

        &::-ms-track {
            @include no-appearance;
        }

        &::-moz-range-track {
            display: none;
        }

        &::-webkit-slider-runnable-track {
            @include no-appearance;
        }

        &:focus::-webkit-slider-runnable-track {
            @include no-appearance;
        }

        &::-ms-thumb {
            @include input-thumb;
        }

        &::-moz-range-thumb {
            @include input-thumb;
            @include no-appearance;
        }

        &::-webkit-slider-thumb {
            @include input-thumb;
        }
    }

    /* stylelint-disable selector-id-pattern */
    @at-root .TooltipOverlay {
        #minPercentage-TooltipMessage,
        #maxPercentage-TooltipMessage {
            padding: 0 0 6px;

            .arrow::before {
                border-top-color: var(--jelly-bean-blue);
                top: -1px;
                border-width: 6px 6px 0;
            }

            .tooltip-inner {
                background-color: var(--jelly-bean-blue);
                border-radius: 4px;
                font-size: 12px;
                line-height: 24px;
                padding: 0 10px;
            }

            &.align-left {
                .tooltip-inner {
                    transform: translateX(calc(50% - 9px));
                }
            }

            &.align-right {
                .tooltip-inner {
                    transform: translateX(calc(-50% + 9px));
                }
            }
        }

        &:has(
                #minPercentage-TooltipMessage.active,
                #maxPercentage-TooltipMessage.active
            ) {
            z-index: 1100;

            .arrow::before {
                border-top-color: var(--midnight-700);
            }

            .tooltip-inner {
                background-color: var(--midnight-700);
            }
        }
    }
    /* stylelint-enable selector-id-pattern */
}
