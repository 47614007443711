.EntityMetricRow {
    align-items: center;
    display: grid;
    grid-template-columns: 20px 52% 1fr 65px;
    column-gap: 5px;
    min-width: 0;

    &-first-column {
        display: flex;
        align-items: center;
    }
    &-index {
        align-items: center;
        color: var(--text-secondary);
    }

    &-name {
        align-items: center;
        display: flex;
        min-width: 0;
    }

    &-change {
        text-align: right;
    }

    &-value {
        text-align: right;
    }
}

.EntityMetricRow-without-index {
    grid-template-columns: 52% 1fr 65px;

    .EntityMetricRow-first-column {
        display: none;
    }
}
