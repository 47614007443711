.singleLineLoadingIndicator {
    width: 50px;

    &.alignRight {
        margin-left: auto;
    }

    &__skeleton {
        .SkeletonLoader-line {
            border-radius: 3px;
            height: 16px;
        }
    }
}
