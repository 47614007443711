.SpotifyToggleSection {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-switch > .custom-control-label {
        cursor: pointer;
    }
}
