.ParticipantGridTable {
    .GridTable-cell {
        padding: 5px;
    }

    .GridTable-column.separator,
    .GridTable-cell.separator {
        &:nth-of-type(2) {
            border-left: none;
        }
    }
}
