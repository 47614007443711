.LinkWithLoader {
    &-link {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    &-loader {
        width: 100px;
        height: 12px;

        .SkeletonLoader-line {
            height: 12px;
            border-radius: 3px;
        }
    }
}
