.ChannelOverviewPage {
    padding: 0 6px 6px 6px;
    overflow: hidden;

    .card {
        height: 100%;
        margin-bottom: 0;
    }

    .ChannelFilters {
        margin-bottom: 0;
    }
}
