.SearchableDropdown {
    &-increasedZIndex .Select__menu {
        z-index: 3;
    }

    .Select__value-container {
        cursor: text;
        white-space: nowrap;

        .Select__single-value {
            .CoverArt.w30px {
                height: 20px;
                width: 20px;
                .CoverArt-image {
                    display: block;
                    height: 100%;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .SearchableDropdownOption-recent-icon {
                display: none;
            }
        }
    }

    &-optionWithIcon {
        display: flex;
        align-items: center;
    }

    &-iconContainer {
        margin-right: 15px;
    }

    .Select__menu &-recentsDivider {
        border-bottom: 1px solid var(--gray-400);
        padding-bottom: 10px;
    }

    .SearchableDropdown-recents {
        border-bottom: 1px solid var(--gray-400);
        margin-bottom: 5px;
        padding-bottom: 5px;
    }
}
