.SelectSong {
    border: 1px var(--midnight-650) dashed;
    border-radius: $border-radius;
    display: flex;
    flex: 1;
    font-weight: 500;
    justify-content: center;
    padding: $song-card-padding;
    text-transform: uppercase;

    &-button {
        align-self: center;
        justify-content: center;

        &.btn {
            height: 100%;
        }

        .Add {
            fill: var(--blue);
            margin-right: 0.5rem;
        }

        &:focus {
            box-shadow: none;
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;

            .Add {
                fill: #004a94;
            }
        }
    }

    &:hover {
        border-color: #004a94;
    }
}
