.NewReleasesTable {
    .GridTable-cell {
        padding: 5px;
    }

    .cell-selectedCountry {
        .NmfCountriesCell {
            justify-content: center;
        }
    }

    &.loading {
        .GridTable-cell {
            .SkeletonLoader {
                height: auto;
            }
        }

        .GridTable-header-row {
            .SkeletonLoader-line {
                height: 12px;
            }
        }
    }

    &.loading:not(.table-extension-updateIndicator) {
        .GridTable-cell {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
