.TrendingPageHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-container {
        gap: 28px;
    }

    &-title {
        margin: 0;
    }

    &-subtitle {
        color: var(--text-secondary);
        @include typography-body;

        .InsightsTruncatedText {
            display: block;
        }
    }

    &-trending-sources {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
    }
}
