.GlobalSearchResults-option {
    border-bottom: 1px solid var(--gray-100);
    cursor: pointer;
    display: flex;
    height: auto;
    padding: 5px 10px;

    &:hover {
        background: var(--gray-50);
        border-color: var(--gray-50);
    }

    &.is-focused {
        color: var(--black);
    }

    &-image {
        cursor: pointer;
        float: left;
        height: $image-cover-size;
        margin-right: 10px;
        width: $image-cover-size;

        &-img {
            max-height: $image-cover-size;
            max-width: $image-cover-size;
        }

        &-placeholder {
            background-color: var(--gray-100);
            height: $image-cover-size;
            width: $image-cover-size;
        }
    }

    &-body {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    &-subtitle {
        color: #a1a1a1;
    }

    &-title {
        margin-bottom: 3px;
        overflow: hidden;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &-catalog {
            align-items: center;
            display: flex;

            &-text {
                @include truncated-text;

                white-space: nowrap;
                min-width: 0;
            }
        }
    }

    &-type {
        margin-right: 10px;
    }

    &-highlighted {
        font-weight: bold;
    }

    &-subtitle-component {
        align-items: center;
        color: var(--text-secondary);
        display: flex;
        font-size: 13px;
        height: 1.5rem;
        white-space: nowrap;
    }

    .SpotifyIcon {
        transform: scale(0.75);
        margin-top: -2px;
    }

    .InCatalogGlyphIcon {
        margin: 0 0.5rem;
    }
}
