.AccountTopContent {
    &-header {
        .DataSection-header-title {
            margin-right: 20px !important;
        }
    }

    &-empty-icon {
        text-align: center;
        padding: 2rem;
    }

    .DataSection-content {
        display: grid;
        grid-template: auto / 1fr 1fr;
        grid-template-areas:
            'topArtists topArtists'
            'topProducts topSongs';
        gap: 40px 80px;
        padding: 1rem 2rem;
    }

    &-products {
        grid-area: topProducts;
    }
    &-songs {
        grid-area: topSongs;
    }

    &-artists {
        grid-area: topArtists;

        &-header {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: var(--weight-bold);
            letter-spacing: 1px;
            text-transform: uppercase;
            color: var(--text-primary);
            margin-bottom: 1rem;
        }

        &-content {
            display: flex;
            justify-content: space-between;
        }

        &-card-title {
            margin-top: 1rem;
            text-align: center;
            color: var(--text-secondary);

            .TruncatedText {
                font-size: 16px;
            }
        }
    }
    &-products-content,
    &-songs-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
    }

    &-products-link,
    &-songs-link {
        font-size: 12px;
        font-weight: var(--weight-bold);
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .SeeAllLink {
        justify-content: flex-end;
    }
}
