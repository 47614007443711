/* stylelint-disable declaration-no-important */

.GlobalSearchResults .Select {
    &__loading--zone,
    &__arrow--zone {
        display: none;
    }

    &__menu,
    &__menu--outer {
        max-height: 80vh;
    }

    &__indicator {
        display: none;
    }

    &__noresults {
        padding: 0;

        .error-text,
        .noresults-container {
            padding: 20px;
        }

        .noresults-container {
            text-align: center;
        }

        .noresults-text {
            color: var(--gray-500);
            font-size: 1.2rem;
            font-style: italic;
            padding: 0.5rem 0;
        }

        .error-text {
            color: var(--red);
        }
    }

    &__placeholder {
        height: auto;
    }
}

.GlobalSearchResults {
    position: relative;

    .Dropdown {
        width: 100%;
    }

    &-search {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 11px;
    }
}
