.NumberWithPercentageAndArrowCell {
    .GlyphIcon {
        margin-right: 5px;
        margin-bottom: 2px;
    }

    &.percentageDecreaseWithArrow {
        .GlyphIcon {
            color: var(--icon-arrow-down-color);
        }
    }

    &.percentageIncreaseWithArrow {
        .GlyphIcon {
            color: var(--icon-arrow-up-color);
        }
    }

    .change,
    .percentage {
        vertical-align: middle;
    }

    .change {
        line-height: 20px;
        font-size: 14px;
        margin-right: 5px;
    }

    .percentage {
        line-height: 12px;
        font-size: 12px;
        letter-spacing: 1px;
    }
}
