.Dropdown {
    width: 200px;

    .Select__control {
        min-height: 40px;
        border-color: var(--gray-400);
        border-radius: 0.25rem;
    }
}

.SuiteColorSchemeV1 {
    .dropdown {
        .SuiteSelect-input-compact {
            .SuiteSelect-input-value {
                align-items: center;
            }
        }
    }
}

.dropdown-toggle {
    &:not([disabled]) {
        cursor: pointer;

        .SuiteSelect-input {
            cursor: pointer;
        }
    }
}
