.ParticipantComparePanel {
    &-participant-info {
        flex: 1;
        margin: 0;
        width: 0;
    }

    &-name {
        margin-bottom: 0;
        font-weight: $font-weight-normal;
        font-size: $h3-font-size;
    }
}
