.DataSection {
    height: calc(100% - 2rem);
    margin-bottom: 2rem;

    &-header {
        align-items: center;
        display: flex;
        padding: 2rem 2rem 0;
    }

    &-subheader {
        display: flex;
        padding: 0 0 1rem 2rem;
        color: var(--text-secondary);
        font-weight: 400;
    }

    &-header-title {
        align-items: center;
        display: flex;
        line-height: 2;
        margin: 0;
        min-width: 0;
    }

    &-header-icon {
        display: flex;
        margin-right: 10px;
        height: 24px;
        width: 24px;

        & > * {
            height: 100%;
            width: 100%;
        }
    }

    &-content {
        margin: auto 0;
        padding: 2rem;

        &.DataSection-content-equal-columns {
            display: flex;
            justify-content: space-between;
            gap: var(--sizing-sizing-36);
        }
    }

    &-empty {
        display: flex;
        min-height: 30rem;
    }

    &-footer {
        border-top: 1px solid var(--border);
        padding: 1rem 2rem;
    }
}
