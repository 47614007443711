.TruncatedText.InsightsTruncatedText {
    align-items: center;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    &-disable-pointer {
        pointer-events: none;
    }
}
