.SocialLinksWithSidecar {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 0;

    &-label {
        color: var(--text-label, #505962);
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-link-container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &-link {
        .PlatformIcon {
            margin-right: 0;
        }
    }

    &-btn-edit {
        border-radius: 50%;
        border: 0;
        padding: 6px;

        .GlyphIcon {
            color: var(--midnight-750, #1d4f72);
        }

        &:hover {
            background: var(--midnight-200, #bad8ee);
        }
    }

    &-sidecar-content {
        width: 100%;

        .Alert {
            margin-top: 20px;
        }
    }
}

.ReactModal-header {
    border-bottom: 1px solid var(--dividers-light-stroke, #e3e5e8);
}

.Sidecar .ReactModal-content {
    border-radius: var(--radius-extra-large, 16px) 0 0
        var(--radius-extra-large, 16px);

    .ReactModal-header {
        .CloseGlyphIcon {
            color: var(--text-secondary);
        }
    }
}
