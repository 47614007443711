.PerformanceOverTimeLegendV2 {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    gap: 20px;

    &-content,
    &-header {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: minmax(220px, auto) minmax(460px, auto) minmax(
                auto,
                320px
            );
        align-items: start;
        justify-items: stretch;
        max-width: 1440px;
        gap: 20px;
        min-width: min-content;

        &.compareMode {
            grid-template-columns: initial;
        }

        &.hasLegend3Columns {
            grid-template-columns: minmax(105px, auto) minmax(460px, auto) minmax(
                    auto,
                    320px
                );
        }
    }

    &-container {
        overflow: auto;
    }

    &-details-switch {
        grid-column: 1 / span 3;
    }

    .LegendInfo {
        display: grid;
        grid-template-columns: repeat(2, minmax(100px, max-content));
        gap: 20px;

        &.has1Column {
            grid-template-columns: minmax(100px, max-content);
        }

        .ValuePanel {
            &-value {
                line-height: 32px;
            }
        }
    }

    .LegendData {
        &Section {
            &-title {
                margin-bottom: 12px;
                color: var(--text-secondary);
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include typography-label;
            }
            &-items {
                display: grid;
                gap: 10px 40px;
                grid-auto-flow: column;
                grid-template-rows: repeat(5, auto);
                grid-template-columns: repeat(
                    auto-fit,
                    minmax(min-content, 100px)
                );
            }

            &.country {
                .LegendDataSection-title {
                    width: 710px;
                }
                .LegendDataSection-items {
                    gap: 8px 20px;
                }
            }
        }

        .LegendStoreType,
        .LegendParticipantType,
        .LegendSubaccountType,
        .LegendImprintType,
        .LegendTrackType,
        .LegendVideoType,
        .LegendProjectType,
        .LegendProductType {
            @include typography-small;
            &-row {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            &-percentage {
                color: var(--text-secondary);
            }

            .ProgressBar {
                width: 40px;
                &-container {
                    height: 10px;
                }
            }
        }

        .LegendCountryType {
            &-clearAllButton {
                margin-right: 16px;
            }

            .LegendDataSection-title {
                margin-bottom: 9px;
            }

            &-percentage {
                margin-left: 8px;
                @include typography-small;
            }

            &-item {
                display: flex;
                height: 32px;
                padding: 0 16px;
                cursor: pointer;
                align-items: center;
                border-radius: 16px;
                border: 1px solid var(--midnight-750, #1d4f72);
                &.active {
                    background: var(--midnight-150, #cee3f3);
                }
                @include typography-label;

                color: var(--midnight-750, #1d4f72);

                .GlyphIcon {
                    margin-right: 4px;
                }
                .CountryFlag {
                    margin-right: 2px;
                }
            }
        }

        .LegendStoreType {
            &-name {
                min-width: 100px;
                display: flex;
                gap: 3px;
                align-items: center;

                .PlatformIcon {
                    margin-right: 0;
                    .StoreIcon {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        .LegendProductType {
            &-product {
                display: flex;
                gap: 5px;
                align-items: center;
            }
            &-name {
                width: 150px;
                &-upc {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendImprintType {
            &-name {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .LegendSubaccountType {
            &-name {
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &-id {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendParticipantType {
            &-participant {
                display: flex;
                gap: 5px;
                align-items: center;
            }
            &-name {
                width: 115px;
                &-upc {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendTrackType {
            &-song {
                display: flex;
                gap: 5px;
                align-items: center;
            }
            &-name {
                width: 150px;
                &-isrc {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendVideoType {
            &-video {
                display: flex;
                gap: 5px;
                align-items: center;
            }
            &-name {
                width: 126px;

                &-id {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendProjectType {
            &-name {
                width: 150px;
                &-id {
                    @include typography-small;

                    color: var(--text-secondary);
                }
            }
        }

        .LegendContentType {
            display: grid;
            grid-template-columns: repeat(2, minmax(220px, 1fr));
            gap: 20px;
            .ValuePanel-header {
                margin-bottom: 9px;
            }

            &-item {
                margin-bottom: 4px;
            }

            &-header {
                align-items: center;
                display: flex;
                gap: 3px;

                .InfoGlyphIcon {
                    display: block;
                }
            }
        }

        .LegendSourceOfStreamsType,
        .LegendSourceOfStreamsDetailsType {
            display: grid;
            grid-template-columns: repeat(2, minmax(220px, 1fr));
            gap: 20px;
            .ValuePanel-header {
                margin-bottom: 9px;
            }

            &-item {
                margin-bottom: 4px;
            }

            &-header {
                align-items: center;
                display: flex;
                gap: 3px;

                .InfoGlyphIcon {
                    display: block;
                }
            }
        }

        .LegendSourceOfStreamsType {
            .ValuePanelPercentage {
                line-height: 24px;
            }
        }

        .LegendSourceOfStreamsDetailsType {
            &-header {
                margin-bottom: 12px;
            }

            &-content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .ValuePanelPercentage {
                    grid-auto-columns: 125px auto;

                    &.disabled {
                        opacity: 0.3;
                    }

                    &-progress {
                        grid-template-columns: 40px 25px;
                    }
                }
            }
        }

        .LegendTotalType {
            display: flex;
            gap: 40px;
            &-name {
                @include text-small;

                display: flex;
                gap: 4px;
            }

            &-item {
                display: flex;
                flex-direction: column;
                gap: 12px;
                max-width: 150px;

                svg {
                    min-width: 16px;
                }
            }
            &-streams {
                div {
                    @include text-label;

                    color: var(--text-secondary);
                    margin-bottom: 5px;
                }
                span {
                    @include text-heading(2);
                }
            }
        }
    }
}
