.MarketsSelector {
    // TODO: remove this margin, this component shouldn't care about position
    margin-right: 1.5rem;

    &-fullWidth {
        width: 100%;
    }

    &-placementRight {
        margin-right: 0;
        margin-left: 1.5rem;
    }

    &-right {
        margin-left: auto;
        margin-right: 0;
    }
}
