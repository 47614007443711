.ParticipantPageHeader {
    &-metrics-name {
        display: flex;
        align-items: stretch;

        .PlatformIcon {
            svg {
                height: 1rem;
                width: 1rem;
            }
        }
    }

    .PageNav {
        margin-top: 10px;
    }

    @media print {
        .SocialLinks-label {
            display: none;
        }
    }

    .ShareButton {
        padding: 5px 0;
        margin-left: auto;
    }
}
