.AccountOverviewPage {
    padding: 0 6px 6px 6px;

    .DataSection,
    .DataSectionV2,
    .PageToolbar {
        height: 100%;
        margin-bottom: 0;
    }

    .ProfitCenterFilter {
        margin-left: 1.5rem;
    }

    .PerformanceOverTime-table {
        max-height: 534px;
    }

    .InsightsGridTable {
        overflow-x: auto;
    }
}
