.SongNameLoadingIndicator {
    display: grid;
    grid-template-areas:
        'image name'
        'image artist';
    grid-template-columns: 40px 150px;
    column-gap: 8px;
    padding-top: 19px;
    padding-bottom: 19px;

    .artwork {
        grid-area: image;
    }

    .songName {
        grid-area: name;
    }

    .artist {
        grid-area: artist;
    }
}
