.SourceOfStreamsAudiencePanel {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) 150px;
    gap: 20px;

    &-item {
        grid-auto-columns: 55px auto;

        &-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .SkeletonLoader {
                gap: 10px;

                &-item {
                    margin: 0;
                }

                &-line {
                    height: 18px;
                }
            }
        }

        .ValuePanelPercentage-progress-bar {
            background-color: var(--text-primary);
            border-color: var(--text-primary);
        }
    }

    .ValuePanel-header {
        margin-bottom: 12px;
    }

    .ValuePanelPercentage-progress {
        grid-template-columns: 40px 33px;
    }
}
