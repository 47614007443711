.CountryLoadingIndicator {
    .countryCard {
        gap: 4px;

        .SkeletonLoader-square {
            width: 50px;
            height: 58px;
            border-radius: 8px;
        }
    }

    &.tableView {
        padding-top: var(--padding-medium-large);
        padding-bottom: var(--padding-medium-large);
    }

    &.modalView {
        .countryCard {
            gap: 8px;
        }

        .SkeletonLoader-square {
            width: 72px;
        }
    }
}
