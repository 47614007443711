.CompareButton {
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;

    svg {
        margin-bottom: 1px;
    }

    .CloseGlyphIcon {
        margin-bottom: 0;
    }
}
