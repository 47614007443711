.SongOverviewPage {
    padding: 0 6px 6px 6px;

    &-content {
        &-digest-column {
            display: grid;
            grid-auto-flow: column;
            column-gap: $grid-gap;
            grid-auto-columns: 1fr;
        }
    }

    .ComparisonSelection {
        .ComparisonFilters {
            flex: 1;

            .Select-menu {
                max-height: 75vh;
            }

            &-period {
                .Select__menu-list {
                    max-height: 500px;
                }
            }
        }

        .CompareButton,
        .ExportButton {
            margin: 0 1rem;
            margin-left: auto;
            padding: 0;
        }
    }

    .DataSection,
    .PageToolbar {
        height: 100%;
        margin-bottom: 0;
    }

    .DataSection.SongPerformance-section {
        height: 310px;
    }
}

@media print {
    .SongOverviewPage-content-not-in-catalog {
        .ComparisonSelection,
        .SongPerformanceOverTime-section {
            display: none;
        }
    }

    .DataSection-footer {
        display: none;
    }
}
