$graph-height: 292px;

.SongsCompareAudience {
    display: flex;
    padding: 20px;
    gap: 20px;

    &-title {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include text-heading(3);
    }

    .SuiteSelect {
        width: fit-content;
    }

    &-subtitle {
        color: var(--text-secondary);

        @include text-small;
    }

    &-graph {
        height: $graph-height;
        .TopAudienceByAge-title,
        .TopAudienceByGender-title {
            color: var(--text-secondary);
            margin-bottom: 20px;

            @include text-heading(4);
        }

        .SkeletonLoader {
            width: 80px;
        }
    }

    &-legend {
        height: 18px;
        display: flex;
        gap: 40px;
        margin-left: 35px;

        &-item {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            min-width: 0;

            @include text-small;
        }
    }

    &-states {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: $graph-height;

        &-title {
            color: var(--text-secondary);

            @include text-heading(4);
        }
    }

    &-state {
        flex: 1;
        justify-content: center;
        display: flex;
    }

    &-tooltip {
        &-container {
            font-family: var(--typeface, Rubik);
            display: flex;
            padding: 8px;
            flex-direction: column;
            align-items: center;
            gap: var(--padding-small, 4px);
            border-radius: 3px;
            background: var(--white);
            box-shadow: var(--shadows-xy-axis-small, 4px)
                var(--shadows-xy-axis-small, 4px) 20px 4px
                rgba(157 166 175 / 10%);
        }

        &-percentage {
            @include typography-small;
            @include typography-primary;

            font-weight: var(--weight-bold);
        }

        &-value {
            @include typography-small;
            @include typography-secondary;
        }
    }
}
