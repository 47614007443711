.TiktokTopList {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-menu {
        display: flex;
        gap: 20px;
        align-items: center;

        span {
            font-size: var(--font-size-h2, 20px);
            font-weight: 500;
            line-height: var(--line-height-h2, 28px);
        }
    }
}
