.GridPerformanceOverTime {
    .SummaryRowCheckbox {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        outline: none;

        &:hover {
            opacity: 0.6;
        }

        &-label {
            margin-left: 0.5rem;
        }

        &-icon {
            align-items: center;
            background-color: var(--white);
            border-radius: 2px;
            border: 1px solid var(--gray-300);
            display: flex;
            padding: 0 2px;

            .Glyph {
                fill: var(--white);
            }

            .GlyphIcon {
                color: var(--white);
            }

            &.checked {
                .Glyph {
                    fill: var(--white);
                }

                .GlyphIcon {
                    color: var(--white);
                }
            }
        }
    }

    .GridTable-header-row {
        .SummaryRowCheckbox-icon {
            &.checked {
                .Glyph {
                    fill: var(--gray-800);
                }

                .GlyphIcon {
                    color: var(--gray-800);
                }
            }
        }
    }
}
