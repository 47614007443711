.CatalogAccountsPage {
    .Dropdown {
        max-width: 250px;
    }

    &-toolbar {
        &-right {
            margin-left: auto;
            display: flex;
            column-gap: 1.5rem;
        }
    }
}
