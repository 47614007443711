.TopListTable {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    overflow: auto;
    max-height: 14rem;

    & + .TopCountriesTable {
        margin-left: 10px;
    }

    &-header {
        margin-bottom: 15px;
    }
}
