.AccountRecentReleasesV2 {
    justify-content: space-between;

    &-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px 10px;
    }

    &-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .DataSectionV2-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 10px;
    }

    .DataSectionV2-footer {
        @include typography-label;
    }
}
