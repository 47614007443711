.TiktokHeaderCellV2 {
    white-space: nowrap;

    .PlatformIcon {
        margin: 3px var(--padding-medium-small, 8px) 3px 0;
        display: inline-block;
        vertical-align: bottom;
    }

    &-title {
        display: inline-block;
    }
}
