.SongPageCatalogSubtitle {
    &-subtitle {
        display: flex;
        align-items: center;
    }

    &-subtitle-separator {
        padding-left: 0.2rem;
        padding-right: 0.7rem;
        color: var(--text-secondary);
    }

    &-subtitle-separator-links {
        > span + span {
            padding-left: 0.5rem;
        }
    }

    &-extra-product-count {
        text-transform: capitalize;
        font-size: 0.95rem !important;
        font-weight: 400;
        letter-spacing: normal;
    }
}
