@use 'sass:map';
@use 'sass:list';

$body-min-width: 1210px;
$main-nav-width: 230px;
$main-content-padding: 1rem;
$image-cover-size: 50px;
$border: 1px solid var(--border);
$box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 17.5%);
$bg-hover: var(--gray-100);
$grid-gap: 20px;
$grid-gap-sm: 15px;
$min-content-width: 980px;
$min-data-section-width: 24rem;
$filter-width: 200px;
$breakdown-line-height: 2;
$color-amazon-music: #f90;
$color-apple-music: #f04861;
$color-beatport: #000;
$color-deezer: #ffed00;
$color-facebook: #4460a0;
$color-google-play: #4285f4;
$color-instagram: #c32aa3;
$color-line-japan: #07b53b;
$color-napster: #393262;
$color-odnoklassniki: #ee8208;
$color-pandora: #00a0ee;
$color-sound-cloud: #fe5000;
$color-spotify: #1ed760;
$color-tik-tok: #000;
$color-tik-tok-views: #a8a0a0;
$color-awa: #000;
$color-twitter: #1da1f2;
$color-v-kontakte: #45668e;
$color-youtube: #f00;
$color-pgc: #fe2c55;
$color-ugc: #25f4ee;
$color-wynk: #d52e20;
$color-qq: #31c27c;
$color-kugou: #00a9ff;
$color-kuwo: #ffe443;
$platform-colors: (
    'amazonMusic': var(--color-amazon-music),
    'appleMusic': var(--color-apple-music),
    'beatport': var(--color-beatport),
    'deezer': var(--color-deezer),
    'facebook': var(--color-facebook),
    'googlePlay': var(--color-google-play),
    'instagram': var(--color-instagram),
    'napster': var(--color-napster),
    'pandora': var(--color-pandora),
    'soundcloud': var(--color-sound-cloud),
    'spotify': var(--color-spotify),
    'tiktok': var(--color-tik-tok),
    'tiktok-views': var(--color-tik-tok-views),
    'twitter': var(--color-twitter),
    'vKontakte': var(--color-v-kontakte),
    'vk': var(--color-v-kontakte),
    'ok': var(--color-odnoklassniki),
    'youtube': var(--color-youtube),
    'line': var(--color-line-japan),
    'awa': var(--color-awa),
    'PGC': var(--color-pgc),
    'UGC': var(--color-ugc),
    'wynk': var(--color-wynk),
    'qq': var(--color-qq),
    'kugou': var(--color-kugou),
    'kuwo': var(--color-kuwo),
);
$source-of-streams-colors: (
    'ACTIVE': var(--dataviz-2),
    'COLLECTION': var(--dataviz-3),
    'PASSIVE': var(--dataviz-1),
    'UNKNOWN': var(--gray-500),
);
$source-of-streams-details-colors: (
    'other': rgba(78 111 146 / 100%),
    'search': rgba(31 122 92 / 100%),
    'spotify_collection': rgba(34 150 243 / 100%),
    'spotify_playqueue': rgba(189 21 10 / 100%),
    'spotify_albumpage': rgba(255 98 0 / 100%),
    'spotify_artistpage': rgba(202 135 2 / 100%),
    'spotify_playlists': rgba(156 40 176 / 100%),
    'spotify_releaseradar': rgba(204 9 246 / 100%),
    'spotify_discoverweekly': rgba(103 58 183 / 100%),
    'spotify_radio': rgba(245 67 54 / 100%),
    'spotify_dailymix': rgba(234 39 99 / 100%),
    'spotify_chart': rgba(67 168 77 / 100%),
    'apple_library': rgba(34 150 243 / 100%),
    'apple_external': rgba(234 39 99 / 100%),
    'apple_voice': rgba(202 135 2 / 100%),
    'apple_musickit': rgba(245 67 54 / 100%),
    'apple_nowplaying': rgba(189 21 10 / 100%),
    'apple_discovery': rgba(255 98 0 / 100%),
    'amazon_userplaylist': rgba(34 150 243 / 100%),
    'amazon_songs': rgba(189 21 10 / 100%),
    'amazon_album': rgba(255 98 0 / 100%),
    'amazon_artist': rgba(202 135 2 / 100%),
    'amazon_playlist': rgba(156 40 176 / 100%),
    'amazon_station': rgba(245 67 54 / 100%),
);
$source-of-streams-details-colors-v2: (
    'other': rgba(78 111 146 / 100%),
    'search': rgba(20 151 158 / 100%),
    'spotify_collection': rgba(34 150 243 / 100%),
    'spotify_playqueue': rgba(189 21 10 / 100%),
    'spotify_albumpage': rgba(255 98 0 / 100%),
    'spotify_artistpage': rgba(202 135 2 / 100%),
    'spotify_playlists': rgba(156 40 176 / 100%),
    'spotify_releaseradar': rgba(204 9 246 / 100%),
    'spotify_discoverweekly': rgba(103 58 183 / 100%),
    'spotify_radio': rgba(245 67 54 / 100%),
    'spotify_dailymix': rgba(234 39 99 / 100%),
    'spotify_chart': rgba(67 168 77 / 100%),
    'apple_library': rgba(34 150 243 / 100%),
    'apple_external': rgba(204 9 246 / 100%),
    'apple_voice': rgba(202 135 2 / 100%),
    'apple_musickit': rgba(245 67 54 / 100%),
    'apple_nowplaying': rgba(189 21 10 / 100%),
    'apple_discovery': rgba(255 98 0 / 100%),
    'amazon_userplaylist': rgba(34 150 243 / 100%),
    'amazon_songs': rgba(189 21 10 / 100%),
    'amazon_album': rgba(255 98 0 / 100%),
    'amazon_artist': rgba(202 135 2 / 100%),
    'amazon_playlist': rgba(156 40 176 / 100%),
    'amazon_station': rgba(245 67 54 / 100%),
);
$dataviz-var-colors: (
    'dataviz-1': $dataviz-1,
    'dataviz-2': $dataviz-2,
    'dataviz-3': $dataviz-3,
    'dataviz-4': $dataviz-4,
    'dataviz-5': $dataviz-5,
    'dataviz-6': $dataviz-6,
    'dataviz-7': $dataviz-7,
    'dataviz-8': $dataviz-8,
    'dataviz-9': $dataviz-9,
    'dataviz-10': $dataviz-10,
    'dataviz-11': $dataviz-11,
);
$dataviz-colors: (
    'bg-dataviz-0': var(--dataviz-1),
    'bg-dataviz-1': var(--dataviz-2),
    'bg-dataviz-2': var(--dataviz-3),
    'bg-dataviz-3': var(--dataviz-4),
    'bg-dataviz-4': var(--dataviz-5),
    'bg-dataviz-5': var(--dataviz-6),
    'bg-dataviz-6': var(--dataviz-7),
    'bg-dataviz-7': var(--dataviz-8),
    'bg-dataviz-8': var(--dataviz-9),
    'bg-dataviz-9': var(--dataviz-10),
    'bg-dataviz-10': var(--dataviz-11),
    'bg-dataviz-11': var(--dataviz-1),
    'bg-dataviz-12': var(--dataviz-2),
    'bg-dataviz-13': var(--dataviz-3),
    'bg-dataviz-14': var(--dataviz-4),
    'bg-dataviz-15': var(--dataviz-5),
    'bg-dataviz-16': var(--dataviz-6),
    'bg-dataviz-17': var(--dataviz-7),
    'bg-dataviz-18': var(--dataviz-8),
    'bg-dataviz-19': var(--dataviz-9),
    'bg-dataviz-20': var(--dataviz-10),
    'bg-dataviz-21': var(--dataviz-11),
    'bg-dataviz-22': var(--dataviz-1),
    'bg-dataviz-23': var(--dataviz-2),
    'bg-dataviz-24': var(--dataviz-3),
    'bg-dataviz-25': var(--dataviz-4),
    'bg-dataviz-26': var(--dataviz-5),
    'bg-dataviz-27': var(--dataviz-6),
    'bg-dataviz-28': var(--dataviz-7),
    'bg-dataviz-29': var(--dataviz-8),
    'bg-dataviz-30': var(--dataviz-9),
    'bg-dataviz-31': var(--dataviz-10),
    'bg-dataviz-32': var(--dataviz-11),
    'bg-dataviz-33': var(--dataviz-1),
    'bg-dataviz-34': var(--dataviz-2),
    'bg-dataviz-35': var(--dataviz-3),
    'bg-dataviz-36': var(--dataviz-4),
    'bg-dataviz-37': var(--dataviz-5),
    'bg-dataviz-38': var(--dataviz-6),
    'bg-dataviz-39': var(--dataviz-7),
    'bg-dataviz-40': var(--dataviz-8),
    'bg-dataviz-41': var(--dataviz-9),
    'bg-dataviz-42': var(--dataviz-10),
    'bg-dataviz-43': var(--dataviz-11),
    'bg-dataviz-44': var(--dataviz-1),
    'bg-dataviz-45': var(--dataviz-2),
    'bg-dataviz-46': var(--dataviz-3),
    'bg-dataviz-47': var(--dataviz-4),
    'bg-dataviz-48': var(--dataviz-5),
    'bg-dataviz-49': var(--dataviz-6),
    'bg-dataviz-50': var(--dataviz-7),
);
$pgs-and-ugc-colors: (
    'UGC': (
        'dark-purple-700',
        'dark-purple-600',
    ),
    'PGC': (
        'teal-800',
        'teal-700',
    ),
);
$extended-stores-chart-colors: map.merge($chart-colors, $pgs-and-ugc-colors);

:root {
    --black: #000;
    --gray-50: #fafafa;
    --gray-chateau: #9da6af;
    --gray-pale-sky: #67737e;
    --gray-davy: #505962;
    --iron-gray: #d5d9dd;
    --bluish-gray: #818c98;
    --midnight-100: #deedf7;
    --midnight-150: #cee3f3;
    --midnight-200: #bad8eeff;
    --midnight-500: #3187c4;
    --midnight-700: #225d87;
    --midnight-800: #17415e;
    --midnight-850: #123249;
    --midnight-900: #0d2435;
    --jelly-bean-blue: #276b9b;
    --link-color: #{$link-color};
    --hover-color: #deebff;
    --color-amazon-music: #{$color-amazon-music};
    --color-apple-music: #{$color-apple-music};
    --color-beatport: #{$color-beatport};
    --color-deezer: #{$color-deezer};
    --color-facebook: #{$color-facebook};
    --color-google-play: #{$color-google-play};
    --color-instagram: #{$color-instagram};
    --color-line-japan: #{$color-line-japan};
    --color-napster: #{$color-napster};
    --color-odnoklassniki: #{$color-odnoklassniki};
    --color-pandora: #{$color-pandora};
    --color-sound-cloud: #{$color-sound-cloud};
    --color-spotify: #{$color-spotify};
    --color-tik-tok: #{$color-tik-tok};
    --color-tik-tok-views: #{$color-tik-tok-views};
    --color-awa: #{$color-awa};
    --color-twitter: #{$color-twitter};
    --color-v-kontakte: #{$color-v-kontakte};
    --color-youtube: #{$color-youtube};
    --color-pgc: #{$color-pgc};
    --color-ugc: #{$color-ugc};
    --color-wynk: #{$color-wynk};
    --color-qq: #{$color-qq};
    --color-kugou: #{$color-kugou};
    --color-kuwo: #{$color-kuwo};
    --warn: #fabb00;
    --icon-arrow-down-color: #ce2617;
    --icon-arrow-up-color: #23870a;
    --range-track-color: #e3e5e8;
    --range-thumb-hover: rgba(34 93 135 / 40%);

    // Extra variables for UGC and PGC
    @each $name, $colours in $pgs-and-ugc-colors {
        $colour-one: list.nth($colours, 1);

        --#{$name}-line: var(--#{$colour-one});
    }
}

$z-index-start: 100;
$z-index-popups: $z-index-start + 2; // popups z-index should be higher than other components
$z-index-sticky: $z-index-start + 1; // sticky elements z-index should be higher than other components but lower than popups
$z-index-main-nav: $z-index-sticky + 1; // main nav z-index should be higher than sticky elements
$z-index-popups-highcharts-tooltip: $z-index-popups + 1; // highcharts popups z-index should be higher than other popups
$z-index-highcharts-tooltip: $z-index-main-nav + 1; // highcharts tooltip z-index should be higher than main nav
$z-index-toolbar-controls: $z-index-start; // PageToolbars controls z-index should be lower than sticky header to avoid overlap with sticky header
$z-index-compare-panel-controls: $z-index-toolbar-controls; // ComparePanel controls z-index should be the same as PageToolbar controls
$z-index-page-col-controls: $z-index-start - 2; // PageCol controls z-index should be lower than sticky header and PageToolbar to avoid overlap with sticky header and toolbar components
