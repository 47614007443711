.PlaylistType {
    display: flex;
    justify-content: space-between;
    line-height: $breakdown-line-height;
    padding: 1px 0;

    &-title {
        overflow: hidden;
        white-space: nowrap;
        display: flex;

        .TruncatedText {
            display: block;
        }
    }

    .ProgressBar {
        flex-basis: 50%;
    }
}
