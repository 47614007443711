.SongStreamsSourceOfStreamsPanel {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 220px 460px minmax(auto, 320px);
    align-items: start;
    justify-items: stretch;
    justify-content: space-around;
    max-width: 1440px;
    gap: 20px;
    padding: 20px;
    min-width: min-content;

    &-container {
        overflow: auto;
    }

    &-details-switch {
        grid-column: 1 / span 3;
    }

    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 130px;
        }
    }
}
