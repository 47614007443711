.WarningMessage {
    display: flex;
    align-items: center;
    background-color: var(--gray-300);
    padding: 10px;
    border-radius: 3px;

    .Glyph.WarningGlyph,
    .GlyphIcon.WarningGlyphIcon {
        margin-right: 10px;
        height: 24px;
        width: 24px;
    }

    .Glyph.WarningGlyph {
        fill: var(--orange);
    }

    .GlyphIcon.WarningGlyphIcon {
        color: var(--orange);
    }
}
