.SearchableDropdownOption {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-recent {
        &-icon {
            margin-right: 1rem;
        }
    }

    &-content {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
