.VideoPerformanceOverTime {
    &-header {
        align-items: center;
        align-self: flex-start;
        display: flex;
    }

    &-chart {
        padding: 0 10px;
    }

    &-table {
        margin: 20px;

        .InsightsGridTable-section-table:has(.InsightsGridTable.zebra-variant) {
            border: 1px solid var(--dividers-light-stroke);
        }

        tbody > tr > td {
            vertical-align: middle;
        }

        tbody > tr > td:first-child {
            padding-left: 12px;
            width: 30%;
        }

        tbody > tr > td:nth-child(2) {
            width: auto;
        }
    }

    &-empty {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        & > .OutlineIcon {
            margin: 0 auto;
        }
        &-text {
            color: var(--gray-500);
            margin: 1rem auto;
        }
    }

    &-public-content-error {
        background-color: var(--gray-200);
        color: var(--gray-500);
        margin: $grid-gap;
        padding: 100px 0;
        text-align: center;
    }

    .DataSection {
        &-content {
            padding: 0;
            margin: 0;
        }
    }
}

.pot-metric-select {
    width: 18rem;
}
