.SelectedSong {
    flex: 1;
    margin-right: 2rem;

    .card-body {
        align-items: center;
        display: flex;
        padding: $song-card-padding;
    }

    &-dot {
        margin-right: 1rem;
    }

    &-artwork {
        display: flex;
        height: $song-card-inner-height;
        margin-right: 1rem;
        width: $song-card-inner-height;

        .LoaderWrapper-square {
            height: $song-card-inner-height;
            width: $song-card-inner-height;
        }

        .img-fluid {
            border-radius: $border-radius;
        }
    }

    &-metadata {
        flex: 1;
        max-height: $song-card-inner-height;
        overflow: hidden;
        margin-bottom: 0;
        font-weight: $font-weight-normal;
        font-size: $h3-font-size;

        .SkeletonLoader {
            width: 15rem;
        }
    }

    &-close {
        align-self: flex-start;
        padding: 0;

        .Close {
            fill: var(--gray-500);
        }
    }

    &-artist,
    &-releaseDate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-releaseDate {
        color: var(--gray-500);
        font-size: $font-size-sm;
    }

    /* stylelint-disable value-no-vendor-prefix */
    &-title {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
