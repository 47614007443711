.PlusMoreValue {
    &.PlusMoreValue-tooltip {
        display: flex;
        white-space: nowrap;
    }

    &.PlusMoreValue-text {
        display: block;
    }

    &-value {
        @include truncated-text;
    }

    &-more {
        font-weight: var(--weight-bold);
        flex-shrink: 0;
        margin-left: 10px;
    }
}
