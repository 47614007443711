.CatalogSongsPage {
    &-table-info {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &-filter {
        width: $filter-width;
        font-size: 1rem;
    }
    &-rhs {
        position: absolute;
        right: 0;
    }

    .Error.Error-noResults,
    .Error.Error-error,
    .Error.Error-noData {
        height: calc(100vh - 263px);
    }

    .CatalogSongsTableV2 {
        .GridTable-table {
            overflow: visible;
            overflow-x: auto;

            .GridTable-cell {
                padding: 10px 10px 9px;
            }
        }

        &.loading {
            @include table-loading-indicators(CatalogSongsTableV2);
        }
    }
}
