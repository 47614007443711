.SettingsPage {
    .card {
        margin-bottom: 15px;
    }
}

.DefaultProfileSettings {
    display: flex;
    align-items: center;
    column-gap: 10px;

    &-header {
        flex: 1;
    }
    &-content {
        max-width: 300px;
    }

    &-tooltip {
        .tooltip-inner {
            max-width: 500px;
            text-align: left;
        }
    }
}
