.SearchableSelect {
    &-view-list {
        min-width: 0;
        overflow: auto;
        position: relative;
    }

    .SuiteListView-body {
        min-height: 150px;
    }
}
