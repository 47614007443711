.StreamsMetrics {
    .positive-percentage {
        .GlyphIcon {
            color: var(--text-success);
        }

        color: var(--text-success);
    }

    .negative-percentage {
        .GlyphIcon {
            color: var(--text-error);
        }

        color: var(--text-error);
    }

    .zero-percentage {
        .GlyphIcon {
            color: var(--black);
        }

        color: var(--black);
    }
}
