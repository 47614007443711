.ChannelMetricsTable {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--dividers-light-stroke);

    th,
    td {
        padding-right: 1rem;
        text-align: right;
        border-right: 1px solid var(--dividers-light-stroke) !important;
        border-radius: 0;
    }

    th {
        border-bottom: 0;
        padding-bottom: 0.75rem;
        padding-top: 1rem;
        text-transform: initial;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    thead th {
        letter-spacing: 0;
        text-transform: capitalize;
        border-bottom-color: var(--dividers-light-stroke);
        padding: 12px;

        .HelpTooltip {
            margin-left: 8px;
        }
    }

    tbody td {
        border: 0 !important;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 12px;
    }

    tfoot td {
        border: 0 !important;
        padding: 0;
    }

    th:first-child,
    td:first-child {
        text-align: left;
    }

    th:last-child,
    td:last-child {
        border-right: 0 !important;
    }

    td:nth-child(2),
    th:nth-child(2) {
        text-align: right;
    }

    &-selected {
        background-color: var(--tables-hover);
        border: 0;
    }

    @media print {
        .ChannelMetricsTable-selected {
            background-color: var(--gray-200) !important;
            color-adjust: exact;
            -webkit-print-color-adjust: exact;
        }
    }

    .Metric {
        padding: 0;
    }

    .DotGlyphIcon {
        margin-right: 8px;
    }

    &.InsightsGridTable {
        border: none;

        .DotGlyphIcon {
            min-width: 16px;
        }
    }
}
