.TopAudienceByAge {
    flex: 1;
    position: relative;

    &-title {
        font-weight: 400;
        margin-bottom: 2rem;
    }

    &-empty-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 8rem;
        text-align: center;
    }

    &-empty-content {
        .highcharts-grid {
            display: none;
        }
    }

    .highcharts-axis-labels {
        fill: var(--gray-700);
    }

    .highcharts-data-label {
        opacity: 0;
    }
    .highcharts-series-hover .highcharts-data-label {
        opacity: 1;
    }
}
