.ChartHighlights {
    display: flex;

    .CompareSection-content {
        display: flex;
        flex-direction: row;
        padding: 2rem;
    }

    &-all-charts {
        font-size: 12px;
        font-weight: var(--weight-bold);
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-header {
        margin-bottom: 15px;
    }

    &-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-width: 0;

        .Table {
            box-shadow: none !important;
            padding: 0 !important;

            th.chart-col-chart {
                width: auto;
            }
        }
    }

    &-item + &-item {
        margin-left: 10px;
    }

    &-item-header {
        align-items: center;
        background-color: var(--gray-100);
        border-radius: 3px;
        display: flex;
        font-size: 14px;
        padding: 0.75rem;
        margin-bottom: 15px;
        min-height: 4rem;
        max-height: 4rem;

        .DotGlyphIcon {
            display: block;
        }

        &-content {
            @include truncated-text;

            flex: 1;
            font-weight: $font-weight-bold;
            padding: 0 10px;
            white-space: nowrap;
        }
    }
}
