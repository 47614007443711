.LegendOtherSection {
    .LegendsTooltip {
        width: fit-content;
    }
    &-content {
        display: flex;
        gap: 10px;
        @include typography-small;
    }

    .ProgressBar {
        width: 40px;
        &-container {
            height: 10px;
        }
    }

    &-percentage {
        color: var(--text-secondary);
    }

    .ValuePanel-header {
        margin-bottom: 12px;
    }
}

.SongLegendHeader {
    display: flex;
    align-items: center;
    .custom-control-label {
        @include typography-body;

        text-transform: capitalize;
    }
}

.SongPerformance {
    .PotMenuButton {
        width: 32px;
        height: 32px;
        align-items: center;
        display: flex;
        justify-content: center;
        color: var(--jelly-bean-blue);

        .btn:focus {
            box-shadow: none;
        }
    }

    &-section.DataSection {
        .DataSection-header-title {
            font-size: 18px;
        }
    }
}
