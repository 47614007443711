.PageHeader {
    .DataList {
        align-items: center;
        border-bottom: 1px solid var(--gray-300);
        display: flex;
        height: 2.2rem;
        margin: -1rem -1rem 0.5rem;
        padding: 0 1rem;

        .DataListItem:first-child {
            padding-left: 0;
        }

        .LinkList-item,
        .TruncatedText {
            color: var(--text-secondary);

            &:hover {
                color: var(--gray-800);
            }
        }
        .DataListEntities {
            > a {
                display: inline-block;
                margin-right: 0.5rem;
                text-decoration: underline;
            }

            > a + a {
                border-left: solid 1px var(--gray-100);
                padding-left: 0.5rem;
            }
        }
    }

    .CoverArt {
        margin-right: 1rem;
    }

    .Metric {
        padding: 1rem;
        text-align: right;
        line-height: 1.1;

        &-header {
            font-size: 0.8rem;
            font-weight: 500;
        }

        &-lg {
            font-size: $font-size-base * 1.6;
        }
    }
}
