.VideoPage {
    .DataSection {
        box-shadow: 0 0 4px 0 var(--border);

        &-header {
            padding: $grid-gap $grid-gap 0;

            &-title {
                font-weight: 400;
                line-height: 2;
                margin-bottom: 0;
            }
        }

        &-subheader {
            padding: 0 0 $grid-gap $grid-gap;
            margin: 0;
        }
    }

    .ContentPanel-empty {
        min-height: 14rem;
    }
}
