.DailyCharts {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-title {
        display: flex;
        align-items: center;
        gap: 8px;

        @include text-heading(3);

        .PlatformIcon {
            margin-right: 0;
        }
    }

    &-tooltip {
        &-overlay {
            .tooltip-inner {
                max-width: 220px;
            }
        }
    }

    &-filters {
        display: flex;
        gap: 10px;
    }

    &-table {
        &-header {
            display: flex;
            align-items: center;
            gap: 8px;

            .DotGlyphIcon {
                min-width: fit-content;
            }
        }

        .GridTable-header-left {
            @include typography-small;
            @include typography-secondary(false);

            font-weight: var(--weight-bold);
        }
    }

    .InsightsGridTable-section-table {
        border-radius: 8px;
        border: 1px solid var(--dividers-light-stroke);
        padding: 0 8px;
    }
}
