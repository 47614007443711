.SearchAllResultsPage-Songs {
    .InsightsGridTable {
        &-section-table {
            padding: 0 12px;
        }

        &.SongResultsGridTable-allResults.TableResults {
            .GridTable-container {
                min-height: 180px;
            }
            .GridTable-body .GridTable-row .GridTable-cell {
                height: 60px;
            }

            &.SongResultsGridTable-allResults--tableContainerMinHeight {
                .GridTable-row {
                    &:last-of-type {
                        .GridTable-cell {
                            border-bottom: 1px solid var(--gray-300);
                        }
                    }
                }
            }
        }
    }
}
