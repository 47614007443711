$tooltip-width: 340px;
$marker-width: 16px;

.GraphAnnotations {
    align-items: center;
    background-color: var(--gray-100);
    border-top: 1px solid var(--gray-300);
    display: flex;
    position: absolute;
    z-index: 1;

    &-point {
        width: 0;
        overflow: visible;
        position: relative;

        &:hover {
            .GraphAnnotations-marker::before {
                position: absolute;
                content: '';
                background-color: var(--gray-300);
                height: 18px;
                top: 2px;
                width: 20px;
                left: -2px;
            }
        }
    }

    &-marker {
        width: $marker-width;
    }

    &-tooltip {
        background-color: white;
        border: 1px solid var(--border);
        border-radius: 0.25rem;
        box-shadow: 0 0 4px 0 var(--border);
        padding: 0.6rem;
    }
}

@media print {
    .GraphAnnotations {
        /* Fudge approximate width since print doesn't let highcharts rerender properly */
        width: calc(100% - 55px) !important;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;

        .HelpTooltip {
            display: inline-block;
        }
    }
}
