.TrendScoresPopup {
    &-tooltip {
        background: var(--white);
        box-shadow: 0 1px 5px rgb(28 28 28 / 45%);
        border-radius: 3px;
        overflow: auto;
        text-align: left;
        z-index: 2;

        &-row {
            display: grid;
            grid-auto-flow: column;
            column-gap: 20px;
            grid-auto-columns: 170px 240px;
            align-items: center;
            background: var(--white);
            padding: 5px 0;
            overflow: hidden;

            &-country {
                @include truncated-text;

                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--text-secondary);
            }
        }

        &-header {
            background: var(--gray-100);
            padding: 20px;

            &-name {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--text-secondary);
            }

            &-title {
                display: flex;
                flex-direction: column;

                &-song-name {
                    @include truncated-text;
                }
            }

            &-subtitle {
                @include truncated-text;

                font-size: 12px;
                margin: 0;
                color: var(--text-secondary);
            }
        }

        &-content {
            padding: 5px 20px;
        }
    }
}
