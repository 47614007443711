.TiktokPerformanceOverTimeContent {
    &V2 {
        margin-bottom: 20px;

        &-title,
        &-period {
            align-items: center;
            display: flex;
            gap: 8px;
        }

        &-export {
            min-width: 32px;
        }

        &-tabs-tooltip {
            .tooltip-inner {
                max-width: 314px;
                text-align: left;
            }
        }

        .MarketsSelector {
            margin: 0;
        }
    }
}
