.InsightsSelect {
    &:not(:has(.SuiteSelect-input-compact)) {
        min-width: 200px;
        width: auto;
    }

    .SuiteSelect-input-value {
        min-width: 0;

        .SuiteSelect-input-value-label {
            min-width: 0;
        }

        .TruncatedText {
            display: flex;
        }
    }

    .SuiteListView-option-content {
        width: 100%;
    }
}
