.SearchResultsLoadingIndicator {
    &-cover {
        @include skeleton-loader-element-square($image-cover-size);
    }

    &-line {
        @include skeleton-loader-element(20%, 18px);
    }

    &-line-sm {
        @include skeleton-loader-element(10%);
    }

    .GlobalSearchResults-option,
    .GlobalSearchResults-option:hover {
        background: inherit;
        border-color: var(--gray-100);
        cursor: default;
        flex: 1;
    }

    .SearchLoaderGroup {
        margin-bottom: 28px;
    }
}
