.Rights {
    &-table {
        margin-top: 10px;

        tr:last-child td {
            border-bottom: none !important;
        }

        td > div {
            display: inline-block;
        }
    }

    &-label {
        color: var(--text-secondary);
    }

    display: grid;
    grid-template-columns: 225px 1fr;

    &-countries {
        display: grid;
        grid-template-columns: repeat(18, 35px);
        margin-top: 10px;
        margin-left: 70px;
    }

    &-country {
        padding: 0.5rem;
        color: var(--gray-500);
        cursor: pointer;
        &.highlighted {
            color: var(--text-primary);
            font-weight: 500;
        }
    }

    &-tooltip span {
        margin-left: 8px;
    }
}
