.NewFeatureHighlight {
    align-items: center;
    display: flex;

    &-container {
        background-color: var(--gray-300);
        position: relative;
        height: 1.5em;
        margin-right: 13px;
        margin-left: 20px;
    }

    &-container::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 100%;
        top: calc(50% - 6px);
        border-top: 6px solid transparent;
        border-left: 10px solid var(--gray-300);
        border-bottom: 6px solid transparent;
    }

    .PlatformIcon {
        background: var(--gray-300);
        height: 1.5em;
        margin: 0;
        position: absolute;
        right: 100%;
        top: calc(50% - 0.75em);

        &-deezer,
        &-spotify {
            border-radius: 50%;
            border-bottom-right-radius: 0;
        }
    }
}
