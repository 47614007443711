.ComparePanelItemAdd {
    background: none;
    border: 1px dashed var(--blue);
    font-weight: var(--weight-bold);
    height: 100%;

    &-search-container {
        position: relative;
    }

    &-button {
        height: 100%;

        svg {
            margin-bottom: 1px;
        }

        .PlusGlyphIcon {
            margin-bottom: 0;
        }

        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}

.SuiteColorSchemeV2 {
    .ComparePanelItemAdd {
        border: 1px dashed var(--midnight-650);
    }
}
