.catalogLoadingIndicator {
    display: grid;
    grid-template-rows: 17px 15px 17px;
    grid-template-columns: 0 16px 0;

    .catalog {
        min-width: 16px;
        width: 16px;
        height: 16px;
        grid-row-start: 2;
        grid-column-start: 2;

        .SkeletonLoader-line {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }
    }
}
