.ChartPageHeader {
    &-title {
        display: flex;
    }

    &-subtitle {
        display: inline-block;
        font-size: 1rem;
        font-weight: normal;
        width: max-content;

        &-overlay {
            .tooltip-inner {
                max-width: 220px;
            }
        }
    }

    .SkeletonLoader {
        margin-bottom: 7px;
    }

    .HelpTooltip {
        margin-right: auto;
    }

    .ChartsDropdown {
        cursor: pointer;

        .Glyph {
            fill: var(--gray-800);
        }
        .Select__control {
            border: none;
        }
        .Select__value-container {
            visibility: hidden;
            order: 1;
        }
        .Select__indicators {
            order: 0;
        }
    }
}
