@import './breakdownTable/breakdownTable.scss';

.PlaylistTypeBreakdown {
    border-top: 2px solid var(--gray-300);
    margin-right: $grid-gap;
    display: flex;
    flex-direction: row;

    &-header {
        @include truncated-text;

        color: var(--text-secondary);
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 0 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &-types {
        flex-grow: 1;
        min-width: 0;
    }

    .LoadingIndicator {
        /* Avoid layout shift */
        height: 480px;
    }
}

.PlaylistStoreBreakdown {
    border-top: 2px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-header {
        @include truncated-text;

        color: var(--text-secondary);
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        margin: 10px 0 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;

        &.TruncatedText {
            display: block;
        }
    }

    &-chart {
        flex: 1;
        min-width: 0;
    }

    .PieChart {
        &-chart {
            max-width: 120px;
            height: auto;
            width: 100%;

            .highcharts-root {
                width: 100%;
            }
        }

        &-row {
            margin: 0;
            padding: 1px 0;
            line-height: $breakdown-line-height;

            &-second {
                min-width: 0;

                .TruncatedText {
                    display: block;
                }
            }
        }
    }

    .bg-dataviz-0-0,
    .series-icon-color-dataviz-0-0,
    .series-icon-color-dataviz-0-0 > circle {
        fill: var(--color-apple-music) !important;
        stroke: var(--color-apple-music) !important;
        color: var(--color-apple-music) !important;
    }

    .bg-dataviz-0-1,
    .series-icon-color-dataviz-0-1 {
        fill: var(--color-amazon-music) !important;
        stroke: var(--color-amazon-music) !important;
        color: var(--color-amazon-music) !important;
    }

    .bg-dataviz-0-2,
    .series-icon-color-dataviz-0-2 {
        fill: var(--color-spotify) !important;
        stroke: var(--color-spotify) !important;
        color: var(--color-spotify) !important;
        filter: brightness(130%);
    }

    .bg-dataviz-0-3,
    .series-icon-color-dataviz-0-3 {
        fill: var(--color-deezer) !important;
        stroke: var(--color-deezer) !important;
        color: var(--color-deezer) !important;
    }

    .bg-dataviz-0-4,
    .series-icon-color-dataviz-0-4 {
        fill: var(--color-youtube) !important;
        stroke: var(--color-youtube) !important;
        color: var(--color-youtube) !important;
    }

    .StoreLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .PlaylistBreakdownTable {
        &-header {
            padding-bottom: 15px;
        }

        margin-bottom: 5px;
    }
}
