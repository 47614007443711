@mixin typography-h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@mixin typography-h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@mixin typography-h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

@mixin typography-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin typography-body {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

@mixin typography-small {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

@mixin typography-secondary($important: false) {
    @if $important {
        color: var(--text-secondary) !important;
    } @else {
        color: var(--text-secondary);
    }
}

@mixin typography-primary($important: fasle) {
    @if $important {
        color: var(--text-primary) !important;
    } @else {
        color: var(--text-primary);
    }
}

p {
    @include typography-secondary;
}

.SuiteColorSchemeV1 {
    --text-secondary: var(--gray-700);
}

.text-primary {
    @include typography-primary(true);
}

.text-secondary {
    @include typography-secondary(true);
}
