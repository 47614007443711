.PlaylistInfoModal {
    width: 980px;

    .Dropdown {
        margin-bottom: 0;
    }

    &-modal-header {
        display: flex;
        flex-direction: column;
    }

    &-modal-filters {
        display: flex;
        margin: 20px 0;
        column-gap: 20px;

        .CountryFilter,
        .DatePickerFilter {
            min-width: 296px;
        }
    }

    &-song-link {
        display: block;

        .TruncatedText {
            display: block;
        }
    }

    &-infos {
        margin-right: 70px;
        flex: 1;
    }

    &-column + &-column {
        margin-left: 10px;
    }

    &-info {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
        column-gap: 20px;

        &-image {
            width: 60px;
            height: 60px;
        }

        &-image-container {
            margin-right: 10px;
        }

        .DataValueField {
            .TruncatedText {
                display: block;
            }

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            .HelpTooltip {
                line-height: 0;
            }
        }

        .PlaylistInfoModal-song-link,
        .PlaylistLink {
            margin-bottom: 0.5rem;
            line-height: 1.2;
            font-size: 1.33rem;
        }
    }

    &-info + &-info {
        margin-top: 20px;
    }

    &-cards {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        column-gap: 10px;

        .LoadingIndicator {
            min-height: auto;

            &-dots {
                padding: 0;
                margin: 15px 0;
            }
        }
    }

    &-card {
        text-align: center;
        height: 110px;

        &-header {
            text-transform: uppercase;
            white-space: nowrap;
            letter-spacing: 1px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            margin-bottom: 5px;

            @include truncated-text;
        }

        h3 {
            margin-bottom: 4px;
        }

        &-text {
            span {
                color: var(--green);
            }
        }

        .card-body {
            padding: 19px;
        }
    }

    &-modal-container {
        .ReactModal-header {
            border: none;
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 2;
        }

        .ReactModal-body {
            padding: 20px;
        }
    }
    .PeakPosition {
        stroke: transparent;
        position: relative;
        top: -16px;
    }

    .PerformanceChart {
        margin-top: 1rem;

        .LoadingSpinner {
            top: 30%;
            z-index: 1;
        }
    }

    .highcharts-legend-item {
        text {
            font-weight: normal;
            font-size: 0.8rem;
            letter-spacing: 0.05rem;
            text-transform: uppercase;
        }
    }
}
