@use 'sass:map';
@use 'sass:list';

@each $name, $colours in $extended-stores-chart-colors {
    $color-identifier: list.nth($colours, 1);

    // there is no inactive colours, so we should find it in other map
    $colour-name: extract-base-color($color-identifier);
    $base-color-map: find-sub-map-by-colour($data-colour-index, $colour-name);
    $active-1: map.get($base-color-map, 'active-1');
    $active-2: map.get($base-color-map, 'active-2');
    $inactive-1: map.get($base-color-map, 'inactive-1');
    $inactive-2: map.get($base-color-map, 'inactive-2');
    $active-color-1: var(--#{$colour-name}-#{$active-1});
    $active-color-2: var(--#{$colour-name}-#{$active-2});
    $inactive-color-1: var(--#{$colour-name}-#{$inactive-1});
    $inactive-color-2: var(--#{$colour-name}-#{$inactive-2});

    .bg-dataviz-platform-#{$name} {
        background: $active-color-1 !important;
        fill: $active-color-1 !important;
        stroke: $active-color-1 !important;

        &.highcharts-point-inactive {
            fill: $inactive-color-1 !important;
            stroke: $inactive-color-1 !important;
        }

        &-2 {
            background: $active-color-2 !important;
            fill: $active-color-2 !important;
            stroke: $active-color-2 !important;

            &.highcharts-point-inactive {
                fill: $inactive-2 !important;
                stroke: $inactive-2 !important;
            }
        }
    }

    .border-dataviz-platform-#{$name} {
        border-color: $active-color-1 !important;

        &-2 {
            border-color: $active-color-2 !important;
        }
    }

    .color-dataviz-platform-#{$name} {
        color: $active-color-1 !important;
        stroke: $active-color-1 !important;
        fill: $active-color-1 !important;

        &-2 {
            color: $active-color-2 !important;
            stroke: $active-color-2 !important;
            fill: $active-color-2 !important;
        }
    }
}

@each $name, $color in $source-of-streams-details-colors-v2 {
    .bg-dataviz-sos-details-#{$name} {
        background-color: $color !important;
        fill: $color !important;
        stroke: $color !important;
    }

    .border-dataviz-sos-details-#{$name} {
        border-color: $color !important;
    }

    .color-dataviz-sos-details-#{$name} {
        color: $color !important;
        stroke: $color !important;
        fill: $color !important;
    }
}

// We need to use a dataviz-(index-1) to comply with what our charts components expect

@for $index from 1 through length($data-colour-index) {
    $name: 'data-#{$index}';
    $index-string: '#{$index}';
    $value: map.get($data-colour-index, $index-string);
    $bg-data-line-color: var(--#{$name}-line);
    $bg-data-area-color: var(--#{$name}-area);
    $bg-data-bar-color: var(--#{$name}-bar);
    $bg-data-inactive-line-color: var(--#{$name}-inactive-line);
    $bg-data-inactive-area-color: var(--#{$name}-inactive-area);
    $bg-data-inactive-bar-color: var(--#{$name}-inactive-bar);
    $colour-name: map.get($value, 'colour');
    $color-map: map.get($data-colors, $colour-name);
    $active-1: map.get($value, 'active-1');
    $active-2: map.get($value, 'active-2');
    $inactive-1: map.get($value, 'inactive-1');
    $inactive-2: map.get($value, 'inactive-2');
    $active-color-1: var(--#{$colour-name}-#{$active-1});
    $active-color-2: var(--#{$colour-name}-#{$active-2});
    $inactive-color-1: var(--#{$colour-name}-#{$inactive-1});
    $inactive-color-2: var(--#{$colour-name}-#{$inactive-2});

    // Get the index of the active-1 and inactive-1 colors in the color map
    $color-identifier-list: map.keys($color-map);
    $index-of-active-1-color-in-map: index($color-identifier-list, $active-1);
    $index-of-inactive-1-color-in-map: index(
        $color-identifier-list,
        $inactive-1
    );

    // Normalize the index to start from 0
    $normalized-index: $index - 1;

    .highcharts-point-inactive,
    .highcharts-series-inactive {
        opacity: 1;
        .bg-dataviz-#{$normalized-index} {
            color: $bg-data-inactive-line-color !important;
            stroke: $bg-data-inactive-line-color !important;
            fill: $bg-data-inactive-line-color !important;
            opacity: 1;
        }
    }

    .bg-dataviz-#{$normalized-index} {
        background-color: $bg-data-line-color !important;
        fill: $bg-data-line-color !important;
        stroke: $bg-data-line-color !important;

        &.highcharts-point-inactive,
        &.highcharts-series-inactive {
            color: $bg-data-inactive-line-color !important;
            stroke: $bg-data-inactive-line-color !important;
            fill: $bg-data-inactive-line-color !important;
            opacity: 1;
        }
    }
    .bg-dataviz-#{$normalized-index}.ProgressBar-bar {
        background: $bg-data-bar-color !important;
        fill: $bg-data-line-color !important;
        stroke: $bg-data-line-color !important;
    }

    // Max 5 sub colors (inactive colors overlap)
    @for $subIndex from 0 through 4 {
        $sub-color-identifier: nth(
            $color-identifier-list,
            $index-of-active-1-color-in-map + $subIndex
        );
        $sub-color: var(--#{$colour-name}-#{$sub-color-identifier});

        .bg-dataviz-#{$normalized-index}-#{$subIndex},
        .series-icon-color-dataviz-#{$normalized-index}-#{$subIndex},
        .bg-dataviz-#{$normalized-index}.bg-dataviz-#{$normalized-index}-#{$subIndex},
        .series-icon-color-dataviz-#{$normalized-index}.series-icon-color-dataviz-#{$normalized-index}-#{$subIndex} {
            fill: $sub-color !important;
            stroke: $sub-color !important;
            color: $sub-color !important;
            filter: unset;
        }

        .bg-dataviz-#{$normalized-index}-#{$subIndex},
        .bg-dataviz-#{$normalized-index}.bg-dataviz-#{$normalized-index}-#{$subIndex} {
            background-color: $sub-color !important;
            filter: unset;
        }
    }

    // Max 3 sub colors (inactive colors overlap)
    // inactive styles
    @for $subIndex from 0 through 2 {
        $sub-inactive-color-identifier: nth(
            $color-identifier-list,
            $index-of-inactive-1-color-in-map + $subIndex
        );
        $sub-inactive-color: var(
            --#{$colour-name}-#{$sub-inactive-color-identifier}
        );

        .bg-dataviz-#{$normalized-index}-#{$subIndex},
        .series-icon-color-dataviz-#{$normalized-index}-#{$subIndex},
        .bg-dataviz-#{$normalized-index}.bg-dataviz-#{$normalized-index}-#{$subIndex},
        .series-icon-color-dataviz-#{$normalized-index}.series-icon-color-dataviz-#{$normalized-index}-#{$subIndex} {
            &.highcharts-point-inactive,
            &.highcharts-series-inactive {
                color: $sub-inactive-color !important;
                stroke: $sub-inactive-color !important;
                fill: $sub-inactive-color !important;
                opacity: 1;
                filter: unset;
            }
        }
    }

    .border-dataviz-#{$normalized-index} {
        border-color: $bg-data-line-color !important;
    }

    .color-dataviz-#{$normalized-index},
    .series-icon-color-dataviz-#{$normalized-index} {
        color: $bg-data-line-color !important;
        stroke: $bg-data-line-color !important;
        fill: $bg-data-line-color !important;
    }

    .color-dataviz-#{$normalized-index}-0 {
        &,
        & > circle {
            fill: $active-color-2 !important;
            color: $active-color-2 !important;
            stroke: $active-color-2 !important;
        }
    }

    .stroke-dataviz-#{$normalized-index} {
        circle {
            stroke: $bg-data-line-color !important;
            stroke-width: 1px;
        }
    }
}
