.VideoPageHeader {
    .SocialLinks {
        margin-left: auto;
    }

    .PageTitle {
        overflow: hidden;
    }

    .ShareButton {
        padding: 5px 0;
        margin-left: auto;
    }
}
