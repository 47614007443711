.Revenue {
    &-metric {
        border-top: 1px solid var(--border);

        &:nth-child(2) {
            border-left: 1px solid var(--border);
        }

        .Metric {
            text-align: center;
        }
    }
}
