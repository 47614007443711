.PageBodyNavigation {
    align-items: flex-start;
    flex: 0;
    position: relative;
    margin-bottom: 20px;

    &--with-top-pace {
        margin-top: 11px;
    }

    .nav-link {
        margin: 0;
        padding: 8px 12px 9px 12px;
        font-size: 12px;
        color: var(--gray-700);
    }
}
