.LegendsTooltip {
    &.HelpTooltip {
        display: block;
    }

    &-content {
        font-size: 12px;
        padding: 15px;
        line-height: 1.5;

        &-value {
            font-weight: var(--weight-bold);
            text-align: right;
        }

        &-subValue {
            font-weight: var(--weight-bold);
            margin-right: 10px;
            color: var(--text-secondary);
        }
    }
    &-title {
        margin-bottom: 5px;
        @include typography-label;

        text-transform: initial;
        letter-spacing: initial;
    }

    &-rows {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 5px 20px;

        &.withSubValue {
            grid-template-columns: repeat(3, auto);
        }
    }
}
