.MetricsHeader {
    position: relative;
    top: 10px;

    &-metricValue {
        font-size: 14px;
    }

    .Metric {
        padding-bottom: 0;
        padding-top: 0;
    }
}
