$no-results-offset-top: 263px; // offset from the top of the page
$error-offset-top: 176px; // offset from the top of the page
.SongChartsPage {
    &-error {
        height: calc(100vh - #{$error-offset-top + $common-container-padding});
    }

    &-noResults {
        height: calc(
            100vh - #{$no-results-offset-top + $common-container-padding}
        );
    }

    .ErrorV2 .tableView {
        height: calc(100vh - 305px);
    }

    .Error-without-tabs.ErrorV2 .tableView {
        height: calc(100vh - 201px);
    }

    .SongChartsGridTable {
        &V2 {
            .GridTable {
                &-header-row {
                    .GridTable-column-loading {
                        padding: 8px;
                        height: 56px;

                        .SkeletonLoader-line {
                            height: 16px;
                        }
                    }
                }

                &-column {
                    &.util-align-right {
                        .GridTable-column-sort-indicator {
                            order: initial;
                        }
                    }

                    &.col-expandable-row-indicator {
                        border-left: 1px solid var(--gray-300);
                    }

                    &.GridTable-row-cell {
                        &.col-position,
                        &.col-change,
                        &.col-peakPosition,
                        &.col-daysOnChart {
                            .SkeletonLoader-line {
                                margin-left: auto;
                            }
                        }

                        &.col-expandable-row-indicator {
                            .SkeletonLoader {
                                display: none;
                            }
                        }
                    }
                }

                &-row {
                    .GridTable-cell {
                        height: 40px;

                        // The loader for the expandable row indicator should be circular
                        &:last-of-type {
                            border-left: 1px solid var(--gray-300);

                            .SkeletonLoader {
                                height: 16px;
                                width: 16px;
                                min-width: 16px;
                                margin: auto;

                                .SkeletonLoader-line {
                                    height: 16px;
                                    width: 16px;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }

            &-chartLoader {
                width: 200px;
            }
        }
    }
}
