.PerformanceChart {
    overflow: visible;
    position: relative;
    width: 100%;

    &-container {
        position: relative;
    }

    & > div,
    .highcharts-container {
        /* stylelint-disable declaration-no-important */
        overflow: visible !important;
        /* stylelint-enable */
    }

    .highcharts-root {
        width: 100%;
        font-family: var(--font-family-sans-serif, sans-serif);
    }

    .highcharts-axis-labels {
        fill: var(--gray-700);
    }

    .highcharts-xaxis-labels {
        opacity: 1;
        transition: opacity 150ms ease-in-out;
    }

    .highcharts-plot-band {
        fill: var(--gray-100);
        fill-opacity: 100%;
    }

    .chart-plotline-zero {
        stroke: var(--gray-400);
        stroke-width: 2px;
    }

    .chart-yaxis-secondary {
        .highcharts-grid-line {
            stroke: none;
        }
    }

    .chart-series-secondary {
        color: var(--midnight-1);
        stroke: var(--midnight-1);
    }

    &-error {
        @include vertical-align;
        @include horizontal-align;

        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 4rem;

        .card {
            border: none;
        }

        .OutlineIcon {
            margin-bottom: 1rem;
        }
    }

    .LoadingSpinner {
        position: absolute;
        height: 30px;
        width: 30px;
        right: 50%;
        top: -30px;
    }
}

.highcharts-tooltip {
    border: none;
    filter: none;
    margin: 0;
    font-size: 12px;
    min-width: 17rem;

    &-box {
        fill: var(--white);
        stroke: var(--gray-400);
        border-radius: 0.25rem;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
        color: black;
        fill-opacity: 100%;
    }

    &-item {
        margin-bottom: 0.5rem;
    }

    &-name {
        display: inline-block;
        margin-left: 0.5rem;
        max-width: 11rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: auto;
    }

    &-data {
        display: flex;
    }

    &-value,
    &-change,
    &-percentage {
        font-weight: 500;
        padding-left: 0.5rem;
        width: 4rem;
        text-align: right;
    }

    &-percentage {
        color: var(--text-secondary);
        width: 3rem;
    }

    &-change {
        &.positive {
            color: var(--text-success);
        }

        &.negative {
            color: var(--text-error);
        }
    }

    &-header {
        font-weight: 500;
        padding-left: 1.3rem;
        color: var(--text-primary);
    }

    &-footer {
        display: flex;
        border-top: 1px solid var(--dividers-light-stroke);
        padding: 0.5rem 0 0.2rem 1.3rem;

        &-title {
            flex: 1;
        }

        &-value,
        &-percentage {
            font-weight: 500;
            text-align: right;
            width: 4rem;
        }

        &-percentage {
            color: var(--text-secondary);
            width: 3rem;
        }
    }

    &-bullet {
        padding-right: 0.5rem;
        width: 0.8rem;
    }

    &-message {
        color: var(--gray-500);
        font-size: 0.9rem;
        margin-left: 1.3rem;
    }
}

.chart-series-default {
    fill: var(--teal-800);
    stroke: var(--teal-800);
    color: var(--teal-800);

    &-2 {
        fill: var(--teal-700);
        stroke: var(--teal-700);
        color: var(--teal-700);
    }
}

@mixin chart-checkbox-colors($name, $color) {
    .chart-series-#{$name} {
        fill: $color;
        stroke: $color;
        color: $color;
    }
    .Checkbox-icon:not(.checked).#{$name} {
        background-color: var(--white) !important;
        stroke: var(--white) !important;

        .Glyph,
        .GlyphIcon {
            fill: var(--white);
        }
    }
    .Checkbox-icon.checked.#{$name} {
        background-color: $color;
        border-color: $color;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;

        .Glyph,
        .GlyphIcon {
            fill: var(--white) !important;
        }
    }
}

@each $name, $colours in $extended-stores-chart-colors {
    $color: var(--#{$name}-line);

    .chart-series-#{$name} {
        fill: $color;
        stroke: $color;
        color: $color;
    }

    @if $name == 'tiktok' {
        .chart-series-#{$name}-non-tiktok-streams,
        .chart-series-#{$name}-views {
            fill: var(--teal-700);
            stroke: var(--teal-700);
            color: var(--teal-700);
        }
    }

    .Checkbox-icon.checked.#{$name} {
        background-color: $color;
        border-color: $color;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;

        .Glyph,
        .GlyphIcon {
            fill: var(--white) !important;
            color: var(--white) !important;
        }
    }

    .SummaryRowCheckbox-icon.checked.#{$name} {
        background-color: $color;
        border-color: $color;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    @include chart-checkbox-colors($name, $color);
}

@each $name, $color in $source-of-streams-colors {
    @include chart-checkbox-colors($name, $color);
}
