.VideoFilters {
    margin-right: auto;

    &-period {
        @include vertical-align;

        .HelpTooltip {
            color: var(--gray-500);
        }
    }
}
