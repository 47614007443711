.AllPlaylistStoresHeader {
    min-width: 0;
    padding-right: 10px;

    &-title {
        line-height: 20px;
    }

    &-stores {
        column-gap: 10px;
        display: flex;
        font-size: 1rem;
        font-weight: 400;
        line-height: 2;

        .StoreLabel {
            align-items: center;
            display: flex;
            min-width: 0;
        }
    }
}
