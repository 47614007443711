.AccountsInfoPopup {
    position: relative;
    display: flex;

    &-account {
        cursor: pointer;
        background: var(--gray-200);
        border-radius: 100px;
        height: 24px;
        padding: 0 8px;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 8px;
        }

        &-with-logo {
            padding-left: 30px;
        }

        &-active {
            background: var(--gray-300);
        }

        &-more {
            min-width: min-content;
            padding-left: 5px;
        }
    }

    &-popup {
        margin: 8px 0;
        z-index: 100;
    }
}

.AccountsDetailsMenu {
    background: var(--gray-100);
    box-shadow: 0 1px 5px rgb(28 28 28 / 45%);
    border-radius: 3px;
    padding: 3px 0;
    max-height: 615px;
    overflow: auto;

    table {
        margin: 0;
    }

    td {
        vertical-align: middle;
        display: table-cell !important;
        border: none !important;
        padding: 0 20px !important;
        background-color: var(--white) !important;
    }

    &-account-name td {
        background-color: var(--gray-100) !important;
        padding: 20px !important;

        label {
            font-size: 12px;
            margin: 0;
            color: var(--text-secondary);
        }
    }

    td:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--text-secondary);
    }

    tr {
        display: table-row !important;
    }

    &-account-tier {
        height: 40px;
        display: flex;
        align-items: center;

        span {
            width: 24px;
            height: 24px;
            background: var(--text-primary);
            color: var(--white);
            margin-right: 1rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }

    &-overlay {
        width: 100%;
        height: 50px;
        background: linear-gradient(
            180deg,
            rgb(255 255 255 / 0%) 0%,
            var(--white) 100%
        );
        position: absolute;
        bottom: 0;
        z-index: 1;
    }

    svg {
        width: 20px;
        height: 40px;
        margin-right: 0.5rem;
    }
}
