$cover-art-w20: 20px;
$cover-art-w30: 30px;
$cover-art-w40: 40px;
$cover-art-w50: 50px;
$cover-art-w53: 53px;
$cover-art-w60: 60px;
$cover-art-w70: 70px;
$cover-art-w80: 80px;
$cover-art-w120: 120px;
$cover-art-w160: 160px;

@mixin define-size($name, $size) {
    $classname: #{'w' + $name + 'px'};
    .CoverArt {
        &.#{$classname},
        &.#{$classname} &-empty,
        &.#{$classname} &-image {
            width: $size;
            height: $size;
        }

        // Letterbox/landscape coverart shape, used for youtube videos
        $letterbox-margin: ($size - ($size / (16 / 9))) / 2;
        &.#{$classname}.landscape,
        &.#{$classname}.letterbox,
        &.#{$classname}.landscape &-image,
        &.#{$classname}.landscape &-empty,
        &.#{$classname}.letterbox &-image,
        &.#{$classname}.letterbox &-empty {
            height: #{$size - ($letterbox-margin * 2)};
        }

        &.#{$classname}.letterbox {
            margin-top: $letterbox-margin;
            margin-bottom: $letterbox-margin;
        }
    }
}

.CoverArt {
    &-image {
        background-color: #f2f0f0;
    }

    &-empty {
        background-color: var(--gray-200);
    }

    &.w100pct,
    &.w100pct &-empty,
    &.w100pct &-image {
        width: 100%;
        height: 100%;
    }
}

@include define-size('20', $cover-art-w20);
@include define-size('30', $cover-art-w30);
@include define-size('40', $cover-art-w40);
@include define-size('50', $cover-art-w50);
@include define-size('53', $cover-art-w53);
@include define-size('60', $cover-art-w60);
@include define-size('70', $cover-art-w70);
@include define-size('80', $cover-art-w80);
@include define-size('120', $cover-art-w120);
@include define-size('160', $cover-art-w160);
