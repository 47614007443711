.ChannelVideosPage {
    &-table-info {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &-filter {
        width: $filter-width;
    }
    &-rhs {
        position: absolute;
        right: 0;
    }

    .Dropdown {
        max-width: 250px;
    }
}
