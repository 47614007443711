.ValuePanel {
    font-size: 12px;
    line-height: 1.5;

    &-header {
        color: var(--text-secondary);
        font-weight: var(--weight-bold);
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 5px;
        white-space: nowrap;

        .Glyph {
            fill: var(--text-secondary);
        }
    }

    &-value {
        &.md {
            font-size: 1.5em;
        }

        &.lg {
            font-size: 2em;
        }
    }
}
