.SongTopUGC {
    display: flex;

    .VideoLabel {
        font-size: 1rem;
        display: flex;
        align-items: center;

        &-text {
            display: block;
            flex: 1;
        }

        &:hover {
            .TruncatedText {
                text-decoration: underline;
            }
        }
    }

    &-section {
        height: auto !important;
    }

    &-header {
        margin-bottom: $grid-gap / 2;
    }

    &-header-menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-container {
        flex: 1;
        width: 0;

        & + & {
            margin-left: $grid-gap / 2;
        }
    }

    &-anchor {
        display: flex;
        overflow: hidden;
    }

    &-footer {
        font-weight: 500;
        margin: 1rem 0.5rem 0;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .ExternalLinkGlyphIcon {
        color: var(--blue);
    }

    .TopListItem-progress-container {
        width: 4rem;
    }

    .ErrorV2.section {
        height: 399px;
    }

    &-contentV2 {
        padding: 12px 20px;
    }

    &-footerV2 {
        padding-left: 20px;
        padding-bottom: 20px;
    }

    .GridTable .GridTable-cell {
        padding: 12px;
    }

    .DataSection-header {
        padding: 20px 20px 0;
    }
}
