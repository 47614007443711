.TopChannelTrafficSources {
    display: flex;
    padding: 0 1rem 1.5rem 1rem;

    .SourceLabel {
        align-items: center;
        display: flex;
        font-size: 1rem;
        line-height: 1rem;
    }
    @include permanent-scrollbar;
}
