.ParticipantMetrics {
    &-spotify {
        display: flex;
        align-items: center;

        .PlatformIcon {
            svg {
                height: 1rem;
                width: 1rem;
            }
        }
    }

    &-streamsAllTime {
        display: flex;
        align-items: center;

        .HelpTooltip {
            align-items: center;
            display: flex;
        }
    }
}
