.IntercomButton {
    height: 4rem;

    &-button {
        height: 100%;
        padding: 0 1rem;

        &:hover,
        &:focus,
        &:active {
            background-color: rgb(0 0 0 / 10%);
            text-decoration: none;
        }
    }

    .Chat {
        fill: var(--midnight-7);
        stroke-width: 2;
    }
}
