.PeriodPercentage {
    color: var(--text-success);
    border: 1px solid var(--glyphs-success);
    border-radius: 10%;
    min-width: 60px;
    line-height: 1.1;
    text-align: center;
    font-size: 0.8rem;
    padding: 0.5rem;
    font-weight: var(--weight-bold);
    letter-spacing: 0.05rem;

    &-period {
        text-transform: uppercase;
    }

    &.red {
        color: var(--text-error);
        border-color: var(--glyphs-error);
    }
}
