.ChannelAnnotationTooltip {
    z-index: 10;

    &-header {
        margin-bottom: 0.6rem;
        color: var(--gray-500);
    }

    &-video {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 0.6rem;

        &:last-child {
            margin-bottom: 0;
        }

        .LinkList {
            height: 1.5rem;

            a {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }
        }

        &-date {
            color: var(--gray-500);
        }
    }

    .CoverArt {
        display: flex;
        margin-right: 0.6rem;
    }

    &-metadata {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 75%;
    }
}
