.ChartPlacementsCard {
    height: 80px;

    &-title {
        padding: 3px;
    }

    &-info {
        margin: 4px 0;
        justify-content: space-between;
    }

    &-topMarkets {
        &-title.ChartDigest-card-subtitle {
            margin-right: 2px;
        }
        &-empty {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            color: var(--text-primary);
        }
    }

    &-placements,
    &-change {
        .ChartDigest-card-subtitle {
            margin-right: 3px;
        }
    }

    &-placements {
        &-value {
            color: var(--text-primary);
        }
    }

    &-change {
        .zero {
            color: var(--text-secondary);
        }

        .positive {
            color: var(--dataviz-9);
        }

        .negative {
            color: var(--dataviz-7);
        }
    }

    .separator {
        position: relative;
        margin: 0 6px;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 1px;
            height: 13px;
            background-color: var(--gray-200);
            transform: translateY(-30%);
        }
    }
}
