.ReactModal {
    &-body-open {
        .highcharts-tooltip-container {
            z-index: $z-index-popups-highcharts-tooltip !important;
        }
    }

    &__Overlay {
        z-index: $z-index-popups !important;
    }
}
