.SearchPageHeader_V2 {
    min-height: 80px;

    .Glyph.SearchGlyph {
        height: 2rem;
        width: 2rem;
        fill: var(--gray-500);
        margin-left: 0.75rem;
    }

    &-input {
        flex-grow: 1;
        position: relative;

        input {
            border: 0;
            font-size: 1.7rem;

            &:focus {
                box-shadow: none;
            }
        }

        &-spinner {
            position: relative;
            width: 50px;
            height: 30px;
            margin-right: 20px;
        }
    }

    &-input-options {
        position: absolute;
        background: white;
        width: 300px;
        box-shadow: $box-shadow;
        z-index: 1;
        padding: 1rem;
        color: #aaa;
    }

    &-input-option {
        padding: 0.5rem;

        &:hover {
            background-color: var(--info);
            color: var(--white);
        }
    }

    .SwitchersControls {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding-left: 30px;
        position: relative;

        &__separator {
            position: absolute;
            left: 10px;
            height: 30px;
            width: 1px;
            top: -4px;
            background-color: var(--gray-500);
        }
    }

    &-tab-skeleton {
        width: 100px;

        .SkeletonLoader-line {
            height: 12px;
            border-radius: 3px;
        }
    }
}
