.ComparePanelItem {
    height: 100%;

    .DotGlyphIcon,
    .CoverArt {
        margin-right: 1rem;
    }

    &-content {
        align-items: center;
        display: flex;
        padding: 15px;
    }

    &-body {
        align-items: center;
        display: flex;
        flex: 1;
    }

    &-close {
        align-self: flex-start;
        padding: 0;
        position: relative;
        right: -2px;
        top: -2px;

        &,
        &:hover,
        &:active,
        &:focus {
            color: var(--gray-500);
        }
    }
}
