.SpotifyPlayButton {
    outline: none;

    .PlayGlyph,
    .PauseGlyph {
        height: 16px;
        width: 16px;
    }

    &.disabled {
        .Icon > path {
            fill: var(--gray-300);
        }
    }

    &-hint {
        align-items: center;
        display: flex;
    }

    &-spotify-icon {
        margin-right: 5px;
    }
}
