.AccountVideosPage {
    padding: 0 6px 6px 6px;

    &-headerContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 20px 0;
    }

    &-youtubeVideosHeader {
        line-height: 32px;
        margin-bottom: 0;
    }
}
