.PerformanceOverTimeV2 {
    .InsightsGridTable-section-table:has(.InsightsGridTable.zebra-variant) {
        border: 1px solid var(--dividers-light-stroke);
    }

    .InsightsGridTable {
        .GridTable-container {
            max-height: 310px;
            overflow-y: auto;
        }

        .col-checkbox + .GridTable-column,
        .cell-checkbox + .GridTable-cell {
            border-left: none;
        }
    }

    &-title {
        margin: 0;
    }

    &-table {
        &.totals {
            .GridTable-row:first-of-type {
                font-weight: bold;
            }
        }

        .cell-checkbox,
        .col-checkbox {
            padding-right: 0;
        }

        .col-checkbox {
            padding-left: 8px;

            .SkeletonLoader-line {
                width: 16px !important;
                height: 16px;
            }
        }

        .cell-title {
            .only {
                color: var(--blue);
                cursor: pointer;
                margin-left: 1rem;
                opacity: 0;
                outline: none;
                transition: opacity ease 0.2s;
            }

            &:hover {
                .only {
                    opacity: 1;
                }
            }
        }

        &-title-only {
            display: flex;
        }

        &-title-cell-upc {
            color: var(--text-secondary);
            font-size: 0.85rem;
        }
    }

    &-loader {
        height: 262px;
        align-items: center;
        .SkeletonLoader-item {
            display: flex;
            justify-content: center;
        }
    }

    &-graph-types,
    &-graph-types-v2 {
        .ButtonGroup {
            margin: 0 10px;
        }
    }

    &-dropdown-separator {
        border-bottom: 1px solid var(--dividers-default);
    }

    .InfoMessageV2 {
        height: 446px;
        padding: 0;
        justify-content: center;
    }

    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 130px;
        }
    }
}
