.ParticipantChartHighlightsItem {
    max-width: 170px;

    &-metadata {
        text-align: center;
    }

    &-icon-container {
        position: relative;

        .CoverArt-image {
            min-height: 120px;
        }
    }

    &-title {
        margin-top: 1rem;
    }

    &-metricValue {
        font-size: 14px;
    }
}
