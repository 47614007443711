.PlaylistsPageFilters {
    &-period {
        @include vertical-align;

        .HelpTooltip {
            color: var(--gray-500);
        }
        .DateRangePicker-indicators-separator {
            display: none !important;
        }
    }

    .DataValueField {
        &-title {
            font-size: 12px;
            font-weight: var(--weight-bold);
            letter-spacing: 1px;
            line-height: 2;
            text-transform: uppercase;
        }
    }
}
