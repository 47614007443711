.ByRegionsLoading {
    &-row {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &-title {
        margin-bottom: 10px;

        .SkeletonLoader-line {
            width: 50px;
            height: 18px;
            border-radius: 3px;
        }
    }

    &-tiles {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
    }
}
