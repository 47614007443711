@use 'sass:map';
@use 'sass:list';

.TiktokImpact {
    padding: 20px;

    &-see-all {
        margin-top: 20px;
        justify-content: flex-end;
        display: inline-flex;
        width: 100%;
    }

    &-graph {
        position: relative;

        & > svg {
            position: absolute;
            top: 50%;
            transform: translateY(-20px);
            z-index: 1;
            left: -8px;
        }
    }

    .ErrorV2.section {
        height: 397px;
    }

    &Selection {
        &-item {
            display: flex;
            justify-content: space-between;
            height: 36px;
            padding: 0 10px;
            margin-bottom: 5px;
            border-radius: 3px;
            align-items: center;

            &:hover {
                background: linear-gradient(88deg, #deedf7 0%, #fff 100%);
            }

            &.active {
                background: linear-gradient(88deg, #deedf7 0%, #fff 100%);
                font-weight: 500;
                color: var(--gray-800);
            }

            &:last-child {
                margin-bottom: 0;
            }

            div {
                display: flex;
                align-items: center;
                & > span {
                    min-width: 60px;
                    text-align: right;
                }

                .tiktok-identification {
                    $tiktok-colors: map.get(
                        $extended-stores-chart-colors,
                        'tiktok'
                    );
                    $colour-one: list.nth($tiktok-colors, 1);
                    $colour-two: list.nth($tiktok-colors, 2);

                    color: var(--#{$colour-one});

                    &.views {
                        color: var(--#{$colour-two});
                    }
                }
            }

            .ProgressBar {
                width: 140px;
                margin-right: 10px;

                .ProgressBar-container {
                    background: var(--gray-150);
                }

                @each $name, $colours in $extended-stores-chart-colors {
                    $color: var(--#{$name}-line);
                    $bar-color: var(--#{$name}-bar);

                    .#{$name} {
                        background: $bar-color;
                        border-color: $color;
                        color-adjust: exact;
                        -webkit-print-color-adjust: exact;
                        &.ProgressBar-percentage {
                            background: unset;
                            color: $color;
                        }
                    }
                }
            }
        }
    }
}
