.Metric {
    padding: 1rem;
    text-align: right;

    &-lg {
        font-size: $h2-font-size;

        .ArrowUpGlyph,
        .ArrowDownGlyph {
            height: $h2-font-size;
            width: auto;
        }
    }

    &-sm {
        font-size: $font-size-sm;

        .ArrowUpGlyph,
        .ArrowDownGlyph {
            height: $h2-font-size;
            width: auto;
        }
    }

    &-header {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-container {
        display: flex;
        flex-direction: row;

        .Metric + .Metric {
            @include divider;
        }
    }
}
