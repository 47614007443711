.LabelManagerFilter {
    .LabelFilterSubtitle {
        color: var(--gray-500);
    }

    &-view-list {
        min-width: 0;
        overflow: auto;
        position: relative;
    }

    .SuiteListView-body {
        min-height: 150px;
    }

    .Select__option--is-selected {
        .LabelFilterSubtitle {
            color: var(--white);
        }
    }

    .Select__value-container {
        .Select__single-value {
            .LabelFilterSubtitle {
                display: none;
            }
        }
    }
}
