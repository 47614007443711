.GainersPageHeader {
    gap: 10px;
    padding: 20px;

    &-title {
        display: flex;
        align-items: center;
        gap: 10px;

        @include typography-h2;
    }
    &-subtitle {
        color: var(--text-secondary);

        @include typography-body;
    }
}
