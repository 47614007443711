.ReleasePeriodFilterListContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 310px;
    margin: 0 5px;

    &-sections {
        display: flex;
        gap: 8px;
        padding: 12px 0;
        border-top: 1px solid var(--dividers-default);

        & > .SuiteListView-section {
            padding: 0;
            margin: 0;
            border: none;
            flex: 1;
            min-width: 0;

            & + .SuiteListView-section {
                padding-left: 8px;
                border-left: 1px solid var(--dividers-default);
            }
        }
    }
}
