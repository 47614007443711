.SongPlaylistDigestV2 {
    &-all-playlists {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        @include typography-label;
    }
    .InfoGlyphIcon {
        display: block;
    }

    &-divider {
        width: 100%;
        height: 1px;
        background: var(--gray-300);
        margin: 20px 0;
    }

    .card-body {
        padding: 20px;
    }

    .InfoMessageV2 {
        .btn {
            @include typography-label;

            padding: 0;
        }
    }

    .ErrorV2.section {
        height: 308px;
    }
}

.SongPlaylistDigestV2-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 40px;
            width: 200px;
            border-radius: 3px;
        }
    }

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--text-primary);
        @include typography-h3;
        & > div {
            align-items: center;
            display: flex;
            gap: 10px;
        }
    }

    &-second-level {
        display: flex;
        justify-content: space-between;
    }

    &-filters {
        display: flex;
        align-items: center;
        .HelpTooltip {
            margin-left: 8px;
            margin-right: 10px;

            svg {
                margin: 0;
            }
        }
    }
}

.SongPlaylistDigestCard {
    $img-size: 78px;

    cursor: pointer;
    height: 80px;
    flex-direction: row;

    &:hover {
        box-shadow: 0 1px 5px 0 rgb(28 28 28 / 30%);
    }

    &-thumb {
        width: $img-size;
        height: $img-size;
        border-radius: 2px 0 0 2px;
    }

    &-content {
        width: calc(100% - #{$img-size});
        padding: 7px 10px;
        gap: 3px;
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
        }

        &-title {
            @include typography-h4;
        }

        &-type,
        &-stats {
            color: var(--text-secondary);
            @include typography-small;
        }

        &-stats {
            color: var(--text-primary);

            &-followers {
                margin-left: 4px;
            }

            & > div {
                align-items: center;
                display: flex;
                gap: 5px;

                .PlatformIcon {
                    margin-right: 0;
                }
            }
        }

        .ChangeCell .Glyph {
            width: 16px;
        }
    }

    .CountryFlag {
        width: 16px;
        height: 16px;
        border-radius: 2px;
    }
}

.SongPlaylistDigestSummary {
    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 202px;
            border-radius: 3px;
        }
    }

    &-container {
        display: flex;
        justify-content: space-around;
        max-width: 1105px;
    }

    &-header {
        margin-bottom: 20px;
        color: var(--text-primary);
        @include typography-h4;
    }

    &-section-title {
        color: var(--text-secondary);
        margin-bottom: 12px;
        @include typography-label;
    }

    &-types,
    &-stores {
        margin-top: 42px;
        &-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        &-card-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &-card {
            align-items: center;
            column-gap: 10px;
            display: grid;
            grid-auto-columns: 1fr 1fr;
            grid-auto-flow: column;
            width: 320px;

            &-stats {
                display: flex;
                gap: 10px;
                align-items: center;
                color: var(--text-secondary);
                width: 146px;
                @include typography-small;
            }

            &-info {
                color: var(--text-primary);
                width: 164px;
                display: flex;
                align-items: center;
                @include typography-small;

                div.PlatformIcon {
                    margin-right: 5px;
                }

                .InfoGlyphIcon {
                    margin-left: 3px;
                }
            }

            .ProgressBar {
                width: 40px;

                &-container {
                    height: 10px;
                }

                &-bar {
                    background-color: var(--text-primary);
                    border-color: var(--text-primary);
                }
            }
        }
    }
    &-info {
        &-label {
            color: var(--text-secondary);
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            @include typography-label;
        }

        &-value {
            color: var(--text-primary);
            margin-bottom: 20px;
            @include typography-h2;
        }
    }
}

.SongPlaylistDigestTopSongsTable {
    .LoadingIndicatorPlaylistName {
        display: grid;
        grid-template-areas:
            'image name'
            'image type';
        grid-template-columns: 30px 200px;
        column-gap: 10px;

        .image {
            grid-area: image;
            min-width: 30px;
            width: 30px;
            margin-right: 10px;
            height: 30px;

            .SkeletonLoader-line {
                height: 30px;
                border-radius: 3px;
            }
        }

        .name {
            margin-top: 0;
            grid-area: name;
            width: 200px;
            height: 16px;

            .SkeletonLoader-line {
                height: 16px;
                border-radius: 3px;
            }
        }

        .type {
            margin-top: 0;
            grid-area: type;
            width: 200px;
            height: 12px;

            .SkeletonLoader-line {
                height: 12px;
                border-radius: 3px;
            }
        }
    }

    .GridTable-row {
        @mixin skeleton-loader-dimensions($height, $width, $position: center) {
            justify-content: $position;
            display: flex;
            .SkeletonLoader {
                height: $height;
                width: $width;
                min-width: $width;

                .SkeletonLoader-line {
                    height: $height;
                    width: $width;
                    border-radius: 3px;
                }
            }
        }
        .GridTable-cell.separator:nth-child(1),
        .GridTable-cell.separator:nth-child(3) {
            @include skeleton-loader-dimensions(24px, 24px);
        }

        .GridTable-cell.separator:nth-child(4),
        .GridTable-cell.separator:nth-child(5),
        .GridTable-cell.separator:nth-child(6),
        .GridTable-cell.separator:nth-child(7) {
            @include skeleton-loader-dimensions(14px, 50px, end);
        }

        .FormattedChange {
            gap: 10px;
        }
    }

    div.PlatformIcon {
        margin-right: initial;
    }

    .PlaylistLinkCell-name-container {
        & > .TruncatedText {
            @include typography-h4;
        }

        .PlaylistLinkCell-description {
            @include typography-small;
        }
    }
}

.PlaylistDigestCardView-loading {
    .SkeletonLoader.stretch {
        .SkeletonLoader-line {
            height: 80px;
            border-radius: 3px;
        }
    }
}
