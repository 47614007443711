.ButtonGroup {
    .SegmentedButton-btn.btn {
        letter-spacing: normal !important;
        text-transform: capitalize !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        .PlatformIcon {
            margin-right: 0;
        }

        &:disabled {
            .PlatformIcon {
                opacity: 0.6;
            }
        }

        .HelpTooltip {
            display: flex;

            .PlatformIcon {
                margin-right: 5px;
            }
        }
    }
}
