.TiktokHeaderCell {
    white-space: nowrap;

    .PlatformIcon {
        margin: 3px var(--padding-medium-small, 8px) 3px 0;
        display: inline-block;
        vertical-align: bottom;
    }

    &-title {
        display: inline-block;
    }

    &-zebra {
        display: flex;
        align-items: flex-end;
        gap: var(--padding-medium-small, 8px);

        &-title {
            display: flex;
            flex-direction: row;
            gap: var(--padding-medium-small, 8px);

            .TikTokStoreIcon {
                margin: 3px 0;
                align-self: flex-end;
            }
        }
    }
}
