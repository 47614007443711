.PreferencesPage {
    .card {
        border-radius: 12px;
        margin-bottom: 15px;
    }

    h3 {
        margin: 0;
    }
}
