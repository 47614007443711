.InsightsGridTable {
    .expanded-row-table-status {
        grid-column: 1 / -1;
        padding: 1rem 0;

        .InfoMessage {
            margin: 0;
        }
    }

    .expanded-row-table-content {
        .cell-playlistName {
            .CoverArt-image {
                visibility: hidden;
            }
        }
    }
}
