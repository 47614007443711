.MyCatalogCheckbox {
    align-items: center;
    display: flex;

    .form-check {
        line-height: 1;
        margin-right: 0.5rem;
    }

    .WarningGlyph {
        color: var(--orange);
    }

    .WarningGlyphIcon {
        fill: var(--orange);
    }
}
