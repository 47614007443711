.TableResults {
    .LoaderIndicator {
        &--alignRight {
            margin-left: auto;
        }
    }

    .GridTable-body {
        .GridTable-row {
            .GridTable-cell {
                padding: 10px;
                height: 50px;
            }
        }
    }

    &.loading {
        .GridTable-body {
            .GridTable-row {
                .GridTable-cell {
                    padding: 0 10px; // Should remove vertical padding when loading for body cells
                }
            }
        }

        .GridTable-header-row {
            .GridTable-column-loading {
                padding-top: 34px;
            }

            .SkeletonLoader-line {
                height: 12px;
            }

            .util-align-right {
                .SkeletonLoader-line {
                    margin-left: auto;
                }
            }

            .col-inCatalog,
            .col-country {
                .SkeletonLoader {
                    display: none;
                }
            }
        }
    }
}
